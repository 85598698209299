import "./statisticTable.scss";
import useLadyService from "../../../../../services/LadyService";
import { Tippy } from "@/components/ui";
import {format, fromUnixTime} from "date-fns";

const StatisticTable = ({
  statistic,
  transactions,
  promoPeriods,
  elitePeriods,
  profileFilter,
}) => {
  const { t } = useLadyService();
  const header = [
    { title: "date", tippy: "" },
    { title: "position", tippy: "tposition" },
    { title: "profileshows", tippy: "tview" },
    { title: "viewprofile", tippy: "tclick" },
    { title: "viewcontact", tippy: "tcontatct" },
    { title: "erocontentaccess", tippy: "tero" },
    { title: "minofvideo", tippy: "tvideo" },
    { title: "g-preorders", tippy: "tpre" },
    { title: "expenses", tippy: "" },
    { title: "income", tippy: "" },
  ];

  

  const combinedData = Object.entries(statistic).map(([timestamp, actions]) => {
    const income = transactions.income[timestamp] || 0; // Default to 0 if not present
    const expenses = transactions.expenses[timestamp] || 0; // Default to 0 if not present

    return {
      date:
        timestamp === "forperiod"
          ? "forperiod"
          : timestamp,
      position: actions?.position || "-", 
      profileshows: actions?.profileshows || 0,
      viewprofile: actions?.viewprofile || 0,
      viewcontact: actions?.viewcontact || 0,
      erocontentaccess: actions?.erocontentaccess || 0,
      minofvideo: actions?.minofvideo || 0,
      prepaidorders: actions?.prepaidorders || 0,
      expenses: expenses,
      income: income,
    };
  });
  // forperiod in the beginning
  combinedData.unshift(combinedData.pop())


  // const transactionsData = transactions.map();
  //   const actionsData = [
  //     "Date",
  //     ...new Set(statistic.flatMap((entry) => Object.keys(entry.actions))),
  //   ];
  //   console.log(actionsData);
  //   const displayHeaders = [
  //     "Date",
  //     ...actionsData.slice(1).map((key) => actionDisplayNames[key] || key),
  //   ];
  

  

  return (
    <>
    <div className="statistic-table3">
      <table >
        <thead>
          <tr>
            {header.map((head, index) => {
              switch (head.title){
                case 'date':{
                  return <th key={index} className="sticky">{t(head.title)}</th>;
                }
                case 'income': case 'expenses':{
                  return <th key={index}>{t(head.title)}</th>;
                }
                case 'position':{
                  return (<>{profileFilter && <th key={index}>{t(head.title)}</th>}</>);
                }
                default :{
                  return(
                    <th key={index}>
                      <Tippy quote={true} arrow={false} title={t(head.tippy)}>{t(head.title)}</Tippy>
                    </th>)
                }
              }
              
            })}
          </tr>
        </thead>
        <tbody>
          {combinedData.toReversed().map((row, index) => (
            <tr className={`${row.date === 'forperiod' ? 'bold' : ''} `} key={index}>
              {Object.keys(row).map((key) => {
                switch(key){
                  case 'date':{
                    return (
                      <td
                        className={` ${promoPeriods?.includes(parseInt(row[key])) ? 'promoted' : ''} ${elitePeriods?.includes(parseInt(row[key])) ? 'elite' : ''} sticky`}
                      >
                        {row[key] === 'forperiod' ? t('forperiod') :  format(fromUnixTime(row[key]), 'dd.MM.yyyy')}
                      </td>
                    );
                  }
                  case 'position': {
                    return(<>{profileFilter && <td className="col-1 tilda">{row[key]}</td>}</>);
                  }
                  case 'profileshows':{
                    return <td className="col-1">{row[key]}</td>;
                  }
                  case 'viewprofile': case 'viewcontact': {
                    return <td className="col-2">{row[key]}</td>;
                  }
                  case 'erocontentaccess': case'minofvideo': case 'prepaidorders':{
                    return <td className="col-3">{row[key]}</td>;
                  }
                  case 'income': case 'expenses':{
                    return <td className={`${key === 'income'? 'income currency--erocoin' : 'expense currency--erocoin'}`}>{row[key]}</td>;
                  }
                  default : {
                    return <td>{row[key]}</td>;
                  }
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
      </div>

      {/* <div className={'statistic-table'}>

                <div className='statistic-table__wrapper'>
                    <div className={`statistic-table__header ${profileFilter ? 'with_positions' : ''}`}>
 
                        {header.map((i, index) => {
                        if (index === 1 && !profileFilter) {
                            return;
                        }
                            switch (index) {
                                case 0: {
                                    return (
                                        <div className='statistic-table__cell sticky-cell  bg-main' >
                                            {i}
                                        </div>
                                    )
                                }
                                case 1 : {
                                    return (
                                        <Tippy title={t('tposition')} className={'statistic-table__cell gray-cell'}>
                                            {i}
                                        </Tippy>
                                    )
                                }
                                case 2: {
                                    return (
                                        <Tippy title={t('tview')} className={'statistic-table__cell gray-cell'}>
                                            {i}
                                        </Tippy>
                                    )
                                }
                                case 3:
                                    {
                                        return (
                                            <Tippy title={t('tclick')} className={'statistic-table__cell dark-cell'}>
                                                {i}
                                            </Tippy>
                                        )
                                    }
                                case 4: {
                                    return (
                                        <Tippy title={t('tcontact')} className={'statistic-table__cell dark-cell'}>
                                            {i}
                                        </Tippy>
                                    )
                                }
                                case 5: 
                                {
                                    return (
                                        <Tippy title={t('tero')}  className={'statistic-table__cell darkest-cell'}>
                                            {i}
                                        </Tippy>
                                    )
                                }
                                case 6:
                                    {
                                    return (
                                        <Tippy title={t('tvideo')}  className={'statistic-table__cell darkest-cell'}>
                                            {i}
                                        </Tippy>
                                    )
                                }
                                case 7: {
                                    return (
                                        <Tippy title={t('tpre')} className={'statistic-table__cell darkest-cell'}>
                                            {i}
                                        </Tippy>
                                    )
                                }
                                case 8: {
                                    return (
                                        <div className={'statistic-table__cell green-cell'}>
                                            {i}
                                        </div>
                                    )
                                }
                                    case 9: {
                                        return (
                                            <div className={'statistic-table__cell red-cell'}>
                                                {i}
                                            </div>
                                        )
                                    }
                                    default:{
                                        return (
                                            <div className={'statistic-table__cell'}>
                                                {i}
                                            </div>)
                                    }
                                    
                                }
        
                                })}
                        </div>
                       
                        
                            {Object.entries(statistic).toReversed().map(([date, actions]) => (
                                    <div className={`statistic-table__row ${profileFilter ? 'with_positions' : ''} ${date === 'forperiod' ? 'bold-row' : ''}`}>
                                        <div className={`statistic-table__cell sticky-cell bg-main ${promoPeriods?.includes(parseInt(date)) ? 'promoted-cell' : ''} ${elitePeriods?.includes(parseInt(date)) ? 'elite-cell' : ''}`}>
                                            {date === 'forperiod' ? t('forperiod') : moment.unix(date).utc().format("DD.MM.YYYY")}
                                        </div>
                                        {profileFilter &&
                                        (<div className={'statistic-table__cell gray-cell'}>
                                            {actions?.position ? `~ ${actions?.position}` : '-'}
                                        </div>)
                                        }
                                        <div className={'statistic-table__cell gray-cell'}>
                                            {actions?.profileshows ?? 0}
                                        </div>
                                        <div className={'statistic-table__cell dark-cell'}>
                                            {actions?.viewprofile ?? 0}
                                        </div>
                                         <div className={'statistic-table__cell dark-cell'}>
                                            {actions?.viewcontact ?? 0}
                                        </div>
                                        <div className={'statistic-table__cell darkest-cell'}>
                                            {actions?.erocontentaccess ?? 0}
                                        </div>
                                        <div className={'statistic-table__cell darkest-cell'}>
                                            {actions?.minofvideo ?? 0}
                                        </div>
                                        <div className={'statistic-table__cell darkest-cell'}>
                                            {actions?.prepaidorders ?? 0}
                                        </div>
                                        <div className={'statistic-table__cell green-cell'}>
                                            <span className={"currency--erocoin"}>{transactions['income'][date] ?? 0}</span>
                                        </div>
                                        <div className={'statistic-table__cell red-cell'}>
                                            <span className={"currency--erocoin"}>{transactions['expenses'][date] ?? 0}</span>
                                        </div>
                                    </div>
                            ))}
                </div>
            </div> */}

      
      <div className="statistic-table__options">
        <span className={"statistic-table__option--elite"}>
          {t("Status")} Elite
        </span>
        <span className={"statistic-table__option--promo"}>
          {t("activepromo")}
        </span>
      </div>
    </>
  );
};

export default StatisticTable;
