import useLadyService from "@/services/LadyService";
import {Button, Icon, InputInLabel, Toggle} from "../../../../ui";
import {MediaZone} from "./MediaZone";
import debounce from "lodash.debounce";
import {useCallback, useEffect, useState} from "react";
import {makeRequest} from "../../../../../services/makeRequest";
import showToast from "../../../../toast/Toast";
import axios from "axios";
import baseUrl from "../../../../../services/apiConfig";

export const Promos = ({removeMedia}) => {

  const {t, token} = useLadyService();
  const [promotions, setPromotions] = useState([]);

  const getPromotions = () => {
    makeRequest({route: 'agency/promotions'}).then((result) => {
      setPromotions(result.data.toReversed())
    }).catch((error) => {
      const res = error.response;
      switch (res?.status) {
        case 401: {
          showToast({
            message: t("noauth"),
            variant: "error",
          });
          break;
        }
        case 422: {
          showToast({
            message: t("oops"),
            variant: "error",
          });
          break;
        }
      }
    })
  }

  useEffect(() => {
    getPromotions();
  }, [])

  const addInput = () => {
    setPromotions([...promotions, {name: "", status: false}]);
  };

  const removeInput = (index) => {
    const promotionList = [...promotions]
    const id = promotionList[index]?.id
    if (!id) {
      setPromotions(promotionList.filter((input, i) => i !== index));
      return
    }
    makeRequest({route: `agency/promotions/${id}`, method: "DELETE"}).then(() => {
      setPromotions(promotionList.filter((input) => input.id !== id));
    }).catch((error) => {
      const res = error.response;
      switch (res?.status) {
        case 401: {
          showToast({
            message: t("noauth"),
            variant: "error",
          });
          break;
        }
        case 403: {
          showToast({
            message: t("noAuthAction"),
            variant: "error",
          });
          break;
        }
        case 422: {
          showToast({
            message: t("oops"),
            variant: "error",
          });
          break;
        }
      }
    })
  }

  const toggleInput = (index) => {
    const promotionList = [...promotions]
    const value = promotionList[index].status;
    const id = promotionList[index].id;
    makeRequest({
      route: `agency/set-promotions-status/${id}`,
      method: "PUT",
      payload: {status: !value}
    }).then((response) => {
      promotionList[index] = response.data
      setPromotions([...promotionList])
      showToast({
        message: t("success"),
        variant: "success",
      });
    }).catch((error) => {
      const res = error.response;
      switch (res?.status) {
        case 401: {
          showToast({
            message: t("noauth"),
            variant: "error",
          });
          break;
        }
        case 403: {
          showToast({
            message: t("noAuthAction"),
            variant: "error",
          });
          break;
        }
        case 422: {
          showToast({
            message: t("oops"),
            variant: "error",
          });
          break;
        }
      }
    })
  };

  const handleInputChange = useCallback(
    debounce((newValue, index) => {
      const promotionList = [...promotions]
      const id = promotionList[index]?.id
      let route = 'agency/promotions/'
      let method = "POST"
      if (id) {
        route = `agency/promotions/${id}`
        method = "PUT"
      }
      makeRequest({route, method, payload: {name: newValue}}).then((response) => {
        promotionList[index] = response.data
        setPromotions([...promotionList])
        showToast({
          message: id ? t("success") : t("successreview"),
          variant: "success",
        });
      }).catch((error) => {
        const res = error.response;
        switch (res?.status) {
          case 401: {
            showToast({
              message: t("noauth"),
              variant: "error",
            });
            break;
          }
          case 403: {
            showToast({
              message: t("noAuthAction"),
              variant: "error",
            });
            break;
          }
          case 422: {
            showToast({
              message: t("oops"),
              variant: "error",
            });
            break;
          }
        }
      })
    }, 1000),
    [promotions]
  );

  const uploadMedia = (promotionId, file, type) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", file.name);
    formData.append("type", type);
    return axios.post(baseUrl + `agency/media/${promotionId}`, formData, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "", "Content-Type": "multipart/form-data",
      }
    })
  }

  const Promo = ({item, index}) => {
    const [name, setName] = useState(item.name);
    const [preview, setPreview] = useState(item.preview);
    const [banner, setBanner] = useState(item.banner);

    useEffect(() => {
      if (preview !== undefined && preview.length && typeof preview[0] === "object") {
        uploadMedia(item.id, preview[0], 'preview').then((response) => {
        setPreview([response.data.link]);
        setPromotions(promotions.map((item, i)=> {
          if(index === i){
            return {...item, preview: response.data.link}
          }
          else{
            return item
          }
        }))
        }).catch((error) => {
          console.error('error', error)
        })
      }
    }, [preview])

    useEffect(() => {
      if (banner !== undefined && banner.length && typeof banner[0] === "object") {
        uploadMedia(item.id, banner[0], 'banner').then((response) => {
          setBanner([response.data.link]);
          setPromotions(promotions.map((item, i)=> {
            if(index === i){
              return {...item, banner: response.data.link}
            }
            else{
              return item
            }
          }))
        }).catch((error) => {
          console.error('error', error)
        })
      }
    }, [banner])

    return (
      <>
        <div className="d-flex gap-16 align-center">
          {item.id ? (
            <Toggle
              id={"isActivePromo" + index}
              checked={!!item.status}
              onChange={() => toggleInput(index)}
            />
          ) : null}

          <InputInLabel
            type={"text"}
            id={"promos-name" + index}
            clazz={"w-100"}
            value={name}
            placeholder={t("name")}
            onChange={(e) => {
              setName(e.target.value)
              handleInputChange(e.target.value, index)
            }}
          >
            {t("agencypromos")}
          </InputInLabel>

          <button className={`button-square tarifs-row-trash icon icon-xl icon--trash _red`}
                  type={'button'}
                  onClick={() => removeInput(index)}
          />
        </div>

        <div className={`d-flex gap-16`}>
          <MediaZone
            w_100
            isH3={false}
            title={(t("uploadpreview") + " 350*580 px")}
            setMedia={setPreview}
            removeMedia={(media) => removeMedia(media, () => setPreview([]))}
            isBigPhoto
            media={preview}
            disableUpload={item.id ? false : true}
          />

          <MediaZone
            w_100
            isBigPhoto
            isH3={false}
            title={(t("banner") + " 1200*900 px)")}
            setMedia={setBanner}
            removeMedia={(media) => removeMedia(media, () => setBanner([]))}
            media={banner}
            disableUpload={item.id ? false : true}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="d-flex justify-sb align-center">
        <h3 className={'m-0'}>{t("agencypromos")}</h3>

        {promotions.length > 0 ? null :
          <Button
            size={"s"}
            clazz={"button_outline--green-accent justify-center float-right"}
            onClick={() => addInput()}
          >
            <Icon spritePath={"plus"} size={"s"}/>

            {t("addad")}
          </Button>}
      </div>

      {promotions.map((item, index) => { 
        return (
          <Promo key={item.id} item={item} index={index}/>
        )
      })}

      {promotions.length > 0 ? <div className="d-flex justify-end align-center">
      
          <Button
            size={"s"}
            clazz={"button_outline--green-accent justify-center float-right"}
            onClick={() => addInput()}
          >
            <Icon spritePath={"plus"} size={"s"}/>

            {t("addad")}
          </Button> 
      </div>: null}
    </>
  );
};
