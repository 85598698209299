import { Icon } from "../index";
import "./checkbox.scss";

const Checkbox = ({
                    id,
                    name,
                    title,
                    checked,
                    disabled = false,
                    value,
                    linkCheckbox,
                    buttonCheckbox,
                    clazz,
                    border = true,
                    flag,
                    onChange,
                    onClick,
                    subTitle = "",
                    readOnly,
                    icon,
                    iconSize = "",
                    iconClazz,
                    radio,
                    onKeyUp,
                    children,
                    important,
                    textClazz
                  }) => {

  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  let defaultCheckboxProps = {
    type: "checkbox",
    tabIndex: 1,
    disabled: disabled,
    id: id,
    name: name,
    onChange: handleChange,
    checked: checked,
    value: value,
    readOnly: readOnly,
  };

  if (linkCheckbox) {
    return (
        <a
            className={`checkbox${checked ? " checkbox_checked" : ""}${disabled ? " checkbox_disabled" : ""}`}
            tabIndex={disabled ? null : 0}
            onClick={disabled ? null : onClick}
            onKeyUp={onKeyUp}
            href="/"
        >
          <input {...defaultCheckboxProps} />
          <label htmlFor={id}>
            {textClazz ? <span className={textClazz}>{title}</span> : title}
          </label>
        </a>
    );
  }

  if (buttonCheckbox) {
    return (
        <label
            className={`checkbox-button${disabled ? " checkbox-button_disabled" : ""}${checked ? " checkbox-button_checked" : ""}${clazz ? ` ${clazz}` : ""}`}
            htmlFor={id}
            tabIndex={0}
            onKeyUp={onKeyUp}
        >
          <input onClick={disabled ? null : onClick} {...defaultCheckboxProps} />
          {textClazz ? <span className={textClazz}>{title}</span> : title}
        </label>
    );
  }

  return (
      <div
          className={`checkbox${checked ? " checkbox_checked" : ""}${disabled ? " checkbox_disabled" : ""}${border ? `` : ` _no-border`}${important ? ` _important` : ''}${clazz ? ` ${clazz}` : ""}`}
          onClick={disabled ? null : onClick}
          onKeyUp={onKeyUp}
      >
        <input {...defaultCheckboxProps} className={`${radio ? " radio" : ""}`} />
        <label htmlFor={id}>
          {!!flag ? (
              <Icon icon type="flag" spritePath={`${flag.toUpperCase()}`} />
          ) : null}
          {icon && (
              <Icon icon size={iconSize} spritePath={`${icon}`} clazz={iconClazz} />
          )}
          {textClazz ? <span className={textClazz}>{title}</span> : title}
          {children}
          {subTitle && typeof subTitle === "string" ? (
              <span>{subTitle}</span>
          ) : !!subTitle ? (
              subTitle
          ) : null}
        </label>
      </div>
  );
};

export default Checkbox;
