import useLadyService from "@/services/LadyService";
import { InputInLabel, Icon } from "@/components/ui";
import { useEffect, useMemo, useState } from "react";
import { setUserInfo } from "@/stores/slices/userSlice";
import { Select } from "@/components/ui";
import { calculateWithCommission } from "@/helper/calculateWithCommission";

const InputsBlock = ({
  values,
  setValueTo,
  setValueFrom,
  registerFrom,
  registerTo,
  currency,
  setCurrency,
  euroValue,
  setEuroValue,
  defaultValue,
}) => {
  const windowWidth = window.innerWidth;
  const isMobile = useMemo(() => windowWidth < 767.98, [windowWidth]);

  const { t, dispatch, resultBalance } = useLadyService();

  const currencyOption = {
    main_title: t("currency"),
    options: [
      { id: 12, title: "EUR", value: "eur" },
      { id: 22, title: "CZK", value: "czk" },
    ],
  };

  const currencyRates = {
    czk: 25,
    eur: 1,
  };

  const [coinValue, setCoinValue] = useState(values[`price-bank-coin`]);

  const calculateEuro = (val) => {
    const value = parseFloat(val) || defaultValue;
    const rate = currencyRates[currency] || 1;
    const adjustedValue = value * rate;

    const convertedValue = calculateWithCommission(adjustedValue, 0.0);
    const roundedValue = parseFloat(convertedValue.toFixed(2));

    setValueFrom("price-bank-coin", value);
    setValueTo("price-bank-euro", roundedValue);
    setEuroValue(roundedValue);
    setCoinValue(value);
    dispatch(setUserInfo({ stateName: "amount", value: adjustedValue }));
  };

  useEffect(() => {
    calculateEuro(coinValue);
    if (!euroValue && currency === "czk") {
      setEuroValue(defaultValue * 25);
    }
  }, [currency]);

  return (
    <>
      <InputInLabel
        price={"erocoin"}
        id={"earn-to"}
        type={"number"}
        max={resultBalance}
        placeholder={""}
        value={values[`price-bank-coin`] || defaultValue}
        trigger={coinValue}
        register={registerTo}
        onChange={(e) => calculateEuro(e.target.value)}
        clazz={`${isMobile ? "min-w-100" : "min-w-160"}`}
      >
        <span>{t("amountwithdraw")}</span>
      </InputInLabel>

      <Select
        onChange={setCurrency}
        options={currencyOption.options}
        clazz={"button button-xs justify-start"}
        arrowSize={"s"}
        name={"currency"}
        defaultTitle={
          currencyOption.options.filter((item) => item.value === currency)[0]
            .title
        }
        subTitle={t("currency")}
        placeholder={t("currency")}
        withoutTitle={true}
        postApi={true}
      />

      <Icon size={"m"} spritePath={"arrow-right"} />
      <div className="balance-page your__balance gap-8">
        <span className={`p1 ${isMobile ? "fz-13" : ""}`}>{t("youwillget")}</span>
        <span
            className={`title_h3 mb-0 ${
            currency === "eur" ? "currency--euro" : "currency--czk"
          }`}
        >
          {euroValue || defaultValue}
        </span>
      </div>
    </>
  );
};

export const Bank = ({
  values,
  setValueTo,
  setValueFrom,
  registerTo,
  registerFrom,
  currency,
  setCurrency,
  setTubValue,
  tubValue,
  defaultValue,
}) => {
  const { t } = useLadyService();
  const windowWidth = window.innerWidth;
  const isMobile = useMemo(() => windowWidth < 767.98, [windowWidth]);
  const [euroValue, setEuroValue] = useState(values[`price-bank-euro`]);

  const tabs = [
    { id: "bank", name: t("czechbank") },
    { id: "iban", name: "IBAN" },
  ];
  const [activeTabId, setActiveTabId] = useState(tubValue);

  const handleTabClick = (tabId) => {
    setActiveTabId(tabId);
    setTubValue(tabId);
  };

  return (
    <>
      <div
        className={`balance-page__header ${
          isMobile ? "fd-row align-center gap-0" : ""
        }`}
      >
        <div className="header__tabs balance-page__tabs mb-16">
          {tabs.map((i, index) => {
            return (
              <div
                key={index}
                className={`header__tab min-w-160 text-center p2 ${
                  activeTabId === i.id ? "_active" : ""
                }`}
                onClick={() => handleTabClick(i.id)}
              >
                {i.name}
              </div>
            );
          })}
        </div>
        {!isMobile && (
          <div className="payment-methods">
            <div className="payment-methods__icon" title={t("bankaccount")}>
              <Icon size={"m"} spritePath={"bank"} />
            </div>
          </div>
        )}
      </div>
      {isMobile && (
        <div className="payment-methods mb-8">
          <div className="payment-methods__icon" title={t("bankaccount")}>
            <Icon size={"m"} spritePath={"bank"} />
          </div>
        </div>
      )}
      <div
        className={`d-flex align-center ver__block__control cursor-default
        `}
      >
        <div
          className={`d-flex align-center w-100 ${
            isMobile && windowWidth !== 0 ? "gap-4" : "border-right gap-16"
          } `}
        >
          <InputsBlock
            defaultValue={defaultValue}
            values={values}
            setValueTo={setValueTo}
            setValueFrom={setValueFrom}
            registerFrom={registerFrom}
            registerTo={registerTo}
            currency={currency}
            setCurrency={setCurrency}
            euroValue={euroValue}
            setEuroValue={setEuroValue}
          />
        </div>
        {!isMobile && (
          <span className="w-50">
            {t("earingin") + " 1-2 " + t("workdays")}
            <br />
            <p className={"color-green"}>{t("freeoffee")}</p>
          </span>
        )}
      </div>
      {isMobile && (
        <div className={`d-flex justify-sb mt-16 gap-8`}>
          <div className="balance-page your__balance gap-8 border-right p-r-8 min-w-fit">
            <span className="p1">{t("topayamount")}</span>
            <span
                className={`title_h3 mb-0 ${
                currency === "eur" ? "currency--euro" : "currency--czk"
              }`}
            >
              {euroValue || defaultValue}
            </span>
          </div>
          <span className="w-100">
            {t("earingin") + " 1-2 " + t("workdays")}
            <br />
            <p className={"color-green"}>{t("freeoffee")}</p>
          </span>
        </div>
      )}
    </>
  );
};
