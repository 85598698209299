import {useTranslation} from "react-i18next";
import {makeRequest} from "@/services/makeRequest";


export const useMakeRequest = () => {
    const { t } = useTranslation("translation");

    return (props) => {
        return makeRequest({...props, t});
    };
};
