import { SwiperSlide } from "swiper/react";
import useLadyService from "../../services/LadyService";
import { Button, ButtonLink, Icon } from "../ui";
import { useState } from "react";
import { Link } from "react-router-dom";
import {Popup, RemoveStory} from "../popups";

const StoriesComponentEdit = ({setStories, setOpen, open, status, data}) => {
 const {
    id = data[0]?.id,
    slug = data[0]?.slug,
    name = data[0]?.name,
     is_online = data[0]?.is_online,
    age = data[0]?.age,
    description = data[0]?.description,
   created_at
 } = data
  const { t, setMediaSize, TimeAgo } = useLadyService();
  const [timeAgo, setTimeAgo] = useState("");
  const regex = /([^.]+)$/;

  const res = data[0]?.media[0]

  const type = res?.match(regex)[1];

  const [muted, setMuted] = useState(false);
  const [remove, setRemove] = useState(false);

  const openRemoveButton = () => {
    setRemove(true)
  }

  return (
    <>
      <Popup
        wrapperClazz="stories-popup"
        open={open}
        setOpen={setOpen}
        container={'none'}
      >
        <SwiperSlide
          className={"stories__slide"}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={"stories__card"}>
            {type === ("mp4" || "webm") && (
              <Button
                square={true}
                size={"s"}
                clazz={`stories__sound${muted ? " _active" : ""}`}
                onClick={() => setMuted((state) => !state)}
              >
                <Icon size={"l"} spritePath={muted ? "volume-x" : "volume"} />
              </Button>
            )}

            {status && (
              <Button
                square={true}
                size={"s"}
                clazz={`girl-card__remove button--secondary`}
                onClick={openRemoveButton}
              >
                <Icon size={"xl"} spritePath={"trash"} clazz={"_red"} />
              </Button>
            )}

            {type === ("mp4" || "webm") ? (
              <video
                loop={true}
                playsInline={true}
                autoPlay={true}
                muted={muted}
                src={res}
              />
            ) : (
              <img
                src={setMediaSize(res, "xl")}
                alt={`${t("escortmodel")} ${name}`}
              />
            )}

            {description ? (
              <p className="stories__text color-white">
                {description}
              </p>
            ) : null}

            <div className="girl-card__info-wrapper">
              <div className="girl-card__age color-white">
                {age} <span>{t("years")}</span>
              </div>

              <div className="girl-card__info color-white">
                {is_online && (
                  <Link
                    title={name}
                    className={`model__name${
                        is_online
                        ? " _online"
                        : " _offline"
                    }`}
                  >
                    {name}
                  </Link>
                )}
                {created_at && (
                  <p className={"color-white"}>
                    <TimeAgo
                      setTimeAgo={setTimeAgo}
                      timeAgo={timeAgo}
                      timestamp={created_at}
                    />
                  </p>
                )}
              </div>

              <ButtonLink
                size={"m"}
                href={`/profile/${slug}`}
                clazz={"button--green _no-underline"}
                onClick={() => setOpen(false)}
              >
                {t("profuser")}
                <Icon spritePath={"chevrone-right"} size={"m"} />
              </ButtonLink>
            </div>
          </div>
        </SwiperSlide>
      </Popup>

      {remove &&
        <RemoveStory
          open={remove}
          setOpen={setRemove}
          id={id}
          setStories={setStories}
          setOpenAll={setOpen}
        />
      }
    </>
  );
};

export default StoriesComponentEdit;
