import axios from "axios";
import showToast from "@/components/toast/Toast";
import useLadyService from "@/services/LadyService";

const useMediaService = () => {
    const { t, token } = useLadyService();
    const removeMedia = (link, callback = () => {}) => {
        axios
            .delete(link, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : "",
                },
            })
            .then(() => {
                callback(link);
            })
            .catch((error) => {
                const res = error.response;
                switch (res?.status) {
                    case 401: {
                        showToast({
                            message: t("noauth"),
                            variant: "error",
                        });
                        break;
                    }
                    case 404: {
                        callback(link);
                        break;
                    }
                    case 422: {
                        showToast({
                            message: t("oops"),
                            variant: "error",
                        });
                        break;
                    }
                    default: {

                    }
                }
            });
    };

    return {
        removeMedia,
    }
}

export default useMediaService;