import { Popup } from "../index";
import useLadyService from "@/services/LadyService";
import { useState } from "react";
import { Button, Checkbox, Icon, SelectCountry, Switch } from "../../ui";
import { useSelector } from "react-redux";
import {
  getCountriesData,
  getUserCurrentCity,
} from "@/stores/slices/userSlice";
import { plural } from "@/helper/plural";

const AgencyFilter = (props) => {
  const {
    resetFilter,
    fetchData,
    open,
    setOpen,
    genderChecked,
    setGenderChecked,
    activeToggle,
    setActiveToggle,
    extraChecked,
    setExtraChecked,
  } = props;
  const { t, lang } = useLadyService();
  const options = {
    gender: [
      { value: "female", title: t("woman"), id: "female" },
      { value: "male", title: t("guys"), id: "male" },
      { value: "trans", title: t("trans"), id: "trans" },
      { value: "couple", title: t("couple"), id: "couple" },
    ],
    status: [
      { value: "elitelady", title: t("elite"), id: "elitelady" },
      { value: "activepromo", title: t("activepromo"), id: "activepromo" },
      { value: "vchat", title: t("videochat"), id: "vchat" },
      { value: "booking", title: t("onlineorder"), id: "booking" },
      {
        value: "preview",
        title: t("modelpreviews"),
        id: "preview",
      },
      { value: "ero", title: t("erocontent"), id: "ero" },
      { value: "video", title: t("video"), id: "video" },
      { value: "indi", title: t("indi"), id: "indi" },
      { value: "pornstar", title: t("star"), id: "pornstar" },
      { value: "verified", title: t("verified"), id: "verified" },
      { value: "healthy", title: t("healthy"), id: "healthy" },
      { value: "new", title: t("newmodels"), id: "new" },
    ],
    extra: [
      { value: "withoutprice", title: t("withoutprice"), id: "withoutprice" },
      {
        value: "withoutaddress",
        title: t("withoutaddress"),
        id: "withoutaddress",
      },
    ],
  };

  const countriesData = useSelector(getCountriesData);
  const currentCity = useSelector(getUserCurrentCity);

  const [searchCount, setSearchCount] = useState("");
  const [currentActive, setCurrentActive] = useState(
    currentCity?.length
      ? currentCity
      : {
          title: "Prague",
          id: "1",
          icon: "cz",
        }
  );

  const changeActiveCityHandler = (cityId) => {
    const city = countriesData[0].cz.cities.find((item) => item.id === cityId);

    setCurrentActive({ title: city.name, id: city.id, icon: "cz" });
  };

  const toggleExtra = (value) => {
    if (extraChecked.includes(value)) {
      setExtraChecked((prev) => prev.filter((item) => item !== value));
    } else {
      setExtraChecked((prev) => [...prev, value]);
    }
  };
  const toggleGender = (value) => {
    if (genderChecked.includes(value)) {
      setGenderChecked((prev) => prev.filter((item) => item !== value));
    } else {
      setGenderChecked((prev) => [...prev, value]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = activeToggle.reduce((acc, obj) => {
      const key = Object.keys(obj)[0];
      acc[key] = obj[key] ? 1 : 0;
      return acc;
    }, {});

    if (genderChecked.length > 0) {
      payload.gender = genderChecked.join(",");
    }
    if (extraChecked.length > 0) {
      payload.extraAgency = extraChecked.join(",");
    }
    payload.city = currentActive.id;
    fetchData(payload);
    setOpen(false);
  };

  const AgencyFilterStatus = ({ title, index, value, active, setActive }) => {
    const handleSwitch = (switchValue) => {
      let array = [...active];
      const index = array.findIndex((obj) => obj.hasOwnProperty(value));
      if (switchValue !== "any") {
        if (index >= 0) {
          array[index][value] = switchValue === "yes";
        } else {
          array = [...array, { [value]: switchValue === "yes" }];
        }
        setActive(array);
      } else if (index >= 0) {
        array.splice(index, 1);
        setActive(array);
      }
    };
    return (
      <div className="filter-select-block">
        {title}
        <Switch
          clazz={"ml-auto"}
          id={value}
          name={value}
          value={activeToggle?.[index]?.[value] ?? "null"}
          onChange={handleSwitch}
        />
      </div>
    );
  };

  const GenderFilter = () => {
    return (
      <div className="filter__box filter--place">
        <span className={"filter__title"}>
          {t("gender").split(" ")[0]}
          {!!genderChecked.length && (
            <span className={"filter__selected"}>!</span>
          )}
          <button
            type="button"
            className={"range__default"}
            onClick={() => setGenderChecked([])}
          >
            {!!genderChecked.length && t("reset")}
          </button>
        </span>
        <div className="filter__checkboxes-grid">
          {options.gender.map((i) => {
            return (
              <Checkbox
                onClick={() => toggleGender(i.value)}
                checked={genderChecked.includes(i.value)}
                key={i.id}
                name={i.name}
                id={i.id}
                title={i.title}
              />
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Popup
      open={open}
      setOpen={setOpen}
      wrapperClazz={"filter"}
      method={"POST"}
      clazz={"gap-16 filter__body"}
    >
      <div className="popup-form__inner gap-0">
        <div className="filter__top">
          <SelectCountry
            data={countriesData}
            name={"city-create-filter"}
            clazz={`button button-xs select-city`}
            clazzWrapper={`mr-auto`}
            arrowSize={"m"}
            type={"flag"}
            defaultTitle={currentActive.title}
            currentActiveIndex={currentActive}
            activeCountry={currentActive.icon}
            setActive={changeActiveCityHandler}
            isAgencyFilter={true}
          />
        </div>
        <GenderFilter />
        <div className="filter__box">
          <span className={"filter__title"}>
            <div className="d-flex">
              {t("Status")}
              {(!!activeToggle.length || !!extraChecked.length) && (
                <span className={"filter__selected"}>!</span>
              )}
              <button
                type="button"
                className={"range__default ml-auto"}
                onClick={() => {
                  setActiveToggle([]);
                  setExtraChecked([]);
                }}
              >
                {!!activeToggle.length && t("reset")}
              </button>
            </div>
          </span>

          <div className="model__params agency__params-filter">
            {options.status.map((i) => {
              const index = activeToggle.findIndex((obj) =>
                obj.hasOwnProperty(i.value)
              );
              return (
                <AgencyFilterStatus
                  key={i.id}
                  id={i.id}
                  value={i.value}
                  title={i.title}
                  index={index}
                  active={activeToggle}
                  setActive={setActiveToggle}
                />
              );
            })}
          </div>
          <div className="d-flex gap-16 w-100">
            {options.extra.map((i) => {
              return (
                <Checkbox
                  size={"s"}
                  buttonCheckbox={true}
                  clazz="w-100"
                  title={i.title}
                  onClick={() => toggleExtra(i.value)}
                  checked={extraChecked.includes(i.value)}
                ></Checkbox>
              );
            })}
          </div>
        </div>
        <div className="filter__bottom ">
          <Button
            onClick={handleSubmit}
            buttonType={"button"}
            size={"m"}
            clazz={"button--green filter__submit"}
          >
            {`${t("apply")}`}
          </Button>
          <Button
            onClick={() => resetFilter()}
            size={"s"}
            clazz={"button--secondary filter__reset"}
          >
            {t("reset")}
          </Button>
          <Button
            size={"s"}
            clazz={"button--tetriary"}
            onClick={() => setOpen(false)}
          >
            {t("toclose")}
            <Icon size={"l"} spritePath={"close"} />
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default AgencyFilter;
