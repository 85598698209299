import {Link} from "react-router-dom";
import useLadyService from "@/services/LadyService";
import {Icon, Button} from "@/components/ui";
import React from "react";
import showToast from "../../toast/Toast";
import {capitalizeFirstLetter} from "@/helper/capitalizeFirstLetter";
import {ButtonLink} from "@/components/ui";
import {useState} from "react";
import {useMakeRequest} from "@/hooks/useMakeRequest";


const PartyCard = ({party, edit = false, setCurrentParty, setOpenPopup, setOpenDelete, setDelete}) => {
    const {t, lang, setMediaSize} = useLadyService();

    const {
        name,
        cover,
        time,
        date,
        address,
        price,
        description,
        private_party,
        profiles_count,
        profiles,
        id,
        status,
        is_private,
    } = party

    const [enabled, setEnabled] = useState(status);


    const formatDate = () =>{
        const arr = date.split('-')
        return `${arr[2]}.${arr[1]}.${arr[0]}`
    }

    
    const View2 = () => {
        return (
            <div className={`agency-card`}>

                {!enabled && edit &&
                    <Button
                        clazz={'agency-card__trash'}
                        onClick={(e) => deleteParty(e)}
                    >
                        <Icon spritePath={'trash'}/>
                    </Button>}
                <Link
                    to={`/${lang}/party/${id}`}
                    title={name}
                    className="agency-card__img"
                >
                    <img src={setMediaSize(cover, 's')} alt={name}/>
                </Link>
                <div className="agency-card__content">
                    <div className="agency-card__heading">
          <span className="party-card__time"> 
            {`${formatDate()} ${time ? `${t('at')} ${time}` : ''}`}
          </span>
                        <Link to={`/${lang}/party/${id}`} className="title_h3">
                            {name}
                        </Link>
                        <div>
                            {!!address && (
                                <Link
                                    to={`https://www.google.com/maps/search/${address}`}
                                    target="__blank"
                                    className="agency-card__address">
                                    {address}
                                </Link>
                            )}
                        </div>
                    </div>

                    <div className="agency-card__counts">

                        <div className={"agency-card__count"}>
                            <Icon size={"l"} spritePath={"body2"}/>
                            <div className="agency-card__questionnaires">
                  <span>
                    {capitalizeFirstLetter(t('modelsparticipate'))}
                  </span>
                                {profiles_count > 0 ? profiles_count : 0}
                            </div>
                        </div>

                    </div>

                    {!!description && <p className="p-el-4row">{description}</p>}

                    <div className="agency-card__buttons">
                        {edit ?
                            <div className="d-flex fd-column gap-16px w-100">
                                <div className="d-flex gap-16 mb-16">
                                    <ButtonLink
                                        clazz={'button button-s button--green w-100 bottom-0'}
                                        href={`/${lang}/lk/party/${id}`}
                                    >
                                        {t('edit')}
                                    </ButtonLink>
                                    <Button
                                        clazz={`button button-s button--${enabled ? 'secondary' : 'primary'} w-100 bottom-0`}
                                        onClick={() => {
                                            changePartyStatus()
                                        }}
                                    >
                                        {enabled ? t('off') : t('on')}
                                    </Button>
                                </div>
                                <Button
                                    clazz={'button button-s button_outline--black w-100 bottom-0 mt-0'}
                                    onClick={handleSendInvintations}>
                                    {t('sendinvitations')}
                                </Button>
                            </div>
                            :
                            <ButtonLink
                                clazz={'button button-s button--primary w-100 bottom-0 mt-0'}
                                href={`/party/${id}`}>
                                {t('moreinfo')}
                            </ButtonLink>
                        }
                    </div>
                </div>
            </div>)
    }


    const View = () => {
        return (
            <>
                <a title={name} className={`party-card__img`}>
                    {<img src={setMediaSize(cover, "s")} alt={name}/>}
                </a>


                <div className="party-card__content">
                    <div className="party-card__heading">
                        <div className={"party-card__info"}>
                 <span className="party-card__time"> 
                   {!private_party ? time : "******"}

                     {!private_party ? (
                         <span className="party-card__date">{date}</span>
                     ) : (
                         <span className="party-card__date">******</span>
                     )}
                 </span>
                            {/* <span className="party-card__address">
                   {!private_party ? address : "******"} 
      
                    
                 </span>  */}
                        </div>

                        {name && <h3>{name}</h3>}
                    </div>

                    <div className="party-card__counts">
                        <div className="party-card__count color-main">
                            <Icon size={"l"} spritePath={"body2"}/>
                            <div className="party-card__questionnaires">

                                <span>{`${profiles_count ?? 0} ${t("modeles")}`}</span>
                            </div>
                        </div>
                        {price > 0 && (
                            <span className="color-main ">{`${t("participation")} - `}
                                <span className="color-green currency--erocoin">{price}</span>
                </span>
                        )}
                    </div>


                    {description && <p className="color-main mt-20 p-el-4row">{description}</p>}

                    {edit ?
                        <div className="fd-column gap-16 mt-24">
                            <div className="d-flex gap-16">
                                <ButtonLink
                                    clazz={'button button-s button--green w-100 bottom-0'}
                                    href={`${lang}/lk/party/${id}`}
                                >
                                    {t('edit')}
                                </ButtonLink>
                                <Button
                                    clazz={'button button-s button--secondary w-100 bottom-0'}
                                    onClick={changePartyStatus}>
                                    {t('disable')}
                                </Button>
                            </div>
                            <Button
                                clazz={'button button-s button_outline--black w-100 bottom-0 mt-0'}
                                onClick={handleSendInvintations}>
                                {t('invite')}
                            </Button>
                        </div>
                        :
                        <ButtonLink
                            clazz={'button button-s button--primary w-100 bottom-0 mt-24'}
                            href={`/party/${id}`}>
                            {t('moreinfo')}
                        </ButtonLink>
                    }


                </div>

            </>)
    }

    const makeRequest = useMakeRequest();
    const changePartyStatus = () => {
        makeRequest({method: 'PUT', route: `agency/parties/status/${id}`, payload: {status: !enabled}}).then(() => {
            setEnabled(!enabled)
            showToast({message: t('success'), variant: "success"})
        }).catch(() => {
            showToast({message: t('oops'), variant: "error"})
        })
    }
    

    const handleSendInvintations = () => {
        setCurrentParty(id)
        setOpenPopup(true)
        // showToast("Invitations sent successfully", "success");
    }

    const deleteParty = (e) => {
        e.stopPropagation()
        e.preventDefault()
        setOpenDelete(true)
        setDelete(id)
    }

    switch (is_private) {
        case true:
            return (<>
                <div
                    className={"party-card party-card--private"}
                >
                    {View(party)}

                </div>
            </>)
        default: {
            return (<>{View2(party)}</>)
        }
    }
}


export default PartyCard