import React, {useEffect, useState} from "react";
import {
    Banner,
    Breadcrumbs,
    ButtonLink,
    Icon,
    ShowMoreText,
    Video,
} from "@/components/ui";
import {Link, useParams} from "react-router-dom";
import Reviews from "../../reviews/Reviews";
import Slider from "../../sliders/Slider";
import {SwiperSlide} from "swiper/react";
import PartyCard from "../../cards/partyCard/PartyCard";
import PromoCard from "../../cards/promoCard/PromoCard";
import {PartyAccess} from "../../popups";
import { Loader } from "@/components/ui";
import {makeRequest} from "@/services/makeRequest";
import OrderViewing from "../../popups/orderViewing/OrderViewing";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import "./agencyPage.scss";
import useLadyService from "@/services/LadyService";
import {useTranslation} from "react-i18next";
import {setGalleryMediaIndex, setGalleryStatus} from "../../../stores/slices/popupSlice";
import Gallery from "../../popups/gallery/Gallery";
import StoriesComponent from "../../stories/StoriesComponent";
import {useSelector} from "react-redux";
import {getAgency, setIsAgencyPage} from "../../../stores/slices/agencySlice";
import Navigation from "../../navigation/Navigation";
import {Helmet} from "react-helmet";
import {isUrl} from "../../../helper/stringHelper";

const AgencyPage = (props) => {
    const {lang, navigate, dispatch, setMediaSize, userTypeAgency, userTypeIndi} = useLadyService();
    const {t} = useTranslation("translation");
    const [page, setPage] = useState(1)
    const agency = useSelector(getAgency);
    const [oneAgencyData, setOneAgencyData] = useState(agency);
    const [isDataEmpty, setIsDataEmpty] = useState(false);
    const {agencyId} = useParams();
    const [isPartiesLoading, setIsPartiesLoading] = useState(false)

    const {
        slug = oneAgencyData?.slug,
        name = oneAgencyData?.name,
        country = oneAgencyData?.country,
        addresses = oneAgencyData?.addresses,
        phone = oneAgencyData?.phone,
        rating = oneAgencyData?.rating,
        rating_atmosphere = oneAgencyData?.rating_atmosphere,
        rating_charm = oneAgencyData?.rating_charm,
        rating_communication = oneAgencyData?.rating_communication,
        rating_emotions = oneAgencyData?.rating_emotions,
        rating_quality = oneAgencyData?.rating_quality,
        description = oneAgencyData?.description,
        whatsapp = oneAgencyData?.whatsapp,
        telegram = oneAgencyData?.telegram,
        website = oneAgencyData?.website,
        email = oneAgencyData?.email,
        profiles_statistic = oneAgencyData?.profiles_statistic,
        profiles = oneAgencyData?.profiles.data ?? [],
        parties = oneAgencyData?.parties,
        cover = oneAgencyData?.cover,
        promotions = oneAgencyData?.promotions,
        logo = oneAgencyData?.logo,
        preview = oneAgencyData?.preview,
        media = {
            photo: [],
            video: [],
            interior: oneAgencyData?.interior ?? [],
        },
        video = oneAgencyData?.video,
        video_link = oneAgencyData?.video_link,
        reviews = oneAgencyData?.reviews,
        reviews_count = oneAgencyData?.reviews_count,
        address = oneAgencyData?.address,
        city = oneAgencyData?.city
    } = props;

    const [loading, setLoading] = useState(false);
    
    
    


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const payload = {
                    limit: 12,
                }
               makeRequest({route: `agencies/${agencyId}`, method: "GET", payload}).then((data) => {
                    if (!data.data) {
                        navigate("/404");
                    }
                    if (data) {
                        const result = data.data;
                        setOneAgencyData(result);
                        setLoading(false);

                    }
                });

            } catch (error) {
                alert("Error: " + error)
            }
        };

        !agency && agencyId && lang && fetchData();
    }, [agencyId, lang, agency]);



    useEffect(() => {
        dispatch(setIsAgencyPage(true))
        return () => {
            dispatch(setIsAgencyPage(false));
        };
    }, []);



    const breadcrumbsList = [
        {
            id: 1,
            title: t("mainpage"),
            link: "/",
        },
        {
            id: 2,
            title: `${t("agency")} – ${country ?? ''}`,
            link: "/agencies",
        },
        {
            id: 3,
            title: name,
            link: "",
        },
    ];

    const tabsBtns = [
        {
            title: t("ourmodels"),
            icon: "image",
            link: "photo",
        },
        {
            title: t("interior"),
            icon: "home-main",
            link: "interior",
            disabled: !media.interior || !media.interior.length
        },
    ];

    const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');

    const phoneRes = !!formatPhoneNumberIntl(`${phone}`)
    ? formatPhoneNumberIntl(`${phone}`)
    : formatPhoneNumberIntl(`+${phone}`);

    const [galleryMedia, setGalleryMedia] = useState([])



    return (
        <>
            <main className={"agency"}>
                <Helmet>
                    <title>
                        {`${t("titleAgency", {AgencyName: name, Country: country, Count: profiles_statistic?.total})}`}
                    </title>
                    <meta name="description" content={`${t("altagency", {AgencyName: name})} - ${description?.slice(0, 150)}`}/>
                </Helmet>

                <Breadcrumbs data={breadcrumbsList} clazz={"__container mb-20"}/>
                {loading ? <Loader height={'70'}/> : null}
                {!loading && <><Banner
                    props={{
                        title: name,
                        img: setMediaSize(cover, "xl"),
                        imgDesktop: setMediaSize(cover, "m"),
                        imgMobile: setMediaSize(cover, "xs"),
                        rating: rating,
                        model_total: profiles_statistic !== undefined ? profiles_statistic.total : 0,
                        date: "",
                        logoImg:  setMediaSize(logo, "s"),
                        logoImgMobile:  setMediaSize(logo, "s"),
                    }}
                />

                <div className="agency__top __container">
                    <div className="agency__info">
                        {description && <p>{description}</p>}
                        {phone || whatsapp || telegram ? (
                            <div className="agency__buttons">
                                {phone ? (
                                    <ButtonLink
                                        size={"l"}
                                        href={`tel:+${phone}`}
                                        clazz="button--primary td-none w-100"
                                    >
                                        {phoneRes}
                                    </ButtonLink>
                                ) : null}
                                {whatsapp ? (
                                    <ButtonLink
                                        square={true}
                                        size={"l"}
                                        href={`https://wa.me/${whatsapp}`}
                                        target="__blank"
                                        clazz="button--primary"
                                    >
                                        <i className="icon icon-xxl icon--whatsapp"></i>
                                    </ButtonLink>
                                ) : null}

                                {telegram ? (
                                    <ButtonLink
                                        square={true}
                                        size={"l"}
                                        target="__blank"
                                        href={`https://t.me/${telegram}`}
                                        clazz="button--primary"
                                    >
                                        <i className="icon icon-xxl icon--telegram"></i>
                                    </ButtonLink>
                                ) : null}
                            </div>
                        ) : null}



                        <div className="agency__links">
                             {website ? (
                                <Link
                                      to={website}
                                      target="__blank"
                                      className="td-none"
                                  >
                                    <Icon spritePath={"globe"}/>
                                    <span  className="hover-line">{` ${website}`}</span>
                                  </Link>
                                ) : null}

                              {email ? <Link
                                  to={`mailto:${email}`}
                                  target="__blank"
                                 className="td-none"
                              >
                                 <Icon spritePath={"mail"}/>
                                  <span  className="hover-line">{` ${email}`}</span>
                              </Link> : null}

                              <br />


                         {
                             addresses && addresses.length > 1 ? addresses.map((i) => (
                             <Link
                                 to={`https://www.google.com/maps/search/${i.address}`}
                                 target="__blank"
                                 className="hover-line"
                                 title={t('viewonmap')}
                             >
                                 <Icon type={"flag"} spritePath={"CZ"}/>
                                 {` ${i.address}`}
                             </Link>)) 
                             : 
                            (address?.address ?
                            (<Link
                             to={`https://www.google.com/maps/search/${address?.address}`}
                             target="__blank"
                             className="hover-line"
                             title={t('viewonmap')}
                         >
                             <Icon type={"flag"} spritePath={"CZ"}/>
                             {` ${address?.address}`}
                         </Link>) : 
                         city && <span className="d-flex gap-8">
                            <Icon type={"flag"} spritePath={"CZ"} />
                            {city}
                         </span>)
                         }
                        </div>
                        {media.interior.length > 0 && <section className="agency__media__video">
                            {(video && video.length) || isUrl(video_link) ? (
                                <div className="agency__block">
                                    <h2 className="mb-32">{t('videopresentation')}</h2>

                                    <Video controls={true} src={video} iframe={video_link}/>
                                </div>
                            ) : null}
                        </section>}

                    </div>
                    {!isJsdom ? (<>
                    {media.interior.length > 0 ?
                    <>
                        <section className="agency__media__interior __container">
                            <>
                             {/*
                             <TabSlider tabs={tabsBtns} clazz={"agency-tabs"}>
                                { <SwiperSlide
                                    className={`agency-tabs__slide`}
                                    data-hash={tabsBtns[0].link}
                                >
                                    {profiles.slice(0, 6).map((profile, index) => {
                                        const result = () => {
                                            dispatch(setStoriesStatus(true));
                                            dispatch(setStoriesId(index));
                                        };
                                        return (
                                            <img
                                                src={setMediaSize(profile.main_photo, "m")}
                                                srcSet={`
                                                  ${setMediaSize(profile.main_photo, "s2")} 220w,
                                                  ${setMediaSize(profile.main_photo, "m")} 800w,
                                                `}
                                                sizes="(max-width: 600px) 200px, 750px"
                                                loading="lazy"
                                                onClick={() => result()}
                                                alt=""
                                            />
                                        );
                                    })}
                                </SwiperSlide>}
                                { 
                                    <SwiperSlide
                                        className={`agency-tabs__slide`}
                                        data-hash={tabsBtns[1].link}
                                    >
                                        {media.interior.slice(0, 6).map((image, index) => {
                                            const result = () => {
                                                dispatch(setGalleryMediaIndex(index));
                                                dispatch(setGalleryStatus(true));
                                            };
                                            return (
                                                <img
                                                    src={image}
                                                    onClick={() => result()}
                                                    alt=""
                                                />
                                            );
                                        })}
                                    </SwiperSlide>
                                }
                            </TabSlider> 
                            */}
                            </>

                            <h3 className="mt-8"><Icon size={"s"} spritePath={tabsBtns[1].icon}/>{` ${tabsBtns[1].title}`}</h3>
                                <SwiperSlide
                                    className={`agency-tabs__slide mt-16`}
                                    data-hash={tabsBtns[1].link}
                                >
                                        {media.interior.slice(0, 6).map((image, index) => {
                                            const result = () => {
                                                dispatch(setGalleryMediaIndex(index));
                                                dispatch(setGalleryStatus(true));

                                        };
                                        return (
                                            <img
                                                src={image}
                                                onClick={() => result()}
                                                alt=""
                                            />
                                        );
                                        })}
                                </SwiperSlide>
                        </section>

                    </> : null}

                    {media.interior.length === 0 &&
                    <section className="agency__media__video">


                            {(video && video.length) || isUrl(video_link) ? (
                                <div className="agency__block">
                                    <h2 className="mb-32">{t('videopresentation')}</h2>

                                    <Video controls={true} src={video} iframe={video_link}/>
                                </div>
                            ) : null}
                        </section>}

                        

                    {(promotions?.length > 0 || parties?.length > 0)  ? (
                    <section className="agency-party w-100">
                        <h3 className="mb-32">{t('agencypromos')}</h3>
                        {!isJsdom ? 
                            <Slider clazz={"agency-party"} type={"agency"}>
                                {parties &&
                                   parties.map((data) => (
                                       <SwiperSlide>
                                           <PartyCard party={data}/>
                                       </SwiperSlide>
                                   ))}
                                {promotions &&
                                    promotions.map((data) => (
                                        <SwiperSlide>
                                            <PromoCard promo={data} setGalleryMedia={setGalleryMedia}/>
                                        </SwiperSlide>
                                    ))}
                            </Slider> 
                            :  
                            <>
                                {parties &&
                                       parties.map((data) => (
                                           <PartyCard party={data} setGalleryMedia={setGalleryMedia}/>
                                       ))}
                                {promotions &&
                                        promotions.map((data) => (
                                            <PromoCard promo={data} setGalleryMedia={setGalleryMedia}/>
                                        ))}
                            </>
                        }
                    </section>
                    ) : null}
                       

                </>) : (<>

                    </>) }

                </div>



                {profiles.length ? 
                    <Navigation 
                        profiles_statistic={profiles_statistic} 
                        agencyId={agencyId} 
                        checkbox={preview?.status && !(userTypeAgency || userTypeIndi)} 
                        mainPage={false}
                        setIsDataEmpty={setIsDataEmpty} 
                        isDataEmpty={isDataEmpty} 
                        preview_price={preview?.price}
                        
                    />        
                : <></>}

                <Reviews
                    props={{
                        clazz: "__container",
                        slug,
                        rating,
                        rating_atmosphere,
                        rating_charm,
                        rating_communication,
                        rating_emotions,
                        rating_quality,
                        reviews_count,
                        reviews,
                        name,
                    }}
                /></>}
            </main>

            <PartyAccess/>

            {!!profiles.length && (
                <StoriesComponent
                    favoriteButton
                    descriptionText
                    profileLink
                    stories={profiles}
                />
            )}
            {!!media.interior.length && (
                <Gallery id={'Interior'} name={name} media={media}/>
            )}

            {!!promotions && (
                <Gallery id={'promos'} name={name} media={galleryMedia}/>
            )}

        </>
    );
};

export default AgencyPage;
