import example1 from "@/assets/img/examplePhotos/verExamplePlus.svg";
import { Button, Icon } from "@/components/ui";
import unilabs20 from "@/assets/img/unilabs.svg";
import unilabs20light from "@/assets/img/unilabs.svg";
import { useEffect, useState } from "react";

import "./verHealthAgency.scss";
import ExampleImage from "../exampleImage/ExampleImage";
import useLadyService from "@/services/LadyService";
import UploadImage from "../uploadImage/UploadImage";
import { Link } from "react-router-dom";
import { setHealthStatus, setVerStatus } from "@/stores/slices/lkIndiSlice";
import { DiscountToHealthPopup } from "@/components/popups";
import PhotoUploadSuccess from "../photoUploadSuccess/PhotoUploadSuccess";
import { setSuperVerStatus } from "@/stores/slices/lkIndiSlice";

const VerHealthAgency = ({
  setDataInfo,
  healthRef,
  isHealthBoosted,
  healthy_at,
  data: dataInfo = {},
  setCurrentType,
  handleUpload,
  mediaUploaded,
  handleFileSelected,
  setCurrentSlug,
  slug,
}) => {
  const {
    is_super_verified = false,
    healthy_photo = {},
    verify_photo,
    healthy_reason,
  } = dataInfo;

  const data = is_super_verified ? verify_photo.data || {} : {};
  const { data: dataHealthy = {} } = healthy_photo;
  const { link = null } = data;
  const {
    link: linkHealthy = null,
    moderated: moderatedHealthy,
    name,
  } = dataHealthy;
  useEffect(() => {
    setImageAnalysis(linkHealthy);
    setRemoveLinkHealthy(linkHealthy);
  }, [linkHealthy]);
  const { t, theme, dispatch, windowWidth } = useLadyService();

  const [isOpenDiscount, setIsOpenDiscount] = useState(false);

  const src = theme === "dark" ? unilabs20light : unilabs20;
  const handleOpenDiscount = () => {
    setIsOpenDiscount(true);
  };
  const isMobile = windowWidth < 767.98;

  const [image, setImage] = useState(link);

  const [removeLink, setRemoveLink] = useState(link);
  const [imageAnalysis, setImageAnalysis] = useState(linkHealthy);
  const [removeLinkHealthy, setRemoveLinkHealthy] = useState(linkHealthy);

  useEffect(() => {
    if (link) {
      setImage(link);
    }
  }, [link]);

  useEffect(() => {
    if (image) {
      dispatch(setVerStatus(true));
      dispatch(setSuperVerStatus(true));
    } else {
      dispatch(setSuperVerStatus(false));
    }
  }, [image]);

  useEffect(() => {
    if (imageAnalysis) {
      dispatch(setHealthStatus(true));
    } else {
      dispatch(setHealthStatus(false));
    }
  }, [imageAnalysis]);

  const MobileWrapper = ({ children }) => {
    if (isMobile) {
      return <div className="d-flex gap-8 align-center">{children}</div>;
    } else {
      return children;
    }
  };

  return (
    <>
      <DiscountToHealthPopup
        open={isOpenDiscount}
        setOpen={setIsOpenDiscount}
      />

      {isMobile && <hr />}

      <div
        className={`${isMobile ? "" : "verads control"}`}
        id={"super-verification"}
        ref={healthRef}
      >
        <div className="verads__title mt-16">
          <div className="d-flex fd-column">
            <h3 className="mb-4 mt-0">
              {t("getstatuses")} <br />
              {t("healthy")} {t("and")} {t("verification")}
            </h3>
          </div>

          <MobileWrapper>
            <span
              title={t("photosverified")}
              className={`${isMobile ? "" : "ml-auto"} model__icon`}
            >
              <Icon spritePath={"verify-fill"} size={"l"} />
              {t("verification")}
            </span>

            <span className="color-green">+</span>

            <span title={t("advantagehealth")} className={"model__icon"}>
              <Icon spritePath={"health-fill"} size={"l"} />
              {t("healthy")}
            </span>
          </MobileWrapper>
        </div>

        {!image && (
          <div className="verads__photo mt-12">
            <div className="verads__examplePhoto text-center">
              {t("example")}
              <br></br>
              {t("superidphoto")}
              <img src={example1} alt={t("example")} />
            </div>
            <UploadImage
              setData={setImage}
              mainText={t("superidphoto")}
              subText={t("photodisplayid")}
              type={"is_super_verify"}
              handleUpload={handleFileSelected}
              setRemoveLink={setRemoveLink}
            />
          </div>
        )}

        {!!image && (
          <div className="verads__photo mt-12">
            <ExampleImage
              setDataInfo={setDataInfo}
              image={image}
              setImage={setImage}
              removeLink={removeLink}
            />

            {imageAnalysis && (
              <ExampleImage
                type="healthy"
                name={name}
                image={imageAnalysis}
                setImage={setImageAnalysis}
                removeLink={removeLinkHealthy}
              />
            )}

            {!imageAnalysis && (
              <>
                <div className="verads__examplePhoto text-center">
                  <div className="d-flex fd-column align-center">
                    <Icon
                      title={""}
                      spritePath={"check"}
                      size={"l"}
                      clazz={"color-green"}
                    />
                    <div className="d-flex gap-4 fd-column">
                      <span className="p1 color-green">
                        {t("superidphoto")} {t("uploaded")}
                      </span>
                      <p className="p3">{t("")}</p>
                    </div>
                  </div>
                </div>
                <UploadImage
                  pdf={true}
                  handleUpload={handleFileSelected}
                  setData={setImageAnalysis}
                  mainText={t("requestanalysis")}
                  subText={t("testtodownload")}
                  type={"is_healthy"}
                  setRemoveLink={setRemoveLinkHealthy}
                />
              </>
            )}

            {!mediaUploaded && imageAnalysis && (
              <div className="verads__examplePhoto">
                <div className="d-flex gap-8 align-center">
                  <i
                    title=""
                    className="icon icon-l icon--check color-green"
                  ></i>
                  <div className="d-flex gap-4 fd-column">
                    <span className="p1 color-green">{t("tested")}</span>
                  </div>
                </div>

                {/* requestver here */}

                <Button
                  title={t("requestver")}
                  clazz={"button--green justify-center min-w-160 mt-12"}
                  size={"m"}
                  onClick={handleUpload}
                >
                  {t("requestver")}
                </Button>
              </div>
            )}

            {mediaUploaded && imageAnalysis && (
              <PhotoUploadSuccess
                moderatedHealthy={moderatedHealthy}
                isHealthBoosted={isHealthBoosted}
                setCurrentType={setCurrentType}
                healthy_reason={healthy_reason}
                setCurrentSlug={setCurrentSlug}
                slug={slug}
                type={"analiz"}
                at={healthy_at}
              />
            )}
          </div>
        )}

        {!imageAnalysis && !!image && (
          <p className="p2 color-main mt-16">{t("nowyouhelth")}</p>
        )}

        <p className="mt-32">
          {t("healthimpornantinfo")}
          {"."}
          <br></br>
          {t("Status")}{" "}
          <Link
            target="_blank"
            to={"/guide#healthControl"}
            className={"color-green hover-line"}
          >
            {'"' + t("healthy") + '"'}
          </Link>{" "}
          {t("healthforinfo")}
          {"."}
        </p>
        <div
          className={`d-flex gap-16 justify-sb align-center mt-12 ${
            isMobile ? "fd-column" : ""
          }`}
        >
          {isMobile && <hr className="w-100"></hr>}

          <img src={src} alt="unilabs" className="h-48 color-main" />

          <span className="fz-13">{t("discountstds")}</span>

          <Button
            title={t("qrcodediscount")}
            clazz={"button--primary justify-center min-w-160"}
            size={"s"}
            onClick={handleOpenDiscount}
          >
            {t("qrcodediscount")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default VerHealthAgency;
