import { Avatar, Button, RatingStars } from "@/components/ui";
import VideoStatus from "./VideoStatus";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { CheckPhonePopup } from "../../../popups";
import "./videoContainer.scss";
import useLadyService from "../../../../services/LadyService";
import { makeRequest } from "../../../../services/makeRequest";
import { setVideochatAmount } from "../../../../stores/slices/userSlice";
import { setLadyForCall } from "../../../../stores/slices/videoCallSlice";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import {Icon, UserBadge} from "../../../ui";
import {format, fromUnixTime} from "date-fns";

const statusObj = {
  1: "workstatus",
  4: "approvestatus",
  6: "declinestatus",
  7: "canceledbyclient",
};

const VideoContainer = (props) => {
  const { data, setData } = props;

  const { from, price, profile, status, uuid, reviews, created_at, duration } =
    data;


  const {
    name: nameFrom,
    phone: phoneFrom,
    rating: ratingFrom,
    tariff: tariffFrom,
  } = from;

  const phoneRes = !!formatPhoneNumberIntl(`${phoneFrom}`)
    ? formatPhoneNumberIntl(`${phoneFrom}`)
    : formatPhoneNumberIntl(`+${phoneFrom}`);

  const { name: nameProfile, photo: photoProfile, slug: slugProfile } = profile;

  const [isOpenCheckPhone, setIsOpenCheckPhone] = useState(false);

  const { dispatch, lang, t, setMediaSize } = useLadyService();

  function secondsToMinutes(seconds) {
    return Math.ceil(seconds / 60);
  }

  function calculateCost(duration) {
    const minutes = secondsToMinutes(duration);
    const cost = minutes * price;
    return cost;
  }

  const yougetmoney = calculateCost(duration);

  const navigate = useNavigate();

  let classVariant;
  switch (statusObj[status]) {
    case "approvestatus": {
      classVariant = " approve";
      break;
    }

    case "canceledbyclient":
    case "declinestatus": {
      classVariant = " decline";
      break;
    }

    default: {
      classVariant = " work";
    }
  }

  const handleOpen = () => {
    setIsOpenCheckPhone(true);
  };

  const startedAt = format(fromUnixTime(created_at), 'dd.MM.yyyy HH:mm');

  let minutes = Math.floor(duration / 60);
  let remainingSeconds = duration % 60;
  minutes = minutes.toString().padStart(2, "0");
  remainingSeconds = remainingSeconds.toString().padStart(2, "0");

  const time = minutes + ":" + remainingSeconds;

  const handleStatus = (status, id) => {
    const fetchData = async () => {
      try {
        const route = `calls/${id}/cancel`;
        const method = "PUT";

        await makeRequest({ route, method });
        setData((prevData) => {
          return prevData.map((item) => {
            if (item.uuid === id) {
              return { ...item, status: 6 };
            }
            return item;
          });
        });
      } catch (error) {}
    };

    if (status === "confirm") {
      dispatch(setLadyForCall(data));
      dispatch(
        setVideochatAmount((prev) => {
          if (prev - 1 < 0) {
            return 0;
          } else {
            return prev - 1;
          }
        })
      );
      navigate(`/${lang}/call/${id}`);
    } else {
      dispatch(setLadyForCall(null));
      dispatch(
        setVideochatAmount((prev) => {
          if (prev - 1 < 0) {
            return 0;
          } else {
            return prev - 1;
          }
        })
      );

      fetchData();
    }
  };

  return (
    <>
      {isOpenCheckPhone && (
        <CheckPhonePopup
          open={isOpenCheckPhone}
          setOpen={setIsOpenCheckPhone}
          phoneClient={phoneFrom}
        />
      )}
      <div className={"res__root"}>
        <div className={`res__user__main${classVariant}`}>
          <div className="res__user__block">
            <UserBadge
              props={{
                slug: tariffFrom,
                name: nameFrom,
                score: ratingFrom
              }}
            />
            <span className="p3 color-main">{startedAt}</span>
          </div>
        </div>
        <div className={`video__info__block${classVariant}`}>
          <div className="res__price">
                <span>{t("calltime")}</span>
                <span className="title_h3 m-0 d-flex gap-4 align-center fd-row">
                  {(duration === 0 || !duration) && (status === 6 || status === 7) ?
                    '00:00'
                    : !duration || duration === 0 ? '00:00' : time}
                </span>

                <hr />

                {status !== 1 ?
                <>
                  <span>{t("yougetmoney")}</span>
                  <span className="title_h3 m-0 d-flex gap-4 align-center fd-row">
                    {yougetmoney}{" "}
                    <Icon
                      title={t("EroCoins")}
                      clazz={"color-green"}
                      spritePath={"erocoin"}
                      size={"m"}
                    />
  
                    {!duration || duration === 0 && <span>{t("inminute")}</span>}
  
                  </span>
                </>
                :
                <>
                  <span >{t("youwillget")}</span>
                  <span className="title_h3 m-0 d-flex gap-4 align-center fd-row">
                    1{" "}
                    <Icon
                      title={t("EroCoins")}
                      clazz={"color-green"}
                      spritePath={"erocoin"}
                      size={"m"}
                    />
                    {" "}
                    {t('inminute')}
                  </span>
                </>}
          </div>
          <Link
            to={`/profile/${slugProfile}`}
            target={"_blank"}
            className="res__ad hover-line"
            alt="ad"
          >
            <img
              src={!!photoProfile?.length ? setMediaSize(photoProfile[0], "xs") : null}
              className="res__ad__photo mr-8"
              alt="ad"
            />
            <span className="p1 mt-8 video__ad__title color-green">
              {nameProfile}
            </span>
          </Link>
        </div>
        <VideoStatus
          status={status}
          reviews={reviews}
          handleStatus={handleStatus}
          id={uuid}
          setData={setData}
          date={created_at}
        />
      </div>
    </>
  );
};

export default VideoContainer;
