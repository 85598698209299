import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {getApiUserReady} from "@/stores/slices/userSlice";
import {useEffect, useState} from "react";
import useLadyService from "@/services/LadyService";
import useUserProfileService from "../../services/UserProfileService";

const routeRoles = {
    "/login": ["admin"],
    "/preview": ["admin", "moderator"],
    "/lk/create": ["indi", "agency", "admin", "moderator"],
    "/lk/customer": ["customer", "admin"],
    "/lk/agency": ["agency", "admin"],
    "/lk/parties": ["agency", "admin"],
    "/lk/indi": ["indi", "admin", "moderator"],
    "/lk/topup": ["indi", "customer", "agency", "admin"],
    "/lk/balance": ["indi", "customer", "agency", "admin"],
    "/lk/withdraw": ["indi", "customer", "agency", "admin"],
    "/lk/operators": ["agency", "admin"],
    "/lk/profiles": ["indi", "agency", "admin", "moderator"],
    "/lk/videochats": ["indi", "agency", "admin"],
    "lk/reservations": ["indi", "agency", "admin"],
    "/lk/reviews": ["indi", "agency", "admin"],
    "/lk/previews": ["indi", "agency", "admin"],
    "/lk/statistic": ["indi", "agency", "admin"],
    "/lk/profile": ["indi", "agency", "admin"],
    "/lk/support": ["indi", "agency", "admin", "customer"],
    "/lk/changeparty": ["agency", "admin"],
    "/lk/party": ["indi", "customer", "agency", "admin"],
};

function PrivateRoute({children}) {
    const {userType, token} = useLadyService();
    const navigate = useNavigate();

    const apiReady = useSelector(getApiUserReady);

    const location = useLocation();
    const currentPath = location.pathname;

    const [renderElem, setRenderElem] = useState(false);
    useEffect(() => {
        if (token && apiReady) {
            const isAuthorized = Object.keys(routeRoles).some((route) => {
                if (currentPath.includes(route)) {
                    setRenderElem(true);
                    return routeRoles[route].includes(userType);
                }
                setRenderElem(false);
                return false;
            });

            if (!isAuthorized) {
                console.log('private-router');
                navigate("/");
            }
        }
        if (!token && !apiReady) {
            console.log('private-router');
            navigate("/");
        }
    }, [apiReady, currentPath, userType, token, navigate]);

    if (!token && !renderElem) {
        return null;
    } else {
        return children;
    }
}

export default PrivateRoute;
