import axios from "axios";
import useLadyService from "./LadyService";
import showToast from "@/components/toast/Toast";

const useHandleLocationClick = () => {
    const {lang, t} = useLadyService();

    const checkAddressInCity = async (city, lat, lng) => {
        const latLng = new window.google.maps.LatLng(lat, lng);
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({address: `${city}`}, (results, status) => {
            if (status === "OK" && results[0]) {
                const cityBounds = results[0].geometry.bounds;
                if (cityBounds && cityBounds.contains(latLng)) {
                    return true;
                }
            }
        });


        return false;
    }

    const handleLocationClick = (currentCity = false) => {
        return new Promise((resolve, reject) => {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        const {latitude, longitude} = position.coords;
                        try {
                            const response = await axios.get(
                                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1&accept-language=${lang}`
                            );


                            const {
                                city: cityRes,
                                road,
                                house_number,
                                town,
                            } = response.data.address;

                            const city = cityRes || town;
                            if (currentCity) {
                                if (await checkAddressInCity(currentCity, latitude, longitude)) {
                                    resolve({city, road, house_number, latitude, longitude})
                                } else {
                                    showToast({message: t("inuptadress", {City: currentCity}), variant: "error"  });
                                }
                            } else {
                                resolve({city, road, house_number, latitude, longitude});
                            }
                        } catch (error) {
                            reject(error);
                        }
                    },
                    (error) => {
                        reject(error);
                    }
                );
            } else {
                reject(new Error("Geolocation is not supported by this browser."));
            }
        });
    };

    return {
        handleLocationClick,
        checkAddressInCity
    };
};

export default useHandleLocationClick;
