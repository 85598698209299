import { Icon } from "@/components/ui";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useLadyService from "@/services/LadyService";
import EditBlock from "../editBlock/EditBlock";

const ModelExtras = ({ props, edit, setIsOpenEditMain}) => {
  const {
    gf,
    ready_travel,
    is_escort,
    porn_star,
  } = props;

  const { travelVariants, lang } = useLadyService();

  const { t } = useTranslation("translation");

  return !!gf.length || !!porn_star || !!ready_travel || !!is_escort ? (
    <div className="model-extras">
      {!!gf.length && 
       gf?.map((model, index) => { return (
        <Link
          key={index}
          target={"_blank"}
          to={`/${lang}/profile/${model.slug}`}
          className={"p2 color-600 width-max text-dots hover"}
        >
          <Template translateKey={t("girlfriend")} icon={"girlfriend"}>
            <span>
                {model.slug}
            </span>
          </Template>
        </Link>
        )}
      )}

      {!!porn_star?.length && (
        <Link
          target={"_blank"}
          to={porn_star?.website_link ? `//${porn_star.website_link}` : ""}
          className={"p2 text-dots"}
        >
          <Template translateKey={t("pornstar")} icon={"star"}>
            
              {porn_star.website_link}
           
          </Template>
        </Link>
      )}

      {!!ready_travel && (
        <Template translateKey={t("travel")} icon={"travel"}>
          <span className={"p2 color-600"}>{travelVariants[ready_travel]}</span>
        </Template>
      )}

      {!!is_escort && (
        <Template translateKey={t("accompaniment")} icon={"escort"}>
          <span className={"p2 color-600"}>{t("nosex")}</span>
        </Template>
      )}

      {!!edit && (
        <EditBlock
          onClick={() => setIsOpenEditMain(true)}
          size={'l-forever'}
        />
      )}
    </div>
  ) : null;
};

const Template = ({ translateKey, icon, children }) => (
  <div title={translateKey} className={"button button-l button--tetriary"}>
    <Icon spritePath={icon} size={"xxl"} />
    <span className={"model-extras__text p1"}>
      {translateKey}
      {children}
    </span>
  </div>
);

export default ModelExtras;
