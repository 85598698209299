import logo from "@/assets/img/logo.svg";
import logoLight from "@/assets/img/logo-light.svg";
import useLadyService from "@/services/LadyService";
import { useMemo } from "react";

const LogoSvg = ({ alt, className = "logo-img" }) => {
  const { theme } = useLadyService();

  const logoSrc = useMemo(() => {
    return theme === "light" ? logoLight : logo;
  }, [theme]);

  return <img className={className} src={logoSrc} alt={alt} />;
};

export default LogoSvg;
