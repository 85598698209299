import { Helmet } from "react-helmet";
import { Icon } from "..";
import { setTheme, toggleTheme } from "@/stores/slices/userSlice";
import useLadyService from "@/services/LadyService";
import "./switchTheme.scss";

const SwitchTheme = (props) => {
  const {
    theme,
    dispatch
  } = useLadyService();

  const { textLight, textDark, type } = props;

  const TypeOfSwitcher = () => {
    switch (type) {
      case "switch":
        return (
          <>
            <button
              onClick={() => dispatch(toggleTheme())}
              type={"button"}
              className={"switch-theme"}
            >

              <span
                className={`p2 switch-theme__check${
                  theme === "light" ? " _active" : ""
                }`}
              >
                <span
                  className={`p2 switch-theme__check${
                    theme === "light" ? " _active" : ""
                  }`}
                >
                  <Icon size={"m"} spritePath={"light"} />
                  {textLight ? textLight : null}
                </span>
              </span>

              <span
                className={`p2 switch-theme__check${
                  theme === "dark" ? " _active" : ""
                }`}
              >
                <span
                  className={`p2 switch-theme__check${
                    theme === "dark" ? " _active" : ""
                  }`}
                >
                  <Icon size={"m"} spritePath={"dark"} />
                  {textDark ? textDark : null}
                </span>
              </span>

              <span
                className={`switch-theme__check-bar ${
                  theme === "dark" ? "_active--dark" : "_active--light"
                }`}
              ></span>
            </button>
          </>
        );
      default:
        return (
          <>
            <div className={"switch-theme"}>

              <span
                onClick={() => dispatch(setTheme("light"))}
                className={`p2 switch-theme__check${
                  theme === "light" ? " _active" : ""
                }`}
              >
                <Icon size={"m"} spritePath={"light"} />
                {textLight ? textLight : null}
              </span>

              <span
                onClick={() => dispatch(setTheme("dark"))}
                className={`p2 switch-theme__check${
                  theme === "dark" ? " _active" : ""
                }`}
              >
                <Icon size={"m"} spritePath={"dark"} />
                {textDark ? textDark : null}
              </span>

              <span
                className={`switch-theme__check-bar ${
                  theme === "dark" ? "_active--dark" : "_active--light"
                }`}
              ></span>
            </div>
          </>
        );
    }
  };

  return <>{TypeOfSwitcher()}</>;
};

export default SwitchTheme;
