import {VerificationAndHealthCheck, VerHeader} from "./pageComponets";
import {useRef, useState} from "react";
import {useEffect} from "react";
import AdCard from "./card/AdCard";
import {ImportAdPopup, MakeEliteAdOnePopup} from "@/components/popups";
import {useTranslation} from "react-i18next";
import {Button, Icon, Skeleton} from "@/components/ui";
import useLadyService from "@/services/LadyService";
import {
    setUserInfoAlreadyExist,
    setCountriesData,
} from "@/stores/slices/userSlice";
import RequestVerPopup from "@/components/popups/requestVerPopup/RequestVerPopup";
import FastVerification from "@/components/popups/fastVerification/FastVerification";
import AddOrImportProfile from "@/components/popups/addOrImportProfile/AddOrImportProfile";
import {setIndiProfiles} from "@/stores/slices/lkIndiSlice";
import AgencyFilter from "@/components/popups/agencyFilter/AgencyFilter";
import {AgencyProfileActions} from "../../agency/agencyProfileActions/AgencyProfileActions";
import "./profilesPage.scss";
import {useMakeRequest} from "@/hooks/useMakeRequest";
import showToast from "../../../../toast/Toast";
import Pagination from "../../../../pagination/Pagination";
import {useNavigate} from "react-router-dom";
import debounce from "lodash.debounce";
import isEqual from "lodash.isequal"


const Res = ({
                 isLoading,
                 options,
                 view,
                 variant,
                 filteredOptions,
                 setData,
                 setCount,
                 dataInfo,
                 verRef,
                 setCurrentSlug,
                 setCurrentType,
                 setCurrentCallback,
                 healthy_boost,
                 isHealthBoosted,
                 agencySelectCheckbox,
                 setAgencySelectCheckbox,
                 page,
                 pagesList,
                 handlePage,
                 cardsPerPage,
             }) => {


    const skeletons = [...Array(cardsPerPage)].map((_, index) => (
        <Skeleton key={index}/>
    ));

    const {t} = useLadyService();
    switch (true) {
        case isLoading: {
            return <div className={`profiles-page__grid-ads small`}>{skeletons}</div>;
        }

        case !!filteredOptions.length: {
            return (
                <>
                    <div className={`profiles-page__grid-ads${variant}`}>
                        {filteredOptions.map((data) => {
                            return (
                                <AdCard
                                    setAgencySelectCheckbox={setAgencySelectCheckbox}
                                    agencySelectCheckbox={agencySelectCheckbox}
                                    healthyAt={dataInfo.healthy_at}
                                    isHealthBoosted={isHealthBoosted}
                                    healthy_boost={healthy_boost}
                                    data={data}
                                    view={view}
                                    dataInfo={dataInfo}
                                    setData={setData}
                                    setCount={setCount}
                                    key={data.slug}
                                    verRef={verRef}
                                    setCurrentSlug={setCurrentSlug}
                                    setCurrentType={setCurrentType}
                                    setCurrentCallback={setCurrentCallback}
                                />
                            );
                        })}
                    </div>
                    {pagesList.length > 1 && (
                        <Pagination
                            pages={pagesList}
                            activePage={page}
                            onPageChange={handlePage}
                        />
                    )}
                </>
            );
        }

        case !filteredOptions.length: {
            return <span className="w-100 text-center">{t("emptyrecord")}</span>;
        }

        case !options.length: {
            return <span className="w-100 text-center">{t("zeroads")}</span>;
        }

        default: {
            return <div className={`profiles-page__grid-ads small`}>{skeletons}</div>;
        }
    }
};

const ProfilesPage = ({asComponent = false, selectedCheckboxes = [], selectCheckboxHandler = false}) => {
    const {windowWidth, lang, dispatch, userType} = useLadyService();
    const verRef = useRef(null);
    const healthRef = useRef(null);

    const [dataInfo, setDataInfo] = useState({});

    const {
        profiles_count,
        verify_count,
        healthy_count,
        verify_wait_count,
        verify_decline_count,
        healthy_boost,
    } = dataInfo;

    const isAgency = userType === "agency";

    const [count, setCount] = useState(profiles_count);
    const [isLoadingInfo, setIsLoadingInfo] = useState(true);
    const [data, setData] = useState([]);
    const [pagesList, setPagesList] = useState([]);
    const [page, setPage] = useState(1);
    const [currentSlug, setCurrentSlug] = useState("");
    const [currentCallback, setCurrentCallback] = useState(() => {});
    const [currentType, setCurrentType] = useState("verification");
    const [isHealthBoosted, setIsHealthBoosted] = useState(false);
    const navigate = useNavigate();

    const handlePage = (e, value) => {
        e.preventDefault();
        navigate(`/${lang}/lk/profiles#${value}`);
        setPage(value);
    };

    useEffect(() => {
        if (!!healthy_boost) {
            setIsHealthBoosted(true);
        }
    }, [healthy_boost]);

    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState(null);
    const [filters, setFilters] = useState(false);



    useEffect(() => {
        const handler = debounce(() => {
            fetchData();
        }, 500);
        if (search !== null || filters) {
            handler();
        }
        return () => {
            handler.cancel();
        };
    }, [search, filters]);

    const [view, setView] = useState("standart");

    const {t} = useTranslation();

    const [isOpenImport, setIsOpenImport] = useState(false);
    const [isAgencyFilterOpen, setIsAgencyFilterOpen] = useState(false);
    const [genderChecked, setGenderChecked] = useState([]);
    const [activeToggle, setActiveToggle] = useState([]);
    const [extraChecked, setExtraChecked] = useState([]);
    const [filterCount, setFilterCount] = useState(0);
    const [cardsPerPage, setCardsPerPage] = useState(localStorage.getItem("cardsPerPage") ?? 12);
    const [makeEliteAgency, setMakeEliteAgency] = useState(false);

    const getCombinedArray = () => {
        return [...genderChecked, ...activeToggle, ...extraChecked];
    };
    useEffect(() => {
        const combinedArray = getCombinedArray();

        setFilterCount(combinedArray.length);
    }, [genderChecked, activeToggle, extraChecked]);

    const [allSlugs, setAllSlugs] = useState([]);

    const resetFilter = () => {
        setGenderChecked([]);
        setActiveToggle([]);
        setExtraChecked([]);
        setFilters({});
    };

    const [agencySelectCheckbox, setAgencySelectCheckbox] = useState(selectedCheckboxes);
    useEffect(() => {
        if (selectCheckboxHandler && !isEqual(agencySelectCheckbox, selectedCheckboxes)) {
            selectCheckboxHandler(agencySelectCheckbox)
        }
    }, [agencySelectCheckbox, selectCheckboxHandler])
    const fzForStatistic = windowWidth && windowWidth < 1200 ? "fz-14" : "fz-16";

    const openVer = () => {
        if (userType !== 'agency') {
            navigate("#verificate_health")
            if (verRef.current) {
                verRef.current.scrollIntoView({behavior: "smooth", block: "start"});
            }
        } else {
            window.open(window.location.origin + "/guide#verify")
            setAgencySelectCheckbox([])
        }

    };

    const openHealth = () => {

        if (userType !== 'agency') {
            navigate("#verificate_health")
            if (verRef.current) {
                verRef.current.scrollIntoView({behavior: "smooth", block: "start"});
            }
        } else {
            window.open(window.location.origin + "/guide#healthControl")
        }
    };

    let variant;
    switch (true) {
        case windowWidth < 1199: {
            variant = " small";
            break;
        }
        case view === "standart": {
            variant = "";
            break;
        }

        default:
        case view !== "standart": {
            variant = " small";
            break;
        }
    }
    const makeRequest = useMakeRequest();
    useEffect(() => {
        const fetchData = async () => {
            setIsLoadingInfo(true);
            try {
                const route = `user/info`;
                const method = "GET";
                const [data] = await Promise.all([makeRequest({route, method})]);
                if (data) {
                    const result = data.data;
                    setCount(result.profiles_count);
                    setDataInfo(result);

                    dispatch(setUserInfoAlreadyExist());
                }

                setIsLoadingInfo(false);
            } catch (error) {
            }
        };

        fetchData();
    }, []);

    const fetchData = (data = {}) => {
        setIsLoading(true);

        const route = `user/profiles`;

        const method = "GET";
        const limit = cardsPerPage;
        const payload = {
            ...data,
            ...(filters && {...filters}),
            ...(search && {search}),
            ...(page && {page}),
            limit,
        };
        makeRequest({
            route,
            method,
            payload,
        })
            .then((respone) => {
                const {profiles, cities, meta} = respone;
                if (isAgency) {
                    setAllSlugs(profiles.map((item) => item.slug));
                }
                setData(profiles);
                dispatch(setCountriesData(cities));
                dispatch(setIndiProfiles(profiles));
                const {per_page, total} = meta;
                const result = Math.ceil(total / per_page);
                const arrayRes = Array.from({length: result}, (_, i) => i + 1);
                setPagesList(arrayRes);
                setIsLoading(false);
            })
            .catch(() => {
                showToast({message: t("oops"), variant: "error"});
            });
    };
    useEffect(() => {
        lang && fetchData();
    }, [lang, page]);

    useEffect(() => {
        fetchData();
    }, [cardsPerPage]);

  const setOnlineStatus = async (status) => {
    const profiles = { ...agencySelectCheckbox };
    await makeRequest({
      route: "agency/profiles/set-online",
      method: "PUT",
      payload: { status, profiles },
    }).then(() => {
      const profilesData = JSON.parse(JSON.stringify(data))
            Object.keys(profiles).forEach((key) => {
                const slug = profiles[key]
                const profileIndex  = profilesData.findIndex((profile) => profile.slug === slug)
                if (profileIndex !== -1) {
                    profilesData[profileIndex] = {
                        ...profilesData[profileIndex],
                        is_online: true,
                    };
                }
            })
      setData(profilesData);
      showToast({ message: t("success"), variant: "success" });
    });
  };

  const setOrderStatus = async (status) => {
    const profiles = { ...agencySelectCheckbox };
    await makeRequest({
      route: "agency/profiles/set-booking-status",
      method: "PUT",
      payload: { status, profiles },
    }).then(() => {
      const profilesData = JSON.parse(JSON.stringify(data))
            Object.keys(profiles).forEach((key) => {
                const slug = profiles[key]
                const profileIndex  = profilesData.findIndex((profile) => profile.slug === slug)
                if (profileIndex !== -1) {
                    profilesData[profileIndex] = {
                        ...profilesData[profileIndex],
                        tag_booking: status,
                    };
                }
            })
      setData(profilesData);
      showToast({ message: t("success"), variant: "success" });

    });
  };
  const setVchatStatus = async (status) => {
    const profiles = { ...agencySelectCheckbox };
    await makeRequest({
      route: "agency/profiles/set-call-status",
      method: "PUT",
      payload: { status, profiles },
    }).then(() => {
      const profilesData = JSON.parse(JSON.stringify(data))
            Object.keys(profiles).forEach((key) => {
                const slug = profiles[key]
                const profileIndex  = profilesData.findIndex((profile) => profile.slug === slug)
                if (profileIndex !== -1) {
                    profilesData[profileIndex] = {
                        ...profilesData[profileIndex],
                        ready_videochat: status,
                    };
                }
            })
      setData(profilesData);
      showToast({ message: t("success"), variant: "success" });

    });
  };

  const setPreviewStatus = async (status) => {
    const profiles = { ...agencySelectCheckbox };
    await makeRequest({
      route: "agency/profiles/set-preview-status",
      method: "PUT",
      payload: { status, profiles },
    }).then(() => {
      const profilesData = JSON.parse(JSON.stringify(data))
            Object.keys(profiles).forEach((key) => {
                const slug = profiles[key]
                const profileIndex  = profilesData.findIndex((profile) => profile.slug === slug)
                if (profileIndex !== -1) {
                    profilesData[profileIndex] = {
                        ...profilesData[profileIndex],
                        ready_preview: status,
                    };
                }
            })
      setData(profilesData);
      showToast({ message: t("success"), variant: "success" });
    });
  };

  const setStatus = async (status) => {
    const profiles = { ...agencySelectCheckbox };
    await makeRequest({
      route: "agency/profiles/set-status",
      method: "PUT",
      payload: { status, profiles },
    }).then(() => {
      const profilesData = JSON.parse(JSON.stringify(data))
            Object.keys(profiles).forEach((key) => {
                const slug = profiles[key]
                const profileIndex  = profilesData.findIndex((profile) => profile.slug === slug)
                if (profileIndex !== -1) {
                    profilesData[profileIndex] = {
                        ...profilesData[profileIndex],
                        is_enabled: status,
                    };
                }
            })
      setData(profilesData);
      showToast({ message: t("success"), variant: "success" });
    });
  };

  const setIndiStatus = async (status) => {
    const profiles = { ...agencySelectCheckbox };
    await makeRequest({
      route: "agency/profiles/set-indi-status",
      method: "PUT",
      payload: { status, profiles },
    }).then(() => {
      // костыль
      const profilesData = JSON.parse(JSON.stringify(data))
            Object.keys(profiles).forEach((key) => {
                const slug = profiles[key]
                const profileIndex  = profilesData.findIndex((profile) => profile.slug === slug)
                if (profileIndex !== -1) {
                    profilesData[profileIndex] = {
                        ...profilesData[profileIndex],
                        is_individual: status,
                    };
                }
            })
      setData(profilesData);
      showToast({ message: t("success"), variant: "success" });
    });
  };



    return (
        <>
            <main>
                <section className={`profiles-page__container ${asComponent ? 'pl-0 pr-0' : ''}`}>
                    {!asComponent && (<div className={"profiles-page__header"}>
                            <h1>
                                {t("myadsall")}: {count}
                            </h1>

                            <VerHeader
                                openVer={openVer}
                                openHealth={openHealth}
                                isLoading={isLoadingInfo}
                                setOpenImport={setIsOpenImport}
                                data={dataInfo}
                                setAgencySelectCheckbox={setAgencySelectCheckbox}
                            />
                        </div>)}

                        <div className="profiles-page view">
                            <Button
                                onClick={() => setView("standart")}
                                clazz={"profiles-page view__btn"}
                            >
                                <Icon
                                    size={"l"}
                                    spritePath={"longer-view"}
                                    clazz={view === "standart" ? "" : "_gray"}
                                />
                                <span className={`p2 ${view === "standart" ? "color-main" : ""}`}>
                              {t("standartview")}
                            </span>
                            </Button>
                            <Button
                                onClick={() => setView("small")}
                                clazz={"profiles-page view__btn"}
                            >
                                <Icon
                                    size={"l"}
                                    spritePath={"small-view"}
                                    clazz={view === "small" ? "" : "_gray"}
                                />
                                <span className={`p2 ${view === "small" ? "color-main" : ""}`}>
                {t("minview")}
              </span>
                            </Button>

                            <div className="search">
                                <label className="search__label header__search--label">
                                    <input
                                        placeholder={t("modelname") + ", " + t("phonenumber")}
                                        value={search}
                                        className={`search__input`}
                                        onChange={(e) => {
                                            setSearch(e.target.value);
                                        }}
                                        type={"search"}
                                    />

                                    {!!search && (
                                        <Button
                                            title={t("reset")}
                                            onClick={() => setSearch("")}
                                            clazz="search__close"
                                        >
                                            <Icon size={"m"} spritePath={"close"}/>
                                        </Button>
                                    )}
                                    <Button square={true} size={"xs"} clazz="search__icon">
                                        <Icon size={"m"} spritePath={"search"}/>
                                    </Button>
                                </label>
                            </div>

                            {isAgency && !asComponent ?  (
                                <AgencyProfileActions
                                    resetFilter={resetFilter}
                                    filterCount={filterCount}
                                    setIsAgencyFilterOpen={setIsAgencyFilterOpen}
                                    setAgencySelectCheckbox={setAgencySelectCheckbox}
                                    allSlugs={allSlugs}
                                    agencySelectCheckbox={agencySelectCheckbox}
                                    setMakeEliteAgency={setMakeEliteAgency}
                                    setOnlineStatus={setOnlineStatus}
                                    setOrderStatus={setOrderStatus}
                                    setVchatStatus={setVchatStatus}
                                    setPreviewStatus={setPreviewStatus}
                                    setStatus={setStatus}
                                    setIndiStatus={setIndiStatus}
                                    disablePromotion={()=>{}}
                                    setCardsPerPage={setCardsPerPage}
                                    cardsPerPage={cardsPerPage}
                                />
                            ) : (<div className="d-flex gap-8">
                                <Button
                                    onClick={() => {
                                        setIsAgencyFilterOpen(true);
                                    }}
                                    size={"s"}
                                    clazz={"header__filter color-main"}
                                >
                                    {t("filter")}
                                    <Icon size={"m"} spritePath={"filter"} />
                                    {!!filterCount && <span className="count">{filterCount}</span>}
                                </Button></div>)}
                        </div>

          <Res
            setAgencySelectCheckbox={setAgencySelectCheckbox}
            agencySelectCheckbox={agencySelectCheckbox}
            isHealthBoosted={isHealthBoosted}
            healthy_boost={healthy_boost}
            setCurrentType={setCurrentType}
            setCurrentSlug={setCurrentSlug}
            setCurrentCallback={setCurrentCallback}
            dataInfo={dataInfo}
            isLoading={isLoading}
            options={data}
            view={view}
            variant={variant}
            verRef={verRef}
            healthRef={healthRef}
            search={search}
            filteredOptions={data}
            setData={setData}
            setCount={setCount}
            pagesList={pagesList}
            setPagesList={setPagesList}
            page={page}
            setPage={setPage}
            handlePage={handlePage}
          />

          {!asComponent && !!count && !isAgency && (
            <VerificationAndHealthCheck
              isHealthBoosted={isHealthBoosted}
              setCurrentType={setCurrentType}
              verRef={verRef}
              healthRef={healthRef}
              data={dataInfo}
              setDataInfo={setDataInfo}
              profiles={data}
            />
          )}
        {!asComponent  && (
          <div
            className={` gap-16 justify-sb ${
              windowWidth < 998 ? "d-grid grid-tp-col-2" : "d-flex fd-row"
            }`}
          >
            <div className="verads control p-24 justify-sb fd-row align-center">
              <span className={`color-green ${fzForStatistic}`}>
                {t("verified")}
              </span>
                            <span className={`${fzForStatistic} color-main`}>
                {verify_count}
              </span>
                        </div>
                        <div className="verads control p-24 justify-sb fd-row align-center">
              <span className={`color-main ${fzForStatistic}`}>
                {t("moderated24hour")}
              </span>
                            <span className={`${fzForStatistic} color-main`}>
                {verify_wait_count || 0}
              </span>
                        </div>
                        <div className="verads control p-24 justify-sb fd-row align-center">
              <span className={`color-red-700 ${fzForStatistic}`}>
                {t("declinedbymoderator")}
              </span>
                            <span className={`${fzForStatistic} color-main`}>
                {verify_decline_count || 0}
              </span>
                        </div>
                        <div className="verads control p-24 justify-sb fd-row align-center">
              <span className={`color-green ${fzForStatistic}`}>
                {t("healthy")}
              </span>
                            <span className={`color-main`}>{healthy_count || 0}</span>
                        </div>
                    </div>)}
                </section>
            </main>

            {!asComponent && (<RequestVerPopup slug={currentSlug}/>)}

            {!asComponent && (<FastVerification
                setIsHealthBoosted={setIsHealthBoosted}
                type={currentType}
                slug={currentSlug}
                callback={currentCallback}
            />)}

            {!asComponent && isOpenImport && (
                <ImportAdPopup open={isOpenImport} setOpen={setIsOpenImport}/>
            )}

            {isAgencyFilterOpen && (
                <AgencyFilter
                    genderChecked={genderChecked}
                    setGenderChecked={setGenderChecked}
                    activeToggle={activeToggle}
                    setActiveToggle={setActiveToggle}
                    extraChecked={extraChecked}
                    setExtraChecked={setExtraChecked}
                    open={isAgencyFilterOpen}
                    setOpen={setIsAgencyFilterOpen}
                    resetFilter={resetFilter}
                    fetchData={setFilters}
                />
            )}

            {!asComponent && makeEliteAgency && (
                <MakeEliteAdOnePopup
                    open={makeEliteAgency}
                    setOpen={setMakeEliteAgency}
                    profiles={data.filter((profile) =>
                        agencySelectCheckbox.includes(profile.slug)
                    )}
                />
            )}

            {!asComponent && (<AddOrImportProfile setOpen={setIsOpenImport}/>)}
        </>
    );
};

export default ProfilesPage;
