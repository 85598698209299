import img1 from "@/assets/img/pick-profile/img1.webp";
import img2 from "@/assets/img/pick-profile/img2.webp";
import img3 from "@/assets/img/pick-profile/img3.webp";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon } from "../ui";

import "./eliteStatusCards.scss";

const EliteStatusCards = () => {

  const { t } = useTranslation("translation");

  return (
    <div className={"profile-cards p3"}>
      <div className={"profile-card"}>
        <div className="profile-card__img">
          <img src={img1} alt="" />
        </div>

        <p>{t("i-eliteframed")}.</p>

        <div className={"fake-checkbox"}>
          <Icon square spritePath={"check"} size={"m"} />
          <p>{t("i-morecustomers")}.</p>
        </div>
      </div>

      <div className={"profile-card"}>
        <div className="profile-card__img">
          <img src={img2} alt="" />
        </div>

        <p>{t("i-signstatus")}.</p>

        <div className={"fake-checkbox"}>
          <Icon square spritePath={"check"} size={"m"} />
          <p>{t("i-wealthy")}.</p>
        </div>
      </div>

      <div className={"profile-card"}>
        <div className="profile-card__img">
          <img src={img3} alt="" />
        </div>
        <p>
          {t("i-onlyelite")}{" "}
          <Link to={`/elitelady`}>https://lady4love.com/elitelady</Link>,{" "}
          {t("i-vipclients")}.
        </p>
        <div className={"fake-checkbox"}>
          <Icon square spritePath={"check"} size={"m"} />
          <p>{t("i-customersmonth")}.</p>
        </div>
      </div>
    </div>
  );
};

export default EliteStatusCards;
