import { useDispatch } from "react-redux";
import React from "react";
import {setGalleryStatus} from "@/stores/slices/popupSlice";
import useLadyService from "@/services/LadyService";


const PromoCard = ({promo, setGalleryMedia}) => {

    const dispatch = useDispatch();
    const {setMediaSize} = useLadyService();

    const {
        name, 
        banner,
        preview
    } = promo

    const galleryMedia = {
      photo: banner.length > 0 ? banner : (preview.length > 0 ? preview : []) 
    }


    const openPromo = ( ) => {
      setGalleryMedia(galleryMedia)
      dispatch(setGalleryStatus(true))
    }

    const View = ( ) => {
        return(
          <div title={name} className={`d-block party-card__img promo`} onClick={openPromo}>
            {<img src={setMediaSize(preview[0], 'xs')} alt={name} />}
          </div>
        )
    }
    
    
  return (
    <div
      className={"party-card"}
    >
      {View(promo)}
    </div>)
}


export default PromoCard