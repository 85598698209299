import React from 'react';
import {ErrorHandler} from "../ui";
import {makeRequest} from "@/services/makeRequest";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // makeRequest({route: 'error', method: 'POST', payload: {uri: window.location.href, error: error?.message ?? "no_message", errorInfo}})
    }

    render() {
        if (this.state.hasError) {
            return (<ErrorHandler/>);
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
