import {
    setForgotPasswordPhoneInputsStatus,
    setNewPassStatus,
} from "@/stores/slices/popupSlice";
import {number, object} from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useEffect, useState} from "react";
import {setNewToken} from "@/stores/slices/userSlice";
import {makeRequest} from "@/services/makeRequest";
import "@/components/popups/restorePassword/restorePhoneInputsPopup.scss";
import {Link} from "react-router-dom";
import showToast from "@/components/toast/Toast";
import {CodeInput} from "@/components/ui/codeInput/CodeInput";
import CountdownTimer from "./CountdownTimer";
import useLadyService from "../../services/LadyService";

const ConfirmPhone = ({
                          telephone,
                          isAuth = false,
                          setCode,
                          error,
                          setFormCodeError,
                          setCodeStatus,
                          codeStatus,
                          setShowCodeInput,
                          time = 60,
                          setTime = false,
                          setCanSubmit = false
                      }) => {
    const {t, dispatch} = useLadyService();
    const [codeError, setCodeError] = useState("");
    const formSchema = object().shape({
        phone: number()
            .typeError(t("phonenumerrequired"))
            .required(t("phonenumerrequired")),
    });

    const {
        reset,
    } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(formSchema),
    });

    const resendCode = async () => {
        const routeByType = isAuth ? "send-code" : "password/reset";
        try {
            const route = `auth/${routeByType}`;
            const method = "POST";
            const payload = {
                ...(!isAuth && {identity: parseInt(telephone.replace(/\s/g, ""))}),
                ...(isAuth && {phone: parseInt(telephone.replace(/\s/g, ""))}),

            };

            const {result, message} = await makeRequest({route, method, payload});

            if (!result) {
                if (message === "sms_send_failed") {
                    showToast({
                        message: t("phoneconfirmincorrect"),
                        variant: "error",
                    });
                }
            } else {
                setCanSend(false);
                setTime(60)

                showToast({
                    message: t("coderesended"),
                    variant: "success",
                });
            }
        } catch (error) {

            showToast({
                message: t("oops"),
                variant: "error",
            });
        }
    };
    const fetchResetData = async () => {
        try {
            const route = `auth/password/verify`;
            const method = "POST";
            const payload = {
                identity: parseInt(telephone.replace(/\s/g, "")),
                code: otp.join(""),
            };

            const data = await makeRequest({route, method, payload});

            if (data?.result) {
                dispatch(setNewToken(data.token));
                dispatch(setForgotPasswordPhoneInputsStatus(false));
                dispatch(setNewPassStatus(true));
                reset();
            } else {
                setCodeError(t("codeisincorrect"));
            }
        } catch (error) {
            console.log("error", error);

            if (error.errors) {
                setCodeError(t("codeisincorrect"));
            }
        }
    };
    const fetchConfirmData = async () => {
        try {
            const route = `auth/check-code`;
            const method = "POST";
            const payload = {
                phone: parseInt(telephone.replace(/\s/g, "")),
                code: otp.join(""),
            };

            const {result} = await makeRequest({route, method, payload});

            if (result) {
                setCanSubmit(true)
                setCodeStatus("success");
                setShowCodeInput(false);
            } else {
                setCodeError(t("codeisincorrect"));
                setCodeStatus("error");
            }
        } catch (error) {
            console.log("error", error);

            if (error.errors) {
                setCodeError(t("codeisincorrect"));
            }
        }
    };
    const onSubmit = () => {

        !isAuth && fetchResetData();
        isAuth && fetchConfirmData();
    };
    const [otp, setOtp] = useState([""]);
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
        setCode(otp.join(""));

        if (!!codeStatus) {
            setCodeStatus(false);
        }

        if (isComplete) {
            onSubmit();
        }
    }, [isComplete, codeStatus, onSubmit, otp, setCode, setCodeStatus]);


    const [canSend, setCanSend] = useState(false);

    return (
        <div className="confirm-phone__root">
            {isAuth && <span className="text-center color-main">{t("smscode")}</span>}
            <CodeInput

                codeError={codeError}
                setCodeError={setCodeError}
                setValue={setOtp}
                setIsComplete={setIsComplete}
                setFormCodeError={setFormCodeError}
            />
            {codeError && (
                <span className={"popup-form__error text-center mb-8"}>
          {codeError}
        </span>
            )}
            {error && (
                <span className={"popup-form__error text-center mb-8"}>
          {t("required")}
        </span>
            )}
            {/* {codeStatus === "error" && (
        <span className={"popup-form__error text-center mb-8"}>
          {t("codeisincorrect")}
        </span>
      )} */}
            {!canSend && <CountdownTimer time={time} setTime={setTime} currentTime={60} setEndTime={setCanSend}/>}

            <Link
                to={"/"}
                onClick={(e) => {
                    e.preventDefault();
                    resendCode();
                }}
                className={`forgot-phone-inputs__link ${!canSend ? "disabled" : ""}`}
            >
                {t("resendcode")}
            </Link>
        </div>
    );
};
export default ConfirmPhone;
