import {
    Button,
    Icon,
    Accordion,
    InputRangeFilter,
    CheckboxFilter,
    InputInLabel,
    SelectCountry,
} from "@/components/ui";
import {
    getUserCurrentCity,
    getLanguagesWords,
    getNationalistWords,
    getServicesWords,
    getCountriesData,
    setCurrentCity,
} from "@/stores/slices/userSlice";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {hide, showFilter} from "@/stores/slices/showFilterSlice";
import {
    filterPlace,
    resetSelected,
    resetState,
    resetRange,
    rangeState,
    setSearchFilter,
    resetAll,
    resetStateAcc,
    resetToggleAcc,
    setAddressValue,
    toggleSelected,
    setRadiusCoords,
    resetRadiusValue,
    resetAddressValue,
    defaultRangeState,
    filterAddress,
    getFilterEditId,
    setFilterEditId,
    fetchRanges,
    resetAccordionByStatus,
    setUpdatedFIlterItem,
} from "@/stores/slices/filterSlice";
import axios from "axios";
import {
    getBreastSizeCount,
    getBreastTypeCount,
    getEthnosCount,
    getEyeColorCount,
    getEyeTypeCount,
    getGenderCount,
    getHairColorCount,
    getHairIntimCount,
    getHairSizeCount,
    getLanguageCount,
    getLastVisitCount,
    getLipsCount,
    getNationCount,
    getPiercingCount,
    getPlaceCount,
    getRadiusCount,
    getServicesCount,
    getServicesForCount,
    getSexCount,
    getSmoKeCount,
    getStatusCount,
    getTariffsCount,
    getTattooCount,
    resetAllCounters,
    resetRadiusCount,
    resetStateCount,
    setRadiusCoordsCount,
    setResultString,
    getTagCount,
    getPenisSizeCount,
    setModelsOnMapCount,
} from "@/stores/slices/counterFilterSlice";
import {
    cleanSorted,
    getNotDisabledCheckbox,
    setSearchValue,
    setSortedModelsByFilter,
    rangeChangeForModel,
    getAllSorted,
    setSorted,
    setPageDefault,
} from "@/stores/slices/modelsState";
import {plural} from "@/helper/plural";
import {
    getFilterPopupStatus,
    setFilterMiniPopupStatus,
    setFilterPopupStatus,
} from "@/stores/slices/filterPopupSlice";
import {clearTitle, getTitle} from "@/stores/slices/titleSlice";
import baseUrl from "@/services/apiConfig";
import useLadyService from "@/services/LadyService";
import GeoInput from "../ui/input/GeoInput";
import {makeRequest} from "@/services/makeRequest";
import showToast from "../toast/Toast";
import {Popup} from "../popups";
import {setProfileFilterCount} from "@/stores/slices/mapModelSlice";
import FilterData from "./FilterData";

import "./filter.scss";
import {allFilter} from "@/stores/slices/filterSlice";

const Filter = () => {
    const {dispatch, navigate, lang, userCity, t, country, token, city} =
        useLadyService();
    const currentCity = useSelector(getUserCurrentCity);

    const filterValue = useSelector(showFilter);
    const servicesCategory = useSelector(getServicesWords);
    const categorySex = servicesCategory.sex;
    const categoryAdditional = servicesCategory.additional;

    const categoryBdsm = servicesCategory.bdsm;
    const categoryExtreme = servicesCategory.extreme;
    const categoryMassage = servicesCategory.massage;
    const categoryStriptease = servicesCategory.striptease;
    const address = useSelector(filterAddress);

    useEffect(() => {
        document.addEventListener("keyup", (event) => {
            if (event.isComposing || event.key === "Escape") {
                dispatch(hide());
                if (isFilterEdit) {
                    dispatch(setFilterEditId(false));
                }
            }
        });
    });

    const isFilterEdit = useSelector(getFilterEditId);
    const EditFilterWrapper = ({children}) => {
        if (isFilterEdit) {
            return <div className="d-flex gap-16 w-100">{children}</div>;
        } else {
            return children;
        }
    };
    const [editFilterName, setEditFilterName] = useState(
        isFilterEdit ? isFilterEdit.title : ""
    );

    const [editFilterError, setEditFilterError] = useState(false);
    const [filterdSting, setFiltredString] = useState("123");
    const sortByStatus = useSelector(getAllSorted);
    const filterState = useSelector(allFilter);

    function queryStringToObject(queryString) {
        const params = queryString.split("&");
        const result = {
            originalStr: queryString,
        };

        params.forEach((param) => {
            const [key, value] = param.split("=");
            result[key] = value;
        });
        if (sortByStatus.length) {
            return {...result, status: sortByStatus.join(",")};
        } else {
            return result;
        }
    }

    const handleEditFilter = async (id) => {
        const resultStr = filterdSting
            ? isFilterEdit.originalStr + "&" + filterdSting
            : isFilterEdit.originalStr;

        try {
            const route = `user/filter/${id}`;
            const method = "POST";
            const payload = {
                title: editFilterName,
                filter: queryStringToObject(resultStr),
                filterState: {...filterState, city},
            };

            const result = await makeRequest({route, method, payload});
            if (result) {
                dispatch(
                    setUpdatedFIlterItem({
                        title: editFilterName,
                        filter: queryStringToObject(resultStr),
                        id: id,
                    })
                );
                showToast({
                    message: t("success"),
                    variant: "success",
                });
            }
        } catch (error) {
            console.log(error);

            showToast({
                message: t("oops"),
                variant: "error",
            });
        }
    };
    const [searchCount, setSearchCount] = useState("");

    const popupRef = useRef(null);
    const childrenRef = useRef(null);

    const handleClickOutside = (event) => {
        if (
            popupRef.current &&
            popupRef.current.contains(event.target) &&
            childrenRef.current &&
            !childrenRef.current.contains(event.target)
        ) {
            if (isFilterEdit) {
                dispatch(setFilterEditId(false));
                resetFilter();
            }
            dispatch(hide());
            event.stopPropagation();
        }
    };

    const defaultRangeValue = useSelector(defaultRangeState);
    const [isActive, setIsActive] = useState(false);

    const {languages, nationalities} = useSelector(getNotDisabledCheckbox);
    const translateLanguages = useSelector(getLanguagesWords);
    const translateNationalist = useSelector(getNationalistWords);

    const nationData =
        translateNationalist?.length > 0 &&
        nationalities?.length > 0 &&
        translateNationalist.filter((item2) =>
            nationalities.some((item1) => item1 === item2.code)
        );
    const langData =
        translateLanguages?.length > 0 &&
        languages?.length > 0 &&
        translateLanguages.filter((item2) =>
            languages.some((item1) => item1 === item2.short_name)
        );

    const handleLocationClick = async () => {
        setIsActive(true);
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(async function (position) {
                const {latitude, longitude} = position.coords;
                try {
                    const response = await axios.get(
                        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1&accept-language=${lang}`
                    );

                    const {city, road, house_number} = response.data.address;

                    let uncorrectName = false;

                    country.map((item) => {
                        if (
                            item.title?.toLowerCase() !== city?.toLowerCase() && !uncorrectName
                        ) {
                            uncorrectName = true;
                            dispatch(
                                setCurrentCity(country.filter((item) => item.id === 1)[0])
                            );
                            return null;
                        }
                    });
                    dispatch(
                        setAddressValue(
                            `${road} ${house_number ? ", " + house_number : ""}`
                        )
                    );
                    dispatch(
                        toggleSelected({
                            value: `${house_number ? ", " + house_number : ""}`,
                            name: "radius",
                        })
                    );
                    dispatch(setRadiusCoords({latitude, longitude}));
                    dispatch(setRadiusCoordsCount({latitude, longitude}));
                } catch (error) {
                    console.error("Error fetching address:", error);
                }
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const filterPlaceSlice = useSelector(filterPlace);
    const isStatusFullPopup = useSelector(getFilterPopupStatus);

    function concatArray(arr) {
        if (arr.length === 0) {
            return "";
        }
        return arr.map((item) => item + "=1").join("&");
    }

    function concatenateArrayWithTitle(arr, title) {
        const concatenatedString = title + "=" + arr.join(",");
        return [concatenatedString];
    }

    function formatRange(obj, title) {
        const {min, max} = obj;
        return `${title}=${min},${max}`;
    }

    const placeCount = useSelector(getPlaceCount);
    const languageCount = useSelector(getLanguageCount);
    const genderCount = useSelector(getGenderCount);
    const sexCount = useSelector(getSexCount);
    const nationCount = useSelector(getNationCount);
    const lastVisitCount = useSelector(getLastVisitCount);
    const minTarifCount = useSelector(getTariffsCount);
    const servicesForCount = useSelector(getServicesForCount);
    const breastSizeCount = useSelector(getBreastSizeCount);
    const breastTypeCount = useSelector(getBreastTypeCount);
    const ethnosCount = useSelector(getEthnosCount);
    const hairSizeCount = useSelector(getHairSizeCount);
    const hairColorCount = useSelector(getHairColorCount);
    const hairIntimCount = useSelector(getHairIntimCount);
    const eyesColorCount = useSelector(getEyeColorCount);
    const eyesTypeCount = useSelector(getEyeTypeCount);
    const lipsCount = useSelector(getLipsCount);
    const tattooCount = useSelector(getTattooCount);
    const piercingCount = useSelector(getPiercingCount);
    const smokeCount = useSelector(getSmoKeCount);
    const statusCount = useSelector(getStatusCount);
    const rangeValue = useSelector(rangeState);
    const servicesValue = useSelector(getServicesCount);
    const radiusState = useSelector(getRadiusCount);
    const tagState = useSelector(getTagCount);
    const penisSize = useSelector(getPenisSizeCount);

    const countModelsOnMapRef = useRef(0);
    useEffect(() => {
        if (filterValue) {
            let result = [];
            let resultForModels = [];

            if (
                (rangeValue.age.min !== 0 &&
                    rangeValue.age.min > defaultRangeValue.age.min) ||
                (rangeValue.age.max !== 0 &&
                    rangeValue.age.max < defaultRangeValue.age.max)
            ) {
                result.push(formatRange(rangeValue.age, "age"));
                resultForModels.push(formatRange(rangeValue.age, "age"));
            }
            if (
                rangeValue.weight.min > defaultRangeValue.weight.min ||
                rangeValue.weight.max < defaultRangeValue.weight.max
            ) {
                result.push(formatRange(rangeValue.weight, "weight"));
                resultForModels.push(formatRange(rangeValue.weight, "weight"));
            }
            if (
                rangeValue.height.min > defaultRangeValue.height.min ||
                rangeValue.height.max < defaultRangeValue.height.max
            ) {
                result.push(formatRange(rangeValue.height, "height"));
                resultForModels.push(formatRange(rangeValue.height, "height"));
            }
            if (
                rangeValue.price.min > defaultRangeValue.price.min ||
                rangeValue.price.max < defaultRangeValue.price.max
            ) {
                result.push(formatRange(rangeValue.price, "price"));
                resultForModels.push(formatRange(rangeValue.price, "price"));
            }
            if (!!statusCount.length) {
                result.push(concatArray(statusCount));
            }
            if (!!penisSize.length) {
                result.push(concatenateArrayWithTitle(penisSize, "penis"));
                resultForModels.push(concatenateArrayWithTitle(penisSize, "penis"));
            }
            if (!!breastTypeCount.length) {
                result.push(concatArray(breastTypeCount));
                resultForModels.push(concatArray(breastTypeCount));
            }
            if (!!eyesTypeCount.length) {
                result.push(concatArray(eyesTypeCount));
                resultForModels.push(concatArray(eyesTypeCount));
            }
            if (!!radiusState.value.length) {
                result.push(
                    `radius=${radiusState.latitude},${
                        radiusState.longitude
                    },${radiusState.value.join(",")}`
                );
                resultForModels.push([
                    `radius=${radiusState.latitude},${
                        radiusState.longitude
                    },${radiusState.value.join(",")}`,
                ]);
            }
            if (placeCount.filter((item) => item !== "incall").length > 0) {
                result.push(
                    concatenateArrayWithTitle(
                        placeCount.filter((item) => item !== "incall"),
                        "place"
                    )
                );
                resultForModels.push(concatenateArrayWithTitle(placeCount, "place"));
            }
            if (servicesValue.length > 0) {
                result.push(concatenateArrayWithTitle(servicesValue, "services"));
                resultForModels.push(
                    concatenateArrayWithTitle(servicesValue, "services")
                );
            }
            if (smokeCount.length > 0) {
                result.push(concatenateArrayWithTitle(smokeCount, "smoking"));
                resultForModels.push(concatenateArrayWithTitle(smokeCount, "smoking"));
            }
            if (piercingCount.length > 0) {
                result.push(concatenateArrayWithTitle(piercingCount, "piercing"));
                resultForModels.push(
                    concatenateArrayWithTitle(piercingCount, "piercing")
                );
            }
            if (tattooCount.length > 0) {
                result.push(concatenateArrayWithTitle(tattooCount, "tattoo"));
                resultForModels.push(concatenateArrayWithTitle(tattooCount, "tattoo"));
            }
            if (lipsCount.length > 0) {
                result.push(concatenateArrayWithTitle(lipsCount, "lips"));
                resultForModels.push(concatenateArrayWithTitle(lipsCount, "lips"));
            }
            if (eyesColorCount.length > 0) {
                result.push(concatenateArrayWithTitle(eyesColorCount, "eyes"));
                resultForModels.push(concatenateArrayWithTitle(eyesColorCount, "eyes"));
            }
            if (hairIntimCount.length > 0) {
                result.push(concatenateArrayWithTitle(hairIntimCount, "intim_hs"));
                resultForModels.push(
                    concatenateArrayWithTitle(hairIntimCount, "intim_hs")
                );
            }
            if (hairSizeCount.length > 0) {
                result.push(concatenateArrayWithTitle(hairSizeCount, "hair_len"));
                resultForModels.push(
                    concatenateArrayWithTitle(hairSizeCount, "hair_len")
                );
            }
            if (hairColorCount.length > 0) {
                result.push(concatenateArrayWithTitle(hairColorCount, "hair"));
                resultForModels.push(concatenateArrayWithTitle(hairColorCount, "hair"));
            }
            if (breastSizeCount.length > 0) {
                result.push(concatenateArrayWithTitle(breastSizeCount, "breast"));
                resultForModels.push(
                    concatenateArrayWithTitle(breastSizeCount, "breast")
                );
            }
            if (ethnosCount.length > 0) {
                result.push(concatenateArrayWithTitle(ethnosCount, "ethnos"));
                resultForModels.push(concatenateArrayWithTitle(ethnosCount, "ethnos"));
            }
            if (genderCount.length > 0) {
                result.push(concatenateArrayWithTitle(genderCount, "gender"));
            }
            if (sexCount.length > 0) {
                result.push(concatenateArrayWithTitle(sexCount, "orient"));
                resultForModels.push(concatenateArrayWithTitle(sexCount, "orient"));
            }
            if (nationCount.length > 0) {
                result.push(concatenateArrayWithTitle(nationCount, "nationality"));
                resultForModels.push(
                    concatenateArrayWithTitle(nationCount, "nationality")
                );
            }
            if (lastVisitCount.length > 0) {
                setLastvisitSelect(true);
                result.push(concatenateArrayWithTitle(lastVisitCount, "last_visit"));
                resultForModels.push(
                    concatenateArrayWithTitle(lastVisitCount, "last_visit")
                );
            }
            if (minTarifCount.length > 0) {
                result.push(concatenateArrayWithTitle(minTarifCount, "min_time"));
                resultForModels.push(
                    concatenateArrayWithTitle(minTarifCount, "min_time")
                );
            }
            if (servicesForCount.length > 0) {
                result.push(concatenateArrayWithTitle(servicesForCount, "for"));
                resultForModels.push(
                    concatenateArrayWithTitle(servicesForCount, "for")
                );
            }
            if (languageCount.length > 0) {
                result.push(concatenateArrayWithTitle(languageCount, "languages"));
                resultForModels.push(
                    concatenateArrayWithTitle(languageCount, "languages")
                );
            }
            setFiltredString(resultForModels.join("&"));
            if (!isFilterEdit) {
                const resultUrl = `${baseUrl}profiles/count?lang=${lang}&region=${currentCity.slug}`;
                const params = result.length ? `&${result.join("&")}` : "";
                axios
                    .request({
                        headers: {
                            Authorization: token ? `Bearer ${token}` : "",
                        },
                        method: "GET",
                        url: resultUrl + params,
                    })
                    .then((response) => {
                        setSearchCount(response.data.count);
                        countModelsOnMapRef.current = response.data.count_on_map;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    }, [
        penisSize,
        tagState,
        userCity,
        radiusState.value,
        servicesValue,
        rangeValue,
        filterValue,
        statusCount,
        smokeCount,
        piercingCount,
        tattooCount,
        lipsCount,
        eyesTypeCount,
        eyesColorCount,
        hairIntimCount,
        hairColorCount,
        ethnosCount,
        hairSizeCount,
        breastTypeCount,
        breastSizeCount,
        servicesForCount,
        placeCount,
        languageCount,
        genderCount,
        sexCount,
        nationCount,
        lastVisitCount,
        minTarifCount,
    ]);

    const sortedValue = useSelector(getAllSorted);

    const [placeTitleSelect, setPlaceTitleSelect] = useState(false);
    const [lastvisitSelect, setLastvisitSelect] = useState(false);

    useEffect(() => {
        const boolean =
            sortedValue.includes("incall") || filterPlaceSlice.length > 0;
        setPlaceTitleSelect(boolean);
    }, [filterPlaceSlice, statusCount]);

    const countriesData = useSelector(getCountriesData);
    
    const [currentActive, setCurrentActive] = useState("");
    const [filterOptionsString, setFilterOptionsString] = useState(filterdSting);

    useEffect(() => {
        if (filterOptionsString !== filterdSting) {
            setFilterOptionsString(filterdSting);
        }
    }, [filterdSting]);

    const urlParams = useSelector(getTitle)
        .map((word) => {
            switch (word) {
                case "videochat":
                    return "vchat";
                case "erocontent":
                    return "ero";
                case "onlineorder":
                    return "booking";
                case "verification":
                    return "verified";
                case "elite":
                    return "elitelady";
                case "trans":
                    return "trance";
                case "couples":
                    return "couple";
                default:
                    return word;
            }
        })
        .join("-");

    const handleClose = () => {
        resetFilter();
        dispatch(hide());
    }
    
    const handleApplyFilter = async ( ) => {
        await dispatch(setSortedModelsByFilter(filterdSting));
        await dispatch(setSearchFilter(true));
        await dispatch(setProfileFilterCount(countModelsOnMapRef.current));
        if (!isStatusFullPopup) {
            await dispatch(setFilterMiniPopupStatus(true));
            await dispatch(setFilterPopupStatus(true));
        }
        if (filterdSting) {
            await dispatch(setFilterMiniPopupStatus(true));
        }
        await dispatch(setResultString(filterdSting));
        await dispatch(setSearchValue(""));
        navigate(
            `/${lang}/${userCity.slug}${urlParams ? "/" + urlParams : ""}`
        );
        dispatch(hide());
    }

    const resetFilter = () => {
        dispatch(resetAll());
        dispatch(setSearchFilter(false));
        dispatch(cleanSorted());
        dispatch(setSortedModelsByFilter(""));
        dispatch(clearTitle());
        dispatch(resetAllCounters());
        dispatch(setPageDefault());
        dispatch(setSearchValue(""));
        dispatch(setProfileFilterCount(null));

        // dispatch(fetchRanges(currentCity.id, lang));
        // dispatch(
        //     rangeChangeForModel({
        //         name: "price",
        //         min: defaultRangeValue.price.min,
        //         max: defaultRangeValue.price.max,
        //     })
        // );
        // dispatch(
        //     rangeChangeForModel({
        //         name: "age",
        //         min: defaultRangeValue.age.min,
        //         max: defaultRangeValue.age.max,
        //     })
        // );
        // dispatch(setFilterMiniPopupStatus(false));
    };


    useEffect(() => {
        setModelsOnMapCount(countModelsOnMapRef);
    }, [countModelsOnMapRef]);
    
    return (
        <Popup
            ref={popupRef}
            open={filterValue}
            setOpen={(e)=>{handleClose()}}
            id={"filter"}
            wrapperClazz={"filter"}
            clazz={"filter__body"}
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            {!!countriesData?.length && (
                <div className="filter__top">
                    <SelectCountry
                        data={countriesData}
                        name={"city-create-filter"}
                        clazz={`button button-xs select-city`}
                        arrowSize={"m"}
                        type={"flag"}
                        defaultTitle={currentCity.title}
                        currentActiveIndex={currentActive}
                        isMain={true}
                        activeCountry={currentCity.icon}
                        setActive={setCurrentActive}
                    />
                </div>
            )}

            <div className="filter__box filter--place">
        <span className={"filter__title"}>
          {t("place")}
            {placeTitleSelect && <span className={"filter__selected"}>!</span>}
            <button
                type="button"
                className={"range__default"}
                onClick={() => {
                    dispatch(resetState("place"));
                    dispatch(resetStateCount("place"));
                    if (sortedValue.includes("incall")) {
                        dispatch(
                            setSorted({
                                stateName: "sorted",
                                payload: "incall",
                            })
                        );
                    }
                }}
            >
            {placeTitleSelect && t("reset")}
          </button>
        </span>
                <div className="filter__checkbox-buttons">
                    {FilterData().place.map((i) => {
                        return (
                            <CheckboxFilter
                                key={i.id}
                                name={i.name}
                                id={i.id}
                                value={i.value}
                                title={i.title}
                                checked={i.checked}
                                buttonCheckbox={true}
                                count={i.count}
                            />
                        );
                    })}
                </div>
            </div>

            <div className="filter__box filter--price">
                <InputRangeFilter
                    min={defaultRangeValue.price.min}
                    max={defaultRangeValue.price.max}
                    step={10}
                    clazz={"range--price"}
                    inputIdLeft={"minPriceFilter"}
                    inputIdRight={"maxPriceFilter"}
                    title={`${t("price")},`}
                    name={"price"}
                    subtitle={`${lang === "cz" ? " Kč" : " €"}/${t("hour")}`}
                    price
                    warning={true}
                />

                <InputRangeFilter
                    min={defaultRangeValue.age.min}
                    max={defaultRangeValue.age.max}
                    step={1}
                    clazz={"range--age"}
                    inputIdLeft={"minAgeFilter"}
                    inputIdRight={"maxAgeFilter"}
                    title={`${t("age")},`}
                    name={"age"}
                    subtitle={` ${t("years")}`}
                    warning={true}
                />
            </div>

            <Accordion
                name={"gender"}
                title={t("gender")}
                onClick={() => {
                    dispatch(resetState("gender"));
                    dispatch(resetState("sex"));
                    dispatch(resetSelected("gender"));
                    dispatch(resetSelected("sex"));
                    dispatch(
                        resetAccordionByStatus({
                            accordionName: "status",
                            arr: ["couple", "male", "female", "trans", "guys"],
                        })
                    );
                    dispatch(resetStateCount("gender"));
                    dispatch(resetStateCount("sex"));
                    dispatch(resetStateAcc("status"));
                    dispatch(resetToggleAcc("status"));
                    dispatch(cleanSorted());
                }}
            >
                <div className="filter__box filter--gender">
                    <div className="filter__checkboxes-grid">
                        {FilterData().gender.map((i) => {
                            return (
                                <CheckboxFilter
                                    key={i.id}
                                    name={i.name}
                                    id={i.id}
                                    value={i.value}
                                    title={i.title}
                                    border={false}
                                    count={i.count}
                                />
                            );
                        })}
                    </div>

                    <div className="filter__checkbox-buttons">
                        {FilterData().sex.map((i) => {
                            return (
                                <CheckboxFilter
                                    key={i.id}
                                    name={i.name}
                                    id={i.id}
                                    value={i.value}
                                    title={i.title}
                                    buttonCheckbox={true}
                                    count={i.count}
                                />
                            );
                        })}
                    </div>
                </div>
            </Accordion>

            <Accordion
                name={"breast"}
                title={t("appearance")}
                onClick={() => {
                    dispatch(rangeChangeForModel({name: "weight", min: 0, max: 0}));
                    dispatch(rangeChangeForModel({name: "height", min: 0, max: 0}));
                    dispatch(resetState("breast"));
                    dispatch(resetState("breast_size"));
                    dispatch(resetState("penis_size"));
                    dispatch(resetState("breast_type"));
                    dispatch(resetSelected("breast"));
                    dispatch(resetStateCount("breast_size"));
                    dispatch(resetStateCount("breast_type"));
                    dispatch(resetStateCount("penis_size"));
                    dispatch(resetStateCount("hair_color"));
                    dispatch(resetStateCount("hair_size"));
                    dispatch(resetStateCount("hair_intim"));
                    dispatch(resetStateCount("eye_color"));
                    dispatch(resetStateCount("eye_type"));
                    dispatch(resetStateCount("special_lips"));
                    dispatch(resetStateCount("special_tattoo"));
                    dispatch(resetStateCount("special_piercing"));
                    dispatch(resetStateCount("special_smoke"));
                    dispatch(resetStateCount("penis_size"));
                    dispatch(
                        resetRange({
                            name: "weight",
                            min: defaultRangeValue.weight.min,
                            max: defaultRangeValue.weight.max,
                        })
                    );
                    dispatch(
                        resetRange({
                            name: "height",
                            min: defaultRangeValue.height.min,
                            max: defaultRangeValue.height.max,
                        })
                    );
                }}
            >
                <div className="filter__box">
                    <InputRangeFilter
                        min={defaultRangeValue.weight.min}
                        max={defaultRangeValue.weight.max}
                        step={1}
                        clazz={"range--weight"}
                        inputIdLeft={"minWeight"}
                        inputIdRight={"maxWeight"}
                        resetButton={true}
                        name={"weight"}
                        title={`${t("weight")},`}
                        subtitle={` ${t("kg")}`}
                    />

                    <InputRangeFilter
                        min={defaultRangeValue.height.min}
                        max={defaultRangeValue.height.max}
                        step={1}
                        inputIdLeft={"minHeight"}
                        inputIdRight={"maxHeight"}
                        resetButton={true}
                        name={"height"}
                        title={`${t("height")},`}
                        subtitle={` ${t("cm")}`}
                    />
                </div>

                <div className="filter__box filter--hair">
                    <span className={"filter__title"}>{t("hair")}</span>

                    <div className="filter__checkboxes-grid">
                        {FilterData().hair.color.map((i) => {
                            return (
                                <CheckboxFilter
                                    border={false}
                                    key={i.id}
                                    name={i.name}
                                    id={i.id}
                                    value={i.value}
                                    title={i.title}
                                    count={i.count}
                                />
                            );
                        })}
                    </div>

                    <div className="filter__checkbox-buttons">
                        {FilterData().hair.size.map((i) => {
                            return (
                                <CheckboxFilter
                                    border={false}
                                    key={i.id}
                                    name={i.name}
                                    id={i.id}
                                    value={i.value}
                                    title={i.title}
                                    buttonCheckbox={true}
                                    count={i.count}
                                />
                            );
                        })}
                    </div>

                    <span className={"filter__title"}>{t("pubichair")}</span>
                    <div className="filter__checkbox-buttons">
                        {FilterData().hair.intim.map((i) => {
                            return (
                                <CheckboxFilter
                                    border={false}
                                    key={i.id}
                                    name={i.name}
                                    id={i.id}
                                    value={i.value}
                                    title={i.title}
                                    buttonCheckbox={true}
                                    count={i.count}
                                />
                            );
                        })}
                    </div>
                </div>

                <div className="filter__box filter--eye">
                    <span className={"filter__title"}>{t("eyes")}</span>

                    <div className="filter__checkboxes-grid">
                        {FilterData().eye.color.map((i) => {
                            return (
                                <CheckboxFilter
                                    border={false}
                                    key={i.id}
                                    name={i.name}
                                    id={i.id}
                                    value={i.value}
                                    title={i.title}
                                    count={i.count}
                                />
                            );
                        })}
                    </div>

                    <div className="filter__checkbox-buttons">
                        {FilterData().eye.type.map((i) => {
                            return (
                                <CheckboxFilter
                                    key={i.id}
                                    name={i.name}
                                    id={i.id}
                                    value={i.value}
                                    title={i.title}
                                    buttonCheckbox={true}
                                    count={i.count}
                                />
                            );
                        })}
                    </div>
                </div>

                <div className="filter__box filter--special">
                    <span className={"filter__title"}>{t("features")}</span>

                    <div className="filter__row">
                        <span className={"filter__title"}>{t("lips")}</span>
                        <div className="filter__checkbox-buttons">
                            {FilterData().special.lips.map((i) => {
                                return (
                                    <CheckboxFilter
                                        border={false}
                                        key={i.id}
                                        name={i.name}
                                        id={i.id}
                                        value={i.value}
                                        title={i.title}
                                        buttonCheckbox={true}
                                        count={i.count}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="filter__row">
                        <span className={"filter__title"}>{t("tattoos")}</span>
                        <div className="filter__checkbox-buttons">
                            {FilterData().special.tattoo.map((i) => {
                                return (
                                    <CheckboxFilter
                                        border={false}
                                        key={i.id}
                                        name={i.name}
                                        id={i.id}
                                        value={i.value}
                                        title={i.title}
                                        buttonCheckbox={true}
                                        count={i.count}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="filter__row">
                        <span className={"filter__title"}>{t("piercing")}</span>
                        <div className="filter__checkbox-buttons">
                            {FilterData().special.piercing.map((i) => {
                                return (
                                    <CheckboxFilter
                                        border={false}
                                        key={i.id}
                                        name={i.name}
                                        id={i.id}
                                        value={i.value}
                                        title={i.title}
                                        buttonCheckbox={true}
                                        count={i.count}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="filter__row">
                        <span className={"filter__title"}>{t("smoking")}</span>
                        <div className="filter__checkbox-buttons">
                            {FilterData().special.smoke.map((i) => {
                                return (
                                    <CheckboxFilter
                                        border={false}
                                        key={i.id}
                                        name={i.name}
                                        id={i.id}
                                        value={i.value}
                                        title={i.title}
                                        buttonCheckbox={true}
                                        count={i.count}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Accordion>

            <Accordion
                name={"status"}
                title={t("Status")}
                onClick={() => {
                    dispatch(resetState("status"));
                    dispatch(resetState("last_visit"));
                    dispatch(resetState("gender"));
                    dispatch(resetSelected("status"));
                    dispatch(resetStateCount("status"));
                    dispatch(resetStateCount("gender"));
                    dispatch(resetStateCount("last_visit"));
                    dispatch(resetStateAcc("gender"));
                    dispatch(resetToggleAcc("gender"));
                    dispatch(
                        resetAccordionByStatus({
                            accordionName: "gender",
                            arr: ["female", "couple", "male", "trans"],
                        })
                    );
                    dispatch(cleanSorted());
                }}
            >
                <div className="filter__box filter--tag">
                    {FilterData().tag.map((i) => {
                        return (
                            <CheckboxFilter
                                key={i.id}
                                name={i.name}
                                id={i.id}
                                value={i.value}
                                title={i.title}
                                buttonCheckbox={true}
                                count={i.count}
                            />
                        );
                    })}
                </div>
                <div className="filter__box filter--status">
                    {FilterData().status.map((i) => {
                        return (
                            <CheckboxFilter
                                border={false}
                                key={i.id}
                                name={i.name}
                                id={i.id}
                                value={i.value}
                                title={i.title}
                                count={i.count}
                                forDisabled={i.forDisabled}
                            />
                        );
                    })}
                </div>
                <div className="filter__box">
                    <span className={"filter__title d-flex justify-sb"}>
                        {t("lastvisit")}
                        <button
                              type="button"
                              className={"range__default"}
                              onClick={()=>{dispatch(resetStateCount("last_visit"))
                                dispatch(resetState("last_visit"));
                              }}
                          >
                          {lastvisitSelect && t("reset")}
                        </button>
                    </span>
                    <div className="filter__checkbox-buttons filter--last-visit">
                        {FilterData().last_visit.map((i) => {
                            return (
                                <CheckboxFilter
                                    key={i.id}
                                    name={i.name}
                                    id={i.id}
                                    value={i.value}
                                    title={i.title}
                                    buttonCheckbox={true}
                                    flag={i.value}
                                    count={i.count}
                                    radio={i.radio}
                                />
                            );
                        })}
                    </div>
                </div>
            </Accordion>

            <Accordion
                name={"services"}
                title={t("services")}
                onClick={() => {
                    dispatch(resetState("services"));
                    dispatch(resetState("for"));
                    dispatch(resetState("min_tariffs"));
                    dispatch(resetSelected("services"));
                    dispatch(resetStateCount("for"));
                    dispatch(resetStateCount("services"));
                    dispatch(resetStateCount("min_tariffs"));
                }}
            >
                <div className="filter__box filter--services">
                    <>
                        <span className={"filter__title"}>{t("servicesfor")}</span>
                        <div className="filter__checkboxes-grid">
                            {FilterData().services.for.map((i) => {
                                return (
                                    <CheckboxFilter
                                        border={false}
                                        key={i.id}
                                        name={i.name}
                                        id={i.id}
                                        value={i.value}
                                        title={t(i.title)}
                                        count={i.count}
                                    />
                                );
                            })}
                        </div>

                        <span className={"filter__title"}>{categorySex?.title}</span>
                        <div className="filter__checkboxes-grid">
                            {FilterData().services.sex.map((i) => {
                                return (
                                    <CheckboxFilter
                                        border={false}
                                        key={i.id}
                                        name={i.name}
                                        id={i.id}
                                        value={i.value}
                                        title={t(i.title)}
                                        count={i.count}
                                    />
                                );
                            })}
                        </div>

                        <span className={"filter__title"}>{categoryStriptease?.title}</span>
                        <div className="filter__checkboxes-grid">
                            {FilterData().services.strip.map((i) => {
                                return (
                                    <CheckboxFilter
                                        border={false}
                                        key={i.id}
                                        name={i.name}
                                        id={i.id}
                                        value={i.value}
                                        title={t(i.title)}
                                        count={i.count}
                                    />
                                );
                            })}
                        </div>

                        <span className={"filter__title"}>{categoryMassage?.title}</span>
                        <div className="filter__checkboxes-grid">
                            {FilterData().services.massage.map((i) => {
                                return (
                                    <CheckboxFilter
                                        border={false}
                                        key={i.id}
                                        name={i.name}
                                        id={i.id}
                                        value={i.value}
                                        title={t(i.title)}
                                        count={i.count}
                                    />
                                );
                            })}
                        </div>

                        <span className={"filter__title"}>{categoryBdsm?.title}</span>
                        <div className="filter__checkboxes-grid">
                            {FilterData().services.bdsm.map((i) => {
                                return (
                                    <CheckboxFilter
                                        border={false}
                                        key={i.id}
                                        name={i.name}
                                        id={i.id}
                                        value={i.value}
                                        title={t(i.title)}
                                        count={i.count}
                                    />
                                );
                            })}
                        </div>
                        <span className={"filter__title"}>{categoryExtreme?.title}</span>
                        <div className="filter__checkboxes-grid">
                            {FilterData().services.extrim.map((i) => {
                                return (
                                    <CheckboxFilter
                                        border={false}
                                        key={i.id}
                                        name={i.name}
                                        id={i.id}
                                        value={i.value}
                                        title={t(i.title)}
                                        count={i.count}
                                    />
                                );
                            })}
                        </div>
                        <span className={"filter__title"}>{categoryAdditional?.title}</span>
                        <div className="filter__checkboxes-grid">
                            {FilterData().services.extra.map((i) => {
                                return (
                                    <CheckboxFilter
                                        border={false}
                                        key={i.id}
                                        name={i.name}
                                        id={i.id}
                                        value={i.value}
                                        title={t(i.title)}
                                        count={i.count}
                                    />
                                );
                            })}
                        </div>
                    </>
                </div>
                <div className="filter__box filter--min-tariffs">
                    <span className={"filter__title"}>{t("mintime")}</span>
                    <div className="filter__checkbox-buttons">
                        {FilterData().min_tariffs.map((i) => {
                            return (
                                <CheckboxFilter
                                    border={false}
                                    key={i.id}
                                    name={i.name}
                                    id={i.id}
                                    value={i.value}
                                    title={i.title}
                                    buttonCheckbox={true}
                                    count={i.count}
                                />
                            );
                        })}
                    </div>
                </div>
            </Accordion>

            <Accordion
                name={"language"}
                title={t("language")}
                onClick={() => {
                    dispatch(resetState("language"));
                    dispatch(resetSelected("language"));
                    dispatch(resetStateCount("language"));
                }}
            >
                <div className="filter__box filter__checkbox-buttons filter--language">
                    {langData.length > 0 &&
                        langData.map((i) => {
                            return (
                                <CheckboxFilter
                                    border={false}
                                    key={`languages-${i.id}`}
                                    name={"language"}
                                    id={`languages-${i.id}`}
                                    value={i.short_name.toUpperCase()}
                                    title={i.name}
                                    flag={i.short_name.toUpperCase()}
                                    count={i.short_name}
                                    type={"flag"}
                                />
                            );
                        })}
                </div>
            </Accordion>

            <Accordion
                name={"nation"}
                title={t("nationality")}
                onClick={() => {
                    dispatch(resetState("nation"));
                    dispatch(resetSelected("nation"));
                    dispatch(resetStateCount("nation"));
                    dispatch(resetStateCount("ethnos"));
                }}
            >
                <div className="filter__box filter--ethnos">
                    <span className={"filter__title"}>{t("ethnicity")}</span>

                    <div className="filter__checkboxes-grid">
                        {FilterData().ethnos.map((i) => {
                            return (
                                <CheckboxFilter
                                    border={false}
                                    key={i.id}
                                    name={i.name}
                                    id={i.id}
                                    value={i.value}
                                    title={i.title}
                                    count={i.count}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="filter__box filter__checkbox-buttons filter--national">
                    {nationData.length > 0 &&
                        nationData.map((i) => {
                            return (
                                <CheckboxFilter
                                    border={false}
                                    key={`nation-${i.id}`}
                                    name={"nation"}
                                    id={`nation-${i.id}`}
                                    value={i.code.toUpperCase()}
                                    title={i.name}
                                    type={"flag"}
                                    flag={i.code.toUpperCase()}
                                    count={i.code}
                                />
                            );
                        })}
                </div>
            </Accordion>

            <Accordion
                name={"search"}
                title={t("search")}
                onClick={() => {
                    dispatch(resetRadiusValue());
                    dispatch(resetRadiusCount());
                    dispatch(resetAddressValue());
                    dispatch(resetSelected("radius"));
                }}
            >
                <div className="filter__box filter--radius">
                    <div className="search filter__map-input">
                        <span className={"filter__map-title"}>{t("youraddress")}</span>
                        <label
                            htmlFor="geosuggest__input"
                            className="search__label header__search"
                        >
                            <GeoInput
                                value={address[0]}
                                name={"location"}
                                id="geosuggest__input-create"
                                clazz={`header__search-input`}
                                placeholder={t("adressplaceholder")}
                                city={currentCity.title}
                            />
                        </label>
                        <Button
                            title={t("chekmyplace")}
                            onClick={() => handleLocationClick()}
                            name={"location"}
                            square={true}
                            buttonType={"submit"}
                            size={"l"}
                            clazz={
                                isActive
                                    ? "search__icon button--tetriary active"
                                    : "search__icon button--tetriary"
                            }
                            type="submit"
                        >
                            <Icon size={"xl"} spritePath={"location"}/>
                        </Button>
                    </div>

                    <div className="filter__checkbox-buttons">
                        {FilterData().radius.map((i) => {
                            return (
                                <CheckboxFilter
                                    key={i.id}
                                    name={i.name}
                                    id={i.id}
                                    value={i.value}
                                    title={i.title}
                                    buttonCheckbox={true}
                                    flag={i.value}
                                    count={i.count}
                                    radio={i.radio}
                                />
                            );
                        })}
                    </div>
                </div>
            </Accordion>

            <div className={`filter__bottom ${isFilterEdit ? "fd-column" : ""}`}>
                {!isFilterEdit && (
                    <>
                        <Button
                            onClick={() => {
                                handleApplyFilter()
                            }}
                            buttonType={"submit"}
                            size={"m"}
                            clazz={"button--green filter__submit"}
                        >
                            {`${t("show")} ${searchCount} ${plural(lang, searchCount)}`}
                        </Button>

                        <Button
                            onClick={resetFilter}
                            size={"s"}
                            clazz={"button--secondary filter__reset"}
                        >
                            {t("reset")}
                        </Button>
                    </>
                )}

                {isFilterEdit && (
                    <InputInLabel
                        clazz={`${editFilterError ? " _error" : "w-100"}`}
                        id={"filter-edit-name"}
                        placeholder={t("")}
                        value={editFilterName}
                        onChange={(e) => {
                            setEditFilterName(e.target.value);
                            if (editFilterError) {
                                setEditFilterError(false);
                            }
                        }}
                        autocomplete={"off"}
                    >
                        {(editFilterError && (
                            <span className={"input-label__error"}>{t("required")}</span>
                        )) || <span>{t("filtername")}</span>}
                    </InputInLabel>
                )}
                <EditFilterWrapper>
                    {isFilterEdit && (
                        <Button
                            onClick={() => {
                                dispatch(hide());
                                dispatch(setFilterEditId(false));
                                handleEditFilter(isFilterEdit.id);
                            }}
                            size={"m"}
                            clazz={"filter__submit button--green"}
                        >
                            {t("savefilters")}
                        </Button>
                    )}

                    <Button size={"s"} clazz={"button--tetriary"} onClick={()=>{handleClose()}}>
                        {t("toclose")}
                        <Icon size={"l"} spritePath={"close"}/>
                    </Button>
                </EditFilterWrapper>
            </div>
        </Popup>
    );
};

export default Filter;
