import {Button, Icon, InputInLabel} from "@/components/ui";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useMakeRequest} from "@/hooks/useMakeRequest";
import GeoInput from "../ui/input/GeoInput";
import {yupResolver} from "@hookform/resolvers/yup";
import {object, string} from "yup";
import useLadyService from "@/services/LadyService";
import {
    setViewingStatus, setAuthorisationAccessType, setAuthorizationStatus,
} from "@/stores/slices/popupSlice";
import {getSelected, getSelectedCount} from "@/stores/slices/agencyFilterSlice";
import {
    selectAdd,
    selectReset,
} from "@/stores/slices/agencyFilterSlice";
import {
    setOneProfileAddress,
    setOneProfileCoords,
} from "@/stores/slices/oneProfileSlice";
import showToast from "@/components/toast/Toast";
import {useForm} from "react-hook-form";
import {Checkbox} from "../ui";
import {getAllModels} from "@/stores/slices/modelsState";
import PhoneInput from "../ui/input/PhoneInput";
import {format, add, parse, getUnixTime} from "date-fns";


const OrderPreviewsForm = ({
                               agencyId = false,
                               checkbox = false,
                               preview_price = false
                           }) => {

    const {dispatch, lang, t, userType} = useLadyService();

    const profiles = useSelector(getSelected);
    const selectedModelsCount = useSelector(getSelectedCount);
    const models = useSelector(getAllModels);


    const [phoneValue, setPhoneValue] = useState("");
    const [addressUser, setAddressUser] = useState(null);
    const [waValue, setWaValue] = useState("");
    const [valueLoad, setValueLoad] = useState(false);
    const [isAddressActive, setIsAddressActive] = useState(false);
    const [newDate, setNewDate] = useState(format(new Date(), 'dd.MM.yyyy'));
    const [allSelected, setALlSelected] = useState(false);

    const makeRequest = useMakeRequest()

    const [previewsModels, setPreviewsModels] = useState([]);
    useEffect(() => {
        setPreviewsModels(models.filter((model) => {
            if (model?.ready_preview && model.ready_preview === 1)
                return model
        }));
    }, [models])


    const fullReset = () => {
        reset();
        setNewDate(null);
        setWaValue("");
        setPhoneValue("");
        dispatch(setViewingStatus(false));
    };


    const handleLocationClick = () => {
        setIsAddressActive(true);

        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(async function (position) {
                const {latitude, longitude} = position.coords;

                try {
                    const response = await axios.get(
                        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1&accept-language=${lang}`
                    );
                    const {
                        city:
                            road,
                        house_number,
                    } = response?.data?.address;

                    dispatch(
                        setOneProfileAddress(
                            `${road ?? ''} ${house_number ? ", " + house_number : ""}`
                        )
                    );
                    dispatch(setOneProfileCoords({latitude, longitude}));
                    setAddressUser(`${road ?? ''} ${house_number ? ", " + house_number : ""}`)
                } catch (error) {

                    console.error("Error fetching address:", error);
                }
            });

        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    };

    const selectAll = () => {
        const status = !allSelected
        setALlSelected(status)
        if (status) {
            previewsModels.forEach(model => {
                dispatch(selectAdd(model.id));
            });
        } else {
            dispatch(selectReset())
        }
    }


    const formSchema = object().shape({
        phone: string()
            .min(11, `${t("phonenumerrequired")}`)
            .test("phone-is-empty", t("phonenumerrequired"), function () {
                return !!phoneValue;
            }),
        date: string().required(`${t("required")}`),
        book_at: string().required(`${t("required")}`),
        address: string().required(t("required"))
    });


    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: {errors},
        clearErrors,
    } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(formSchema),
    });

    useEffect(()=>console.log('err : ', errors),[errors.book_at])
    
    const onSubmit = (data) => {


        const fetchData = async () => {

            try {
                const route = `previews/${agencyId}`;

                const {date, book_at} = data;
                const targetDate = getUnixTime(parse(`${date} ${book_at}`, 'dd.MM.yyyy HH:mm', new Date()));
                let currentTimePlusOneHour = getUnixTime(add(new Date(), {hours: 1}));
                if (targetDate < currentTimePlusOneHour) {
                    showToast({
                        message: t("timemorehour"),
                        variant: "error",
                    });
                    return;
                }

                const method = "POST";

                const payload = {
                    book_at: targetDate,
                    address: data.address,
                    phone: parseInt(phoneValue),
                    ...(!!parseInt(waValue) && {
                        whatsapp: parseInt(waValue),
                    }),
                    profiles
                };
                const result = await makeRequest({route, method, payload});
                if (result.message === "preview_request_created") {
                    showToast({
                        message: t("ordersuccess"),
                        variant: "order",
                    });
                    fullReset();
                }
            } catch (error) {
                console.log('error', error)
                showToast({
                    message: t("oops"),
                    variant: "error",
                });
            }
        };

        fetchData();
    };


    const handleViewingStatusAgency = (e) => {
        if (selectedModelsCount > 0) {
            if (userType === 'default') {
                dispatch(setAuthorizationStatus(true));
                dispatch(setAuthorisationAccessType("preview"));
            } else if (addressUser && phoneValue) {
                handleSubmit(onSubmit)
            } else {
                console.log('no data: ', addressUser, phoneValue);
            }
        }
        e.preventDefault()
    }


    useEffect(() => {

        const fetchData = async () => {

            setValueLoad(false);

            try {
                const route = `user/info`;
                const method = "GET";

                const data = await makeRequest({route, method});

                const result = data?.data;
                setPhoneValue(result?.phone);
                setWaValue(result?.whatsapp);
                setAddressUser(`${result?.address?.[0]?.city ? result.address?.[0]?.city + ', ' : ''}${result.address?.[0]?.address}`);
                setValue("address", `${result?.address?.[0]?.city ? result.address?.[0]?.city + ', ' : ''}${result.address?.[0]?.address}`);
                setValueLoad(true);
            } catch (error) {
            }
        };

        userType !== "default" && fetchData();

    }, [userType]);


    useEffect(() => {

        setValueLoad(false);
        setValue("phone", String(phoneValue));
        setNewDate(format(new Date(), "dd.MM.yyyy"));
        setValue("date", format(new Date(), "dd.MM.yyyy"));
        setValueLoad(true);

    }, [phoneValue, setValue]);


    return (
        <>
            {checkbox ?
                (<>
                    <h3 id={"orderToLook"}>{t("demomodels")}</h3>

                    <p className={"p1 color-700"}>{t("ordersevprev")}</p>

                    <p className={"p1 color-main"}>
                        {`${t('priceforone')} - `}<span className="currency--erocoin"> {preview_price}</span>
                    </p>

                    <div className={"previews-counts-container"}>
                        <div className={"d-flex gap-12 align-center"}>
                            <span className="color-700">{t("numberofmodels")}:</span>
                            <span className={"title title_h2"}>{selectedModelsCount}</span>
                        </div>

                        <div className={"d-flex gap-12 align-center"}>
                            <span className="color-700">{t("priceforselected")}:</span>
                            <span className={"title title_h2 color-green currency--erocoin"}>
                    {selectedModelsCount * preview_price}
                </span>
                        </div>

                        <Checkbox
                            id={"agencyFilterAll"}
                            name={"all"}
                            clazzLabel={"p-0"}
                            checked={allSelected}
                            onChange={selectAll}
                            title={`${t("selectall")} ${previewsModels?.length}`}
                        />
                    </div>


                    <form className="agency-filter__box" onSubmit={handleSubmit(onSubmit)}>

                        <div className={"agency-filter__1group d-flex gap-8 align-center"}>

                            <InputInLabel
                                clazz={`${errors.date ? " error" : ""}`}
                                type={"date"}
                                id={"orderDate"}
                                register={{...register("date")}}
                                required
                                onChange={(value) => {
                                    setValue("date", format(value, "dd.MM.yyyy"));
                                }}
                                value={newDate}
                            >
                                {errors.date ? (
                                    <span className={"input-label__error"}>{t("date")}</span>
                                ) : (
                                    t("date")
                                )}
                            </InputInLabel>

                            <InputInLabel
                                clazz={`${errors.book_at ? " error" : ""}`}
                                register={{...register("book_at")}}
                                registerName={"book_at"}
                                setValue={setValue}
                                id={"orderTime"}
                                placeholder={"18:00"}
                                type={"time"}
                                required
                            >
                                {errors.book_at ?
                                    (
                                        <span className={"input-label__error"}>{t("time")}</span>
                                    ) : (
                                        t("time")
                                    )}
                            </InputInLabel>

                        </div>

                        <div className="popup-form__inner agency-filter__2group fd-row gap-8">

                            <div className="search filter__map-input">

                    <span className={"filter__map-title"}>
                        {errors.address ?
                            (
                                <span className={`input-label__error ${errors.address ? "error" : ""}`}>
                                {errors.address.message}
                            </span>
                            ) : (
                                t("youraddress")
                            )}
                    </span>

                                <label
                                    htmlFor="geosuggest__input"
                                    className={`search__label header__search ${errors.address ? "error" : ""} required`}
                                >
                                    <GeoInput
                                        value={addressUser ? addressUser : ''}
                                        name={"address"}
                                        id="geosuggest__input-create"
                                        clazz={`header__search-input`}
                                        placeholder={t("fulladdress")}
                                        setValue={(value, address) => {
                                            if (typeof address === Object) {
                                                setValue(value, address?.address)
                                            } else {
                                                setValue(value, address)
                                            }
                                        }}
                                    />
                                </label>
                            </div>

                            <Button
                                title={t("chekmyplace")}
                                onClick={handleLocationClick}
                                name={"location"}
                                square={true}
                                size={"l"}
                                clazz={isAddressActive ? "button--tetriary bg-color-500" : "button--tetriary"}
                            >
                                <Icon size={"xl"} spritePath={"location"}/>
                            </Button>

                        </div>

                        <div className="agency-filter__3group d-flex fd-row gap-8">

                            <PhoneInput
                                clazz={`${errors.phone ? " error" : ""}`}
                                id={"orderPhone"}
                                type={"tel"}
                                placeholder={t("yourphone")}
                                register={{...register("phone")}}
                                autocomplete={"tel"}
                                testValue={phoneValue}
                                maskValue={phoneValue}
                                setMaskValue={setPhoneValue}
                                valueLoad={valueLoad}
                                required
                            >
                                {(errors.phone &&
                                    (
                                        <span className={"input-label__error"}>
                           {errors.phone.message}
                       </span>
                                    )
                                ) || (
                                    <span>
                            {t("yourphone")}
                        </span>
                                )
                                }
                            </PhoneInput>

                            <PhoneInput
                                id={"orderWaPhone"}
                                clazz={"w-100"}
                                type={"tel"}
                                placeholder={"Whatsapp"}
                                register={{...register("whatsapp")}}
                                autocomplete={"tel"}
                                testValue={waValue}
                                maskValue={waValue}
                                setMaskValue={setWaValue}
                                valueLoad={valueLoad}
                            >
                                <span>Whatsapp</span>
                            </PhoneInput>

                        </div>
                        {userType === 'default' ? (<Button
                            size={"l"}
                            clazz={"button--green"}
                            disabled={selectedModelsCount <= 0}
                            onClick={() => {
                                dispatch(setAuthorizationStatus(true));
                                dispatch(setAuthorisationAccessType("preview"));
                            }}>
                            {t("order")}
                        </Button>) : (
                            <Button
                                size={"l"}
                                clazz={"button--green"}
                                disabled={selectedModelsCount <= 0}
                                buttonType={"submit"}
                            >
                                {t("order")}
                            </Button>
                        )}

                    </form>

                    <p className={"p1 color-700 mt-12"}>{t('amoundebitedto')}</p>

                    <p className={"p1 color-700 mt-12"}>{`* ${t('paymentforprev')}`}</p>

                </>) : null}
        </>
    )
}

export default OrderPreviewsForm;
