import {useState, useEffect} from "react";
import {makeRequest} from "@/services/makeRequest";
import { Button, Icon, Loader, Toggle, InputInLabel,  } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import {CustomSkeleton} from "../../ui";
import {useLocation, useNavigate} from "react-router-dom";
import PartyCard from "../../cards/partyCard/PartyCard";
import '../../pages/partyPage/partyPage.scss'
import showToast from "@/components/toast/Toast";
import PublishPopup from "@/components/popups/publishParty/PublishNSendReqPopup";
import { Popup } from "@/components/popups";
import { format } from "date-fns";
import {useRef} from "react";




const PartiesPage = () => {
  const {token, lang, t, windowWidth, userCity} = useLadyService();

  const navigate = useNavigate();
  
  const [parties, setParties] = useState([]);

  const {hash} = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(1);


  const [openPublishPopup, setOpenPublishPopup] = useState(false);
  const [currentParty, setCurrentParty] = useState()
  const [createPartyOpen, setCreatePartyOpen] = useState(false)
  const [partyName, setPartyName] = useState('')
  const [time, setTime] = useState('18:00')
  const [isOpenDelete, setOpenDelete] = useState(false)
  const [deleteId, setDeleteId] = useState()




  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await makeRequest({
        route: `parties`,
        method: "GET",
        payload: {
          limit: 12,
          page,
        },
      });

      if (res) {
        setParties(res?.parties);
        setIsLoading(false);
      }
      else{
        setIsLoading(false);
      }

    } catch (error) {
      setIsLoading(false)
      console.log("ERR", error);
      showToast({message: t("oops"), variant: "error"});
    }
  };

  

  const deleteParty = async (id) => {

    const route = `agency/parties/${id}`;
    const method = "DELETE";

    try {

      await makeRequest({route, method});
      fetchData()
      setOpenDelete(false)
    } catch (error) {
      setOpenDelete(false)
      console.log("ERR", error);
      showToast({message: t("oops"), variant: "error"});
    }
  }    

  const formatDate = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = String(date.getFullYear());

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    if (year < 10) {
      year = "0" + year;
    }

    return `${day}.${month}.${year}`;
}

const now = new Date();
const currentDate = new Date();
currentDate.setDate(now.getDate() - 1);
const previousMonthDate = new Date();
previousMonthDate.setMonth(now.getMonth() - 1);
const formattedPreviousMonthDate = formatDate(previousMonthDate);
const formattedCurrentDate = formatDate(currentDate);
const [partyDate, setPartyDate] = useState(
    formattedCurrentDate
);
const [error, setError] = useState()

const onChangeDate = (value) => {
  setPartyDate(format(value || new Date(), "dd.MM.yyyyy"));
}

const handleAddParty = ( ) =>{
  setCreatePartyOpen(true)
}

const onClose = () => {
  setCreatePartyOpen(false);
  setPartyName('');
  setPartyDate(formattedCurrentDate);
  setTime('18:00')
}
const handleTime = (e) => {
  setTime(e.target.value)
}


 


  let ammountSceletons ;

    if (windowWidth > 1200){
      ammountSceletons = 8;
    }
    else if (windowWidth < 1201 && windowWidth > 768){
      ammountSceletons = 6;
      ;
    }
    else if (windowWidth < 769 && windowWidth > 480){
      ammountSceletons = 4;
      
    }
    else{
      ammountSceletons = 2;
    }
  

  const skeletons = [...Array(ammountSceletons)].map((_, index) => {
    return <CustomSkeleton key={index} height={150}/>;
  });

  const timeRef = useRef(null)
  
  

  const handleSave = async () => {
    const payload = {
      name: partyName,
      date: partyDate,
      time: time,
      city_id: userCity.id ?? 1
    }
    if(partyName && partyDate)
    {
      try {
        const route = `agency/parties`;
        const method = "POST";
      
        await makeRequest({ route, method, payload });
        
        setCreatePartyOpen(false);
        setPartyName('');
        setPartyDate(formattedCurrentDate);
        setTime('18:00')
        fetchData()
    } 
    catch (error) {
        setCreatePartyOpen(false)
        console.log('error: ', error)
        showToast({
          message: t("oops"),
          variant: "error",})
    }}
    else if(partyName === ''){
      setError('*')
    } 
    else{
      console.log('err payload', payload)
      showToast({
        message: t("oops"),
        variant: "error",})
    }
  };

  useEffect(() => {
    page !== 0 && token && fetchData();
  }, [token, page]);

  useEffect(() => {
    let str = hash;
    str = str.replace("#", "");
    setPage(Number(str) === 0 ? 1 : Number(str));
  }, [hash]);

  
  return (
    <main>
      <section className={"container"}>
        <div className="d-flex w-100 justify-sb align-center">
            <h1>{t("myparty")}</h1>
            <Button 
                clazz={'button button-s button--green bottom-0 height-fit'}
                onClick={handleAddParty}>
                   <Icon spritePath={"plus-1"}/>
                   <span>{t('addad')}</span>
            </Button>
        </div>

        <div className="parties__grid">
          {isLoading ? skeletons
            : !!parties?.length
              ? parties?.map((i, index )=> 
                <PartyCard 
                  key={index} 
                  party={i} 
                  edit={true} 
                  setDelete={setDeleteId}
                  setCurrentParty={setCurrentParty} 
                  setOpenDelete={setOpenDelete}
                  setOpenPopup={setOpenPublishPopup}/>)
              : <h3 className="text-center mt-12">{t("emptyrecord")}</h3>
          }

        </div>



        {/* {pagesList.length > 1 && (
          <Pagination
            pages={pagesList}
            activePage={page}
            onPageChange={handlePage}
          />
        )} */}
      </section>

      <Popup
            clazz={'p-24'}
            open={createPartyOpen}
            id={"createPartyPopup"}
            setOpen={onClose}
        >
          <h2>{t('createnew')}</h2>
          <div className='fd-column gap-16'>
            <InputInLabel
              clazz={`${error ? " error" : ""} w-100`}
              id="create_party_name"
              placeholder={t("name")}
              required
              onChange={(e)=>setPartyName(e.target.value)}
              value={partyName}
              error={error}
              >
                {error ? 
                  <span className={"input-label__error"}>{t("name")}</span>
                  : 
                  t("name")}
            </InputInLabel>
            <div className="d-flex gap-16 mb-16">
              <InputInLabel
                type={"date"}
                id={"orderDate"}
                required
                onChange={(e)=>onChangeDate(e.target.value)}
                value={partyDate}
              > 
                  {t("date")}
              </InputInLabel>
              <InputInLabel
                  id={"orderTime"}
                  placeholder={"18:00"}
                  type={"time"}
                  onChange={handleTime}
              >
                {t("time")}
              </InputInLabel>
            </div>
           
            <div className="d-flex gap-16 mb-16">
              <Button 
              size={'l'}
               clazz={`button button-l button--secondary w-100 bottom-0`}
               onClick={()=>setCreatePartyOpen(false)}
               inputRef={timeRef}
              >
                  {t('cancel')}
              </Button>
              <Button 
               size={'l'}
               clazz={'button button-l button--green w-100 bottom-0'}
               onClick={handleSave}
               disable={!partyName}

               >
                  {t('createnew')}
              </Button>
            </div>
          </div>
      </Popup>

      <PublishPopup 
        openPopup={openPublishPopup}
        setOpenPopup={setOpenPublishPopup}
        partyId={currentParty}
      />

      {isOpenDelete && 
        <Popup 
          clazz={'p-24'}
          open={isOpenDelete}
          id={'deletePartyPopup'}
          setOpen={setOpenDelete}
        >
          <h2>{t('delete')}?</h2>
          <div className="d-flex gap-12 mt-32">
            
            <Button 
                clazz={`button button-l button--secondary w-100`}
                onClick={()=>setOpenDelete(false)}
              >
                {t('cancel')}
            </Button>
            <Button 
                clazz={`button button-l button--error w-100`} 
                onClick={()=>deleteParty(deleteId)}
              >
                {t('delete')}
            </Button>

          </div>
        </Popup>}  
    </main>
  );
};

export default PartiesPage;
