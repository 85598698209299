import { Link } from "react-router-dom";
import { Button, ButtonLink, Icon, UserBadge } from "@/components/ui";
import { format, fromUnixTime } from "date-fns"
import React, { useState } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { wordEndings } from "@/helper/wordEndings";
import { CheckPhonePopup } from "@/components/popups";
import useLadyService from "@/services/LadyService";
import PreviewActions from "@/components/orderPreviewsForm/PreviewActions";
import './PreviewContainer.scss'

const statusObj = {
    0: "workstatus",
    1: "approvestatus",
    2: "declinestatus",
    3: "completestatus",
};

const PreviewContainer = (props) => {
    const { data, setData } = props;



    const {
        from,
        whatsapp,
        phone,
        profiles,
        book_at,
        status,
        price,
        created_at,
        address,
        id,
        reviews = [],
    } = data;

    const {
        name: nameFrom,
        // phone: phoneFrom,
        rating: ratingFrom,
        tariff: tariffFrom,
        // whatsapp: whatsappFrom,
    } = from;

    const phoneRes = formatPhoneNumberIntl(`+${phone}`);
    const whatsRes = formatPhoneNumberIntl(`+${whatsapp}`);

    const [isOpenCheckPhone, setIsOpenCheckPhone] = useState(false);

    const { t, setMediaSize, windowWidth } = useLadyService();

    let classVariant;
    switch (statusObj[status]) {
        case "approvestatus":
        case "completestatus":
            classVariant = " approve";
            break;
        case "declinestatus":
            classVariant = " decline";
            break;
        default:
            classVariant = " work";
    }

    const handleOpen = () => {
        setIsOpenCheckPhone(true);
    };

    const bookAt = format(fromUnixTime(book_at), 'dd.MM.yyyy HH:mm');
    const startAt = format(fromUnixTime(created_at), 'dd.MM.yyyy HH:mm');

    const newProfiles = [...profiles, ...profiles, ...profiles]


    return (
        <>
            <div className={"res__root"}>
                <div className={`res__user__main${classVariant}`}>
                    <div className="res__user__block">
                        <UserBadge
                            props={{
                                slug: tariffFrom,
                                name: nameFrom,
                                score: ratingFrom
                            }}
                        />

                        <p className={'p3 mb-0'}>{startAt.replace(' ', ` ${t('at')} `)}</p>
                    </div>

                    <div className="res__user__block">
                        {!!whatsapp ? (
                            <div className="res__user-contacts fd-column">
                                <Link to={`tel:${phone}`} className={"hover-line"}>
                                    <b>{phoneRes}</b>
                                </Link>

                                <ButtonLink
                                    href={`https://wa.me/${whatsapp}`}
                                    clazz={"button--primary"}
                                    size={"xs"}
                                    target={"_blank"}
                                >
                                    <Icon
                                        size={"m"}
                                        spritePath={"whatsapp"} />
                                    {whatsRes.replaceAll(' ', '')}
                                </ButtonLink>
                            </div>
                        ) : (
                            <Link to={`tel:${phone}`} className={"hover-line"}>
                                <b>{phoneRes}</b>
                            </Link>
                        )}

                        <Button
                            size={"xs"}
                            buttonType={"submit"}
                            clazz={"button_outline--green align-self-start"}
                            title={t("crossnumber")}
                            onClick={handleOpen}
                        >
                            {t("crossnumber")}
                        </Button>
                    </div>
                </div>

                <div className={`res__info__block${classVariant}`}>
                    <div className={`res__info__block__money${classVariant}`}>
                        <div className="res__price ">
                            <span className={'p3'}>{t("dateandtime")}</span>
                            <p className="p1 color-main mt-0">{bookAt.replace(' ', ` ${t('at')} `)}</p>
                            {address && windowWidth > 1023 &&
                                <>
                                    <span className="p3">{t('adresofclient')}</span>
                                    <p className={'p1 color-main'}>
                                        <Link
                                            target={'_blank'}
                                            to={`//www.google.com/maps/search/?api=1&query=${address.replace(/ /g, '+')}`}
                                            title={t('viewonmap')}
                                            className="girl-card__address color-main p1 hover-line d-none-tablet-small"
                                        >
                                            <Icon spritePath={"map"} size={"xs"} clazz={'mr-8'} />{address}
                                        </Link>
                                    </p>
                                </>}



                            {status !== 0 && <p className={'p1 color-main'}>{t("selected")} {profiles.length} {t('profiles')}</p>}
                            <p className={"align-baseline gap-8 mt-0"}>
                                {status !== 0 ? <span className={'p1 color-main '}>
                                    {t("prepayment") + ' '}
                                </span> :
                                    <span className="p3 mt-0 mb-0">{t('youwillget') + '*'}</span>}
                                <h3 className={status !== 0 ? 'currency--erocoin mt-0 mb-0' : 'currency--erocoin color-green mt-0 mb-0'}>
                                    {price}
                                </h3>
                            </p>
                            {status === 0 &&
                                <p>
                                    {'*' + t('paymentforprev')}
                                </p>}
                        </div>
                    </div>
                    <div className={"d-flex fd-column"}>
                        {status === 0 ?
                            <p className="d-flex align-baseline gap-8 mt-0 selected-models-count">
                                <span className={'p1'}>{t("selected")} {t('profiles')}</span>
                                <h3 className="color-green mt-0">{profiles.length}</h3>
                            </p> : <></>}
                        <div className={`${((windowWidth > 769 && newProfiles.length < 5) || (windowWidth < 769 && !(newProfiles.length > 3)))
                            ? 'd-flex gap-8' : 'align-self-start'}`}>
                            {
                                (newProfiles.length && (((windowWidth > 769) && newProfiles.length < 5) || (windowWidth < 769 && !(newProfiles.length > 3))))
                                    ? newProfiles.map((profile) => {
                                        return (
                                            <Link
                                                to={`/profile/${profile.slug}`}
                                                target={"_blank"}
                                                className="res__ad"
                                                alt="ad"
                                            >
                                                <img
                                                    src={setMediaSize(profile.photo, "xs")}
                                                    className="res__ad__photo"
                                                    alt="ad"
                                                />
                                                <span title={profile.name} className="p1 mt-12 res__ad__title color-green text-dots">
                                                    {profile.name}
                                                </span>
                                            </Link>)
                                    })
                                    :
                                    newProfiles.map((profile) => {
                                        return (
                                            <Link
                                                to={`/profile/${profile.slug}`}
                                                target={"_blank"}
                                                className="res__ad hover-line"
                                                alt="ad"
                                            >
                                                <span title={profile.name} className="p1 mt-12 res__ad__title color-green text-dots">
                                                    {profile.name}
                                                </span>
                                            </Link>
                                        )
                                    })}
                        </div>
                        {/* button show all {profiles.length} */}
                    </div>
                </div>

                {address && (windowWidth < 1024) && (
                    <>
                        <span className="p3 text-center w-100">{t('adresofclient')}</span>
                        <Link
                            target={'_blank'}
                            to={`//www.google.com/maps/search/?api=1&query=${address.replace(/ /g, '+')}`}
                            title={t('viewonmap')}
                            className="girl-card__address color-main p1 hover-line text-center d-none-tablet-small-reverce "
                        >
                            <Icon spritePath={"map"} size={"xs"} clazz={'mr-8'} />
                            {address}
                        </Link>
                    </>
                )}



                <PreviewActions
                    status={status}
                    reviews={reviews}
                    id={id}
                    setData={setData}
                    book_at={book_at}
                />
            </div>

            <CheckPhonePopup
                open={isOpenCheckPhone}
                setOpen={setIsOpenCheckPhone}
                phoneClient={phone}
            />
        </>
    );
};

export default PreviewContainer;
