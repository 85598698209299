import {useEffect} from 'react';

function useTitle(title, set = true) {
	useEffect(() => {
		if (set) {
			document.title = title;
		}
	});
}

export default useTitle;
