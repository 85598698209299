import {Checkbox, InputInLabel, Loader} from "../../../ui";
import {Link} from "react-router-dom";
import {useCallback, useState, useEffect} from "react";
import debounce from "lodash.debounce";
import {makeRequest} from "@/services/makeRequest";
import useLadyService from "@/services/LadyService";
import {Button, Icon} from "@/components/ui";
import showToast from "@/components/toast/Toast";


const SearchBlock = ({
                       selectName,
                       isLoading,
                       currentName,
                       setName,
                       selectSearch,
                       setSelectSearch,
                       selectData,
                       setSelectData,
                       oneOnlySelect = false,
                     }) => {
  const {t, setMediaSize,} = useLadyService()

  const Res = ({ options, handleAddChange, selectSearch, isDataEmpty, oneOnlySelect }) => {

    const handleSingleClick = (e,slug,name) => {
      e.preventDefault();
      e.stopPropagation();
      handleAddChange(slug,name)
    }
    switch (true) {
      case load: {
        return <Loader />;
      }

      case !!options.length: {
        return options.map((i) => {
          const { main_photo, id, name, slug } = i;

          return (
            <>
              {oneOnlySelect ?
              <div key={id} className="agency-block" onClick={(e)=>handleSingleClick(e,slug,name)}>
                <img src={setMediaSize(main_photo, "xs")} alt="girl" />
                <Link
                  target="_blank"
                  to={`/profile/${slug}`}
                  className="agency-block__link-to"
                >
                  {name}
                </Link>
              </div>
               :<div key={id} className="agency-block">
                <img src={setMediaSize(main_photo, "xs")} alt="girl" />
                <Link
                  target="_blank"
                  to={`/profile/${slug}`}
                  className="agency-block__link-to"
                >
                  {name}
                </Link>
                <Checkbox
                  border={false}
                  checked={slug === selectName?.slug}
                  onChange={() => handleAddChange(slug, name)}
                />
              </div>}
            </>
          );
        });
      }

      case !selectSearch: {
        return <span className="w-100 text-center">{t("startname")}</span>;
      }

      case isDataEmpty: {
        return <span className="w-100 text-center">{t("noproffound")}</span>;
      }

      default: {
        return <Loader />;
      }
    }
  };

  const [value, setValue] = useState(null);
  const [options, setOptions] = useState(selectData);
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const [load, setLoad] = useState(false);
  const [search, setSearch] = useState(selectSearch);

  const handleAddChange = (slugAgency, name) => {
    setSelectSearch(search);
    setSelectData(options);
    if (name === currentName.name) {
      setName({
        name: "",
        slug: "",
      });
    } else {
      setName({
        name,
        slug: slugAgency,
      });
    }
    if (slugAgency === value) {
      setValue(null);
    } else {
      setValue(slugAgency);
    }
  };

  const handleFilter = useCallback((e) => {
    if (isDataEmpty) {
      setIsDataEmpty(false);
    }
    const val = e.currentTarget.value;
    setSearch(val);
    fetchData(val);
  }, []);
  const getProfiles = async (payload = {}) => {
      setLoad(true);
      try{const route = `user/profiles`;
      const method = "GET";
      const { profiles } = await makeRequest({ route, method, payload });
      setIsDataEmpty(!profiles.length);
      setOptions(profiles);
      setLoad(false);}
      catch{
        showToast({message: t('oops'), variant: 'error'});
        setLoad(false)
      }
  }
  const fetchData = useCallback(
    debounce(async (value) => { 
        if (!!value) {
          getProfiles( {
            search: value,
          })
        }
    }, 1000),
    []
  );

  useEffect(()=>{
    getProfiles({limit: 5})
  }, [])

  return (
    <form>
      {/* <InputInLabel
        placeholder={t("searchmodel")}
        onChange={handleFilter}
        clazz={"fd-row"}
        size={"s"}
        value={search}
      /> */}
      <div className="search">
        <label className="search__label">
            <input
            placeholder={t("searchmodel")}
            value={search}
            className={`search__input p-0`}
            onChange={handleFilter}
            type={"search"}
        />

        {!!search && (
            <Button
                title={t("reset")}
                onClick={() => setSearch("")}
                clazz="search__close-r8px"
            >
              <Icon size={"m"} spritePath={"close"}/>
            </Button>
        )}
          </label>
      </div>
      <div className="agency-block__content mt-16">
        <Res
          isLoading={isLoading}
          options={options}
          handleAddChange={handleAddChange}
          search={search}
          isDataEmpty={isDataEmpty}
          oneOnlySelect={oneOnlySelect}
        />
      </div>
    </form>
  );
};

export default SearchBlock