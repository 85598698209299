import React, { useState } from "react";
import useLadyService from "@/services/LadyService";
import { Button } from "../../ui";
import { makeRequest } from "@/services/makeRequest";
import showToast from "../../toast/Toast";
import GirlCard from "../../cards/girlCard/GirlCard";
import {format, fromUnixTime} from "date-fns";

export const HistoryCard = ({ data, activeIndex }) => {
  const { t } = useLadyService();
  const getTime = (date) => {
    return format(fromUnixTime(date), 'dd.MM.yyyy HH:mm')
  };
  const isLessThanOneHourAgo = (timestamp) => {
    const currentTime = Math.floor(Date.now() / 1000);
    const oneHourAgo = currentTime - 3600;

    if (timestamp >= oneHourAgo) {
      return true;
    } else {
      return false;
    }
  };

  const [orderStatus, setOrderStatus] = useState(data.data?.data?.status)
  const cancelBooking = async (id, slug) => {
    try {
      const route = `bookings/${id}/cancel`;
      const method = "PUT";

      const result = await makeRequest({ route, method });

      if (result) {
        setOrderStatus(2)
        showToast({
          message: t("success"),
          variant: "success",
        });
      }
    } catch (error) {
      showToast({
        message: t("oops"),
        variant: "error",
      });
      console.log(error);
    }
  };
  return (
    <div className={"favorites-page__card"}>
      {activeIndex === "booking" && (
        <div className={"favorites-page__card-box"}>
          <span className={"color-600"}>
            {getTime(data.data?.data?.book_at)}
          </span>
          {/* <span className={"color-main"}>{t("orderword")}</span> */}

          {isLessThanOneHourAgo(data.data?.data?.book_at) &&
            orderStatus !== 2 &&
            orderStatus !== 3 && (
              <Button
                clazz={"button--secondary"}
                size={"xs"}
                onClick={() =>
                  cancelBooking(data.data?.data.id, data.profile.slug)
                }
              >
                {t("cancel")}
              </Button>
            )}
          {orderStatus === 2 && (
            <span className="color-red-700">{t("declinestatus")}</span>
          )}
          {orderStatus === 3 && (
            <span className="color-green">{t('approvestatus')}</span>
          )}
        </div>
      )}
      <GirlCard props={data.slug ? data : data.profile} key={data.id} big />
    </div>
  );
};
