import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import showToast from "../../../../../../toast/Toast";
import { Image } from "./Image";
import { Icon } from "../../../../../../ui";
import axios from "axios";
import baseUrl from "@/services/apiConfig";
import {
  setAddStoriesStatus,
  setAddStoriesPhoto
} from "@/stores/slices/popupSlice";
import useLadyService from "@/services/LadyService";
import { useDispatch } from "react-redux";

const PhotoUpload = ({
  data,
  setData,
  accept,
  type,
  movePhoto,
  addText = false,
  mode,
  maxSize = false,
  limit = false,
  setError = false,
  video = false,
  formData: userFormData,
  setFormData,
  setAllSteps,
  setOpen = false,
  setLink,
}) => {
  const { token, t } = useLadyService();

  const dispatch = useDispatch();


  const acceptedExtensions = Object.values(accept).flat().join(", ");

  // const isDuplicate = (file, existingFiles) => {
  //   return existingFiles.some(
  //     (existingFile) =>
  //       existingFile.name === file.name &&
  //       existingFile.size === file.size &&
  //       existingFile.type === file.type
  //   );
  // };
  const getValidFormatsMessage = (type) => {
    if (type === "photo") {
      return `${t("allowed")}: JPG, JPEG, PNG, WEBP`;
    } else if (type === "video") {
      return `${t("allowed")}: MP4, MOV, WMV, AVI, MKV`;
    } else {
      return `${t("allowed")}: JPG, JPEG, PNG, WEBP, MP4, MOV, WMV, AVI, MKV`;
    }
  };

  const postUploadInfo = (count, size) => {
    return t("max10-20", {count, size})
  };

  const postEroInfo = (count) => {
    return t("addcountero",{count})
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = [];

      if (
        limit &&
        acceptedFiles?.length === 1 &&
        data.files?.length > limit - 1
      ) {
        showToast({
          message: t("maxfileerror"),
          variant: "error",
        });
        return;
      }

      if (limit && acceptedFiles.length > limit) {
        showToast({
          message: t("maxfileerror"),
          variant: "error",
        });
      }

      const filesToProcess =
        limit && acceptedFiles.length > limit
          ? acceptedFiles.slice(0, limit)
          : acceptedFiles;
      setError && setError("");

      filesToProcess.forEach(async (file) => {
        if (
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\.\w+$/.test(
            file.name
          )
        ) {
          return;
        }
        if (
          !file.type.startsWith(type === "photo" ? "image/" : "video/") &&
          !(
            type === "mixed" &&
            (file.type.startsWith("image/") || file.type.startsWith("video/"))
          )
        ) {
          showToast({
            message: `${getValidFormatsMessage(type)}`,
            variant: "error",
          });
          return;
        }
        // file.size > 20 * 1024 * 1024
        if (maxSize && file.size > maxSize) {
          showToast({
            message: `${t("maxfilesize")}: ${
              String(maxSize).substr(0, 2) + " Mb"
            }`,
            variant: "error",
          });
          return;
        }
        // const existingFiles =
        //   type === "mixed" ? [...data.images, ...data.videos] : data.files;
        // if (isDuplicate(file, existingFiles)) {
        //   showToast({
        //     message: `${file.name} ${t("filealreadyixist")}`,
        //     variant: "error",
        //   });
        //   return;
        // }

        const filePreview = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        newFiles.push(filePreview);
      });

      if (newFiles.length > 0) {
        const uploadFiles = async () => {
          for (let i = 0; i < newFiles.length; i++) {
            await uploadDataToServer(newFiles[i], mode, newFiles);
          }
        };
        uploadFiles();
      }
    },
    [setData, mode, showToast]
  );

  const uploadDataToServer = (file, format, data) => {
    setData((prevData) => {
      return {
        files: [
          ...prevData.files,
          {
            name: file.name,
            preview: file.name,
            status: "load",
          },
        ],
      };
    });

    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", file.name);
    axios
      .post(baseUrl + `user/profiles/${userFormData.slug}/media`, formData, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          "Content-Type": "multipart/form-data",
        },
        params: {
          ...(format === "interior" && { is_interior: true }),
          ...(format === "ero" && { is_ero: true }),
          // ...(eroPrice && format === "ero" && { price: Number(eroPrice) }),
          // ...(eroDesc && format === "ero" && { description: eroDesc }),
        },
      })
      .then((response) => {
        const link = response.data.link;
        const newFile = {
          status: "success",
          link,
          name: link,
          preview: link,
          moderated: format === "ero" ? 0 : null,
        };
        if (setAllSteps && format === "main") {
          setAllSteps(true);
        }
        if (link) {
          setFormData((prevData) => {
            if (format === "main") {
              return {
                ...prevData,
                media: {
                  ...prevData.media,
                  photo: [...prevData.media.photo, link],
                },
              };
            }
            if (format === "interior") {
              return {
                ...prevData,
                media: {
                  ...prevData.media,
                  interior: [...prevData.media.interior, link],
                },
              };
            }
            if (format === "video") {
              return {
                ...prevData,
                media: {
                  ...prevData.media,
                  video: [...prevData.media.video, link],
                },
              };
            }
            if (format === "ero") {
              if (type === "photo") {
                if (Array.isArray(prevData.ero)) {
                  return {
                    ...prevData,
                    ero: {
                      data: {
                        photo: {
                          data: [
                            {
                              link,
                              moderated: 0,
                            },
                          ],
                        },
                        video: {
                          data: [],
                        },
                        description: {},
                        description_translate_to: [],
                        price: 10,
                      },
                    },
                  };
                } else {
                  return {
                    ...prevData,
                    ero: {
                      data: {
                        photo: {
                          data: [
                            ...prevData.ero?.data?.photo?.data,
                            {
                              link,
                              moderated: 0,
                            },
                          ],
                        },
                        video: {
                          data: [...prevData.ero?.data?.video?.data],
                        },
                        description: prevData.ero?.data?.description || {},
                        description_translate_to:
                          prevData.ero?.data?.description_translate_to || [],
                        hide: prevData.ero?.data?.hide,
                        photo_count: prevData.ero?.data?.photo_count,
                        price: prevData.ero.data?.price,
                      },
                    },
                  };
                }
              }
              if (type === "video") {
                if (Array.isArray(prevData.ero)) {
                  return {
                    ...prevData,
                    ero: {
                      data: {
                        photo: {
                          data: [],
                        },
                        video: {
                          data: [
                            {
                              link,
                              moderated: 0,
                            },
                          ],
                        },
                        description: {},
                        description_translate_to: [],
                        price: 10,
                      },
                    },
                  };
                } else {
                  return {
                    ...prevData,
                    ero: {
                      data: {
                        description: prevData.ero.data?.description,
                        description_translate_to:
                          prevData.ero.data?.description_translate_to,
                        hide: prevData.ero?.data.hide,
                        photo_count: prevData.ero.data?.photo_count,
                        price: prevData.ero.data?.price,
                        photo: {
                          data: [...prevData.ero.data?.photo.data],
                        },
                        video: {
                          data: [
                            ...prevData.ero.data?.video.data,
                            {
                              link,
                              moderated: 0,
                            },
                          ],
                        },
                      },
                    },
                  };
                }
              }
            }
          });
        }

        setData((prevData) => {
          return {
            files: [
              ...prevData.files.filter((item) => item.name !== file.name),
              newFile,
            ],
          };
        });
      });
  };


  const removeFile = (fileName, fileType) => {
    setData((prevData) => {
      if (type === "mixed") {
        return {
          images:
            fileType === "image"
              ? prevData.images.filter((file) => file.name !== fileName)
              : prevData.images,
          videos:
            fileType === "video"
              ? prevData.videos.filter((file) => file.name !== fileName)
              : prevData.videos,
        };
      } else {
        return {
          files:
            prevData.files
              ?.filter((file) => file.name !== fileName)
              .filter((file) => file.link !== fileName) ||
            prevData.filter((file) => file.link !== fileName),
        };
      }
    });
    setFormData((prevData) => {
      if (mode === "main") {
        const newData = [
          ...prevData.media.photo.filter((item) => item !== fileName),
        ];
        return {
          ...prevData,
          media: {
            ...prevData.media,
            photo: newData,
          },
        };
      }
      if (mode === "interior") {
        const newData = [
          ...prevData.media.interior.filter((item) => item !== fileName),
        ];
        return {
          ...prevData,
          media: {
            ...prevData.media,
            interior: newData,
          },
        };
      }
      if (mode === "video") {
        const newData = [
          ...prevData.media.video.filter((item) => item !== fileName),
        ];
        return {
          ...prevData,
          media: {
            ...prevData.media,
            video: newData,
          },
        };
      }
      if (mode === "ero") {
        if (type === "photo") {
          const newData = [
            ...prevData.ero.data.photo.data.filter(
              (item) => item.link !== fileName
            ),
          ];
          return {
            ...prevData,
            ero: {
              data: {
                description: prevData.ero.data.description,
                description_translate_to:
                  prevData.ero.data.description_translate_to,
                hide: prevData.ero.data.hide,
                photo_count: prevData.ero.data.photo_count,
                price: prevData.ero.data.price,
                photo: {
                  data: newData,
                },
                video: {
                  data: [...prevData.ero.data.video.data],
                },
              },
            },
          };
        }
        if (type === "video") {
          const newData = [
            ...prevData?.ero?.data?.video?.data.filter(
              (item) => item.link !== fileName
            ),
          ];
          return {
            ...prevData,
            ero: {
              data: {
                description: prevData.ero.data.description,
                description_translate_to:
                  prevData.ero.data.description_translate_to,
                hide: prevData.ero.data.hide,
                photo_count: prevData.ero.data.photo_count,
                price: prevData.ero.data.price,
                photo: {
                  data: [...prevData.ero.data.photo.data],
                },
                video: {
                  data: newData,
                },
              },
            },
          };
        }
      }
    });
  };


  const handleRemove = ( e, file ) => {
    e.stopPropagation();

    const linkFileToRemove = !!userFormData.links
      ? userFormData.links.filter(
          (item) => item.name === file.name || item.link === file.name
        )
      : [];
    if (mode !== "main" && !linkFileToRemove.length) {
      axios.delete(`${file.link || file.name}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      removeFile(file.name || file.link);
    }
    if (
      mode === "main" &&
      !linkFileToRemove.length &&
      data?.files?.length > 1
    ) {
      axios.delete(`${file.link || file.name}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      removeFile(file.name || file.link);
    }
    if (
      mode === "main" &&
      !linkFileToRemove.length &&
      data?.files?.length === 1
    ) {
      if (setOpen) {
        setOpen(true);
        setLink(file.name || file.link);
      }
    }
  }

  const [stories, setStories] = useState()

  const handleAddstories = (e, file) => {
    e.stopPropagation();
    dispatch(setAddStoriesStatus(true))
    dispatch(setAddStoriesPhoto(file))
  }


  // const imageFormats = /\.(jpg|jpeg|png|webp)$/i;
  const thumbs = (
    type === "mixed" ? [...data.images, ...data.videos] : data.files || data
  ).map((file, index) => (
    <div key={index} style={{ position: "relative", cursor: "grab" }}>
      <Image
        key={file?.name || file?.link}
        index={index}
        photo={file?.preview || file?.link || file?.name}
        movePhoto={movePhoto}
        moderated={file?.moderated}
        isVideo={video || file?.link?.includes(".mp4")}
        video={video || file?.link?.includes(".mp4")}
        status={file.status}
        type={mode}
      />

      <span
        className="icon-bg"
        onClick={(e) => {
          handleRemove(e, file)
        }}
      >
        <Icon size={"s"} spritePath={"close"} />
      </span>
      <span 
        className="icon-bg-stories"
        onClick={(e)=>handleAddstories(e, file)}>
           <Icon title={t('addstory')} size={"s"} spritePath={"plus"} />
           {t('addstory')}
      </span>
      {/* <span className="icon-bg-edit" onClick={(e) => {
        e.stopPropagation()
        dispatch(setMediaToChange(file.name || file.link))
        dispatch(setChangePhoto(true))
      }
        }>
        <Icon title="edit" size={"s"} spritePath={"edit"} />
      </span> */}
    </div>
  ));

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
  });

  const handleClick = (e) => {
    const target = e.target
      .closest(".upload__zone")
      .querySelector('.dropzone input[type="file"]');
    // const inputElement = document.querySelector('.dropzone input[type="file"]');
    if (target) {
      target.click();
    }
  };

  const UploadText = () => {
    if (mode === "main") {
      return (
        <>
          <span className="p1 color-main mb-12">{t("upload1")}</span>
          <span className="mb-16">{postUploadInfo(10, 20)}</span>
          <span className="mb-16">{t("fotoeditor")}</span>
          <span className="p1 color-green text-center">{t("openface")}</span>
        </>
      );
    } else if (mode === "ero" && type === "photo") {
      return (
        <>
          <span className="p1 color-main mb-12">{t("i-erodownload")}</span>
          <span className="mb-16">{postEroInfo(20)}</span>
          <span className="p1 color-green text-center w-80">
            {t("i-profitacc")}
          </span>
        </>
      );
    } else if (mode === "ero" && type === "video") {
      return (
        <>
          <span className="p1 color-main mb-12">{t("i-videodownload")}</span>
          <span className="mb-16">{postEroInfo(10)}</span>
          <span className="p1 color-green text-center w-80">
            {t("i-profitacc")}
          </span>
        </>
      );
    } else if (mode === "video") {
      return (
        <>
          <span className="p1 color-main mb-12">{t("addvideo")}</span>
          <span className="mb-16">{postUploadInfo(10, 20)}</span>
          <span className="p1 color-green text-center">{t("more75")}</span>
        </>
      );
    } else if (mode === "interior") {
      return (
        <>
          <span className="p1 color-main mb-12">{t("interdesc")}</span>
          <span className="mb-16">{postUploadInfo(20, 10)}</span>
          <span className="p1 color-green text-center">
            {t("showyourinter")}
          </span>
        </>
      );
    }
  };

  return (
    <>
      <div
        {...getRootProps({
          className: "dropzone upload__zone",
        })}
        tabIndex={0}
        onClick={(e) => handleClick(e)}
      >
        <input {...getInputProps()} multiple accept={acceptedExtensions} />
        <Icon
          clazz={"_green media-upload--icon"}
          size={"xl"}
          spritePath={"plus-1"}
        />
        {!data.files?.length && !data.length && <UploadText />}
        {(!!data.files?.length || !!thumbs.length) && addText && (
          <div className="main-images">
            {thumbs.slice(0, 3)}
            <span className="text-center">{t("mainphotos")}</span>
          </div>
        )}
        {(data.files?.length || !!thumbs.length) && addText && (
          <div className="second-images">{thumbs.slice(3)}</div>
        )}
        {!addText && (!!data.files?.length || !!thumbs.length) && (
          <div className="second-images">{thumbs}</div>
        )}
      </div>
    </>
  );
};

export default PhotoUpload;
