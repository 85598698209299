import { useSelector } from "react-redux";
import {useParams} from "react-router-dom";
import { useEffect } from "react";
import containsKeyword from "../helper/containsKeyword";
import useLadyService from "@/services/LadyService";
import {setProfileCount} from "@/stores/slices/mapModelSlice";
import {getIsMainPage} from "@/stores/slices/modelsState";
import {
  getUserCountry,
  setCity,
  setCountriesData,
  setCurrentCity,
  setLanguagesWords,
  setNationalistWords,
  setServicesWords,
  setCountry,
  setCountrySlug,
  setRegion,
  getUserCurrentCity,
} from "@/stores/slices/userSlice";

import {makeRequest} from "@/services/makeRequest";

const useDefiningCity = (setIsLoading = false) => {
  const {
    dispatch,
    city,
    navigate,
    lang,
    usePrevious
  } = useLadyService()

  const allCities = useSelector(getUserCountry);
  const currentCity = useSelector(getUserCurrentCity);
  const slugOfAllCities = allCities.map((city) => city.slug);
  const {city: cityParam} = useParams()
  const isMainPage = useSelector(getIsMainPage);
  if (setIsLoading) {
    setIsLoading(true)
  }
  const prevLang = usePrevious(lang);
  useEffect(() => {
    try {
      const route = "services";
      const method = "GET";
      if ((cityParam === undefined || currentCity.slug === cityParam) && currentCity.slug !== undefined && prevLang === lang) {
        return
      }
      makeRequest({route, method}).then((response) => {
        const data = response.data;
        if (!!Object.values(data).length) {
          const citiesData = data.cities;
          const languages = data.languages;
          const nationalities = data.nationalities;
          const services = data.services;
          const country = Object.keys(citiesData)[0];
          const countrySLug = citiesData[country].slug
          dispatch(setLanguagesWords(languages));
          dispatch(setNationalistWords(nationalities));
          dispatch(setServicesWords(services));
          dispatch(setCountriesData(citiesData));
          const citySegment = cityParam || countrySLug;
          let cities
          if (countrySLug === citySegment) {
            dispatch(setCountrySlug(countrySLug))
            cities = Object.keys(citiesData).map((key) => {
              const item = citiesData[key]
              return {
                id: item?.id ?? 1,
                title: item.name,
                icon: item.code.toUpperCase(),
                highlight: item.highlight,
                slug: item.slug,
                profiles_count: item?.profiles_count ?? 0,
                sort: item.sort,
              };
            });

          } else {
            let cityExist = false;
            dispatch(setCountrySlug(false))
            cities = citiesData[country].cities.map((item) => {
              if (!cityExist && (item.slug === citySegment)) {
                cityExist = true;
              }
              return {
                id: item.id,
                title: item.name,
                icon: citiesData[country].code.toUpperCase(),
                highlight: item.highlight,
                slug: item.slug,
                profiles_count: item.profiles_count,
                sort: item.sort,
              };
            });
            if (!cityExist && isMainPage) {
              navigate("/404");
              return;
            }
          }
          let currentCity;
          if (citySegment === countrySLug) {
            currentCity = cities.filter((item) => {
              return item.slug === citySegment;
            });
          } else {
            currentCity = cities.filter((item) => {
              return item.slug === citySegment;
            });
          }
          if (!!currentCity?.length) {
            dispatch(setProfileCount(currentCity[0].profiles_count));
            dispatch(setCurrentCity(currentCity[0]));
          } else {
            console.log('citySegment', citySegment)
            navigate('/404')
          }
          dispatch(setCountry(cities));
          dispatch(setRegion(citiesData[country].name));
          if (setIsLoading) {
            setIsLoading(false)
          }
        }
      });
    } catch (error) {
      console.log(error);
      navigate('/404')
    }
  }, [cityParam, currentCity, lang]);

  useEffect(() => {
    if (!city && allCities.length > 0) {
      const url = window.location.pathname;
      const segments = url.split("/");
      const citySegment = segments[2];
      if (citySegment && containsKeyword(citySegment, slugOfAllCities)) {
        const cityBySlug = allCities.filter(
          (city) => city.slug === citySegment
        )[0];
        dispatch(setCity(citySegment));
        if (cityBySlug) {
          dispatch(setCurrentCity(cityBySlug));
        }
      }
    }
  }, [allCities]);
};

export default useDefiningCity;
