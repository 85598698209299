import {Route, Routes, useLocation} from "react-router-dom";
import {
    ErrorPage,
    MainPage,
    OperatorConfirm,
    RegistrationPage,
    Selection,
    VariantModelPage,
} from "../pages";
import Echo from "laravel-echo";
import io from "socket.io-client";
import {useSelector} from "react-redux";

import {
    getUserID,
    getAgencyID,
    setApiUserReady,
    setUserId,
    setUserTariff,
    setUserTariffSlug,
    setUserType,
    setReadyToOrder,
    setReadyToVideochat,
    setFavoriteCounter,
    setBookingAmount,
    setEmailVerifyAt,
    setIsLkPage,
    setUserBalance,
    setUserBookingBlock,
    setUserHold,
    setUserStatus,
    setUserTickets,
    setUserVideochatBlock,
    setUserVideochatCanceledBlock,
    setVideochatAmount,
    setUserAgencyId,
    setVideochatStatus,
    setCity,
    setCountriesData,
    setCurrentCity,
    setLang,
    setLanguagesWords,
    setNationalistWords,
    setServicesWords,
    setIncomingTransaction,
    setCountrySlug, setUserPreviewsBlock,
} from "@/stores/slices/userSlice";
import {
    setFastFilter,
    setDefaultRange,
    extractKeysAndNumbers,
} from "@/stores/slices/filterSlice";
import {Suspense, useEffect} from "react";
import useLadyService from "@/services/LadyService";
import {setNotifyTelegramStatus} from "@/stores/slices/userProfileSlice";
import {setAllModelsMeta} from "@/stores/slices/modelsState";
import {makeRequest} from "@/services/makeRequest";
import {setAgencies, setAgency} from "@/stores/slices/agencySlice";
import {setPreviewsAmount, setRegion} from "@/stores/slices/userSlice";
import {setTitleArray, clearTitle} from "@/stores/slices/titleSlice";
import {setProfileData, setSortedServices, setTopProfilesData} from "@/stores/slices/oneProfileSlice";
import useFetchRanges from "@/hooks/useFetchFilters";
import {setVideoCallStatus} from "@/stores/slices/videoCallSlice";
import {
    clearDescByName,
    setTranslateDesc,
    setTranslateDescEro,
    setTranslatePrefer
} from "@/stores/slices/translateAiSlice";
 
import {setAllModels, setSelections, setNotDisabledCheckbox} from "@/stores/slices/modelsState";
import {Loader} from "@/components/ui"
import {ScrollToTop} from "../ui/ScrollToTop"
import PrivateRoute from "../private-route/private-route"


import SignIn from "../signIn/SignIn";
import AgencyList from "../pages/agencyList/AgencyList";
import AgencyPage from "../pages/agency/AgencyPage";
import VideochatHistoryPage from "../pages/videochatHistoryPage/VideochatHistoryPage";
import PartyPage from "../pages/partyPage/PartyPage";
import ChangePartyPage from "../pages/partyPage/ChangePartyPage";
import ProfilesPage from "../pages/accounts/model/profiles/ProfilesPage";
import CreateProfile from "../pages/accounts/createProfile/CreateProfile";
import ReviewsPage from "../pages/reviews/ReviewsPage";
import ReservationPage from "../pages/reservations/ReservationPage";
import BalancePage from "../pages/balancePage/BalancePage";
import StatisticPage from "../pages/statistic/StatisticPage";
import MainLayout from "../pages/Layouts/MainLayout";
import UIKitPage from "../pages/uIKitPage/UIKitPage";
import About from "../pages/about/About";
import RulesPage from "../pages/rules/RulesPage";
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";
import SupportPage from "../pages/support/SupportPage";
import Guide from "../pages/guide/Guide";
import ComingSoon from "../pages/ComingSoon";
import Balance from "../pages/balance/Balance";
import WitchdrawBalance from "@/components/pages/witchdrawBalance/WitchdrawBalance";
import Subscriptions from "@/components/pages/subscriptions/Subscriptions";
import History from "@/components/pages/history/History";
import RedirectLk from "@/components/pages/redirectLk/RedirectLk";
import AdminLogin from "@/components/pages/adminLogin/AdminLogin";
import OperatorsPage from "@/components/pages/operators/OperatorsPage";
import AccountPage from "@/components/pages/accounts/AccountPage";
import PreviewsPage from "@/components/pages/previews/PreviewsPage";
import ProvisionsPage from "@/components/pages/userDeclaration/ProvisionsPage";
import showToast from "@/components/toast/Toast";
import '../../styles/root.scss'
import PartiesPage from "@/components/pages/partiesPage/PartiesPage";
 
function App({initialData}) {
    const {dispatch, userType, token, lang, navigate, t, theme} = useLadyService();

    const isAgency = userType === "agency"
    useFetchRanges();
    const location = useLocation();
    const urlParams = location.pathname;
    useEffect(() => {
        if (urlParams.includes("videochats") || urlParams.includes("videochat")) {
            dispatch(setVideochatStatus(true));
        }
    }, []);
    /* SSR START */
    if (initialData?.isBot) {
        if (initialData.services !== undefined) {
            const citiesData = initialData.services.data.cities;
            const languages = initialData.services.data.languages;
            const nationalities = initialData.services.data.nationalities;
            const services = initialData.services.data.services;
            dispatch(setLanguagesWords(languages));
            dispatch(setNationalistWords(nationalities));
            dispatch(setServicesWords(services));
            dispatch(setCountriesData(citiesData));
        }

        if (initialData.countrySlug !== undefined) {
            dispatch(setCountrySlug(initialData.countrySlug))
        }
        if (initialData.currentCity !== undefined) {
            dispatch(
                setCurrentCity({
                    ...initialData.currentCity,
                    title: initialData.currentCity.name,
                })
            );
        }

        if (initialData.titleArray !== undefined) {
            dispatch(setTitleArray(initialData.titleArray));
        } else {
            dispatch(clearTitle());
        }

        if (initialData.profile !== undefined) {
            dispatch(setProfileData(initialData.profile));
            const sortedServices = [...initialData.profile.services].sort(
                (a, b) => a.category_id - b.category_id
            );
            dispatch(setSortedServices(sortedServices));
        }
        if (initialData.lang !== undefined) {
            dispatch(setLang(initialData.lang));
        }
        if (initialData.city !== undefined) {
            dispatch(setCity(initialData.city));
        }
        if (initialData.selections !== undefined) {
            dispatch(setSelections(initialData.selections));
        }
        if (initialData.region !== undefined) {
            dispatch(setRegion(initialData.region));
        }
        if (initialData.agencies !== undefined) {
            dispatch(setAgencies(initialData.agencies));
        }
        if (initialData.agency !== undefined) {
            dispatch(setAgency(initialData.agency));
        }
        if (initialData.topProfiles !== undefined) {
            dispatch(setTopProfilesData(initialData.topProfiles));
        }

        if (initialData.meta !== undefined) {
            dispatch(setAllModelsMeta(initialData.meta));
        }
        if (initialData.profiles !== undefined) {
            dispatch(setAllModels(initialData.profiles));
        }

        if (initialData.fastFilters !== undefined) {
            dispatch(setFastFilter(initialData.fastFilters.length > 0));
        } else {
            dispatch(setFastFilter(false));
        }
        if (initialData.filters !== undefined) {
            const filterData = initialData.filters;
            dispatch(
                setDefaultRange({
                    name: "price",
                    min: filterData.price_min,
                    max: filterData.price_max,
                })
            );
            dispatch(
                setDefaultRange({
                    name: "age",
                    min: filterData.age_min,
                    max: filterData.age_max,
                })
            );
            dispatch(
                setDefaultRange({
                    name: "weight",
                    min: filterData.weight_min,
                    max: filterData.weight_max,
                })
            );
            dispatch(
                setDefaultRange({
                    name: "height",
                    min: filterData.height_min,
                    max: filterData.height_max,
                })
            );

            const result = extractKeysAndNumbers(filterData);
            dispatch(setNotDisabledCheckbox(result));
        }
    } else {
        dispatch(setFastFilter(false));
    }
    /* SSR END */


    useEffect(() => {
        if (urlParams.includes("lk")) {
            dispatch(setIsLkPage(true));
        } else {
            dispatch(setIsLkPage(false));
        }
    }, [urlParams, navigate, dispatch]);

    useEffect(() => {
        if (lang && urlParams.includes("payment")) {
            navigate(`/${lang}/lk/topup`);
        }
    }, [lang, navigate, urlParams]);

    const userId = useSelector(getUserID);
    const agencyId = useSelector(getAgencyID);
    useEffect(() => {
        if (token && (userId || agencyId) && process.env.REACT_APP_WEBSOCKET_ENABLED) {
            window.Echo = new Echo({
                broadcaster: "socket.io",
                client: io,
                host: process.env.REACT_APP_WEBSOCKET_URL,
                auth: {headers: {Authorization: "Bearer " + token}},
                transports: ["websocket"],
            });

            const handleEvent = (eventName, handler) => {
                let channel = `App.Models.User.${userId}`
                if (isAgency) {
                    channel = `App.Models.Agency.${agencyId}`
                }
                window.Echo.private(channel).listen(
                    eventName,
                    (e) => {
                        const eventKey = `${eventName}-${e.broadcast_event_id}`;

                        if (!localStorage.getItem(eventKey)) {
                            handler(e);
                            localStorage.setItem(eventKey, "true");
                        }
                    }
                );
            };

            handleEvent("ErrorEvent", (e) => {
                showToast({
                    message: t(e.message),
                    variant: "error",
                    ...(e.message === "insuff" && {type: "insuff"}),
                    ...(e.message === "insuff" && {userType: userType}),
                });
            });
            handleEvent("NotifyrEvent", (e) => {
                showToast({
                    message: t(e.message),
                    variant: "info",
                });
            });
            if (userType === "indi" || userType === 'agency') {
                handleEvent("VideoCallEvent", (e) => {
                    dispatch(setVideochatAmount((prev) => prev + 1));
                    showToast({
                        type: "video",
                        variant: "video",
                    });
                    dispatch(setUserVideochatBlock(e));
                });

                handleEvent("VideoCallCanceledEvent", (e) => {
                    dispatch(setUserVideochatCanceledBlock(e.data));
                    showToast({
                        message: t("canceledbyclient"),
                        variant: "info",
                    });
                });

                handleEvent("DescriptionTranslateEvent", (e) => {
                    dispatch(clearDescByName("description_translate_to"));

                    if (e["cz"]) {
                        dispatch(
                            setTranslateDesc({
                                cz: e["cz"],
                            })
                        );
                    }
                    if (e["ru"]) {
                        dispatch(
                            setTranslateDesc({
                                ru: e["ru"],
                            })
                        );
                    }
                    if (e["en"]) {
                        dispatch(
                            setTranslateDesc({
                                en: e["en"],
                            })
                        );
                    }
                });

                handleEvent("EroDescriptionTranslateEvent", (e) => {
                    dispatch(clearDescByName("description_ero_translate_to"));

                    if (e["cz"]) {
                        dispatch(
                            setTranslateDescEro({
                                cz: e["cz"],
                            })
                        );
                    }
                    if (e["ru"]) {
                        dispatch(
                            setTranslateDescEro({
                                ru: e["ru"],
                            })
                        );
                    }
                    if (e["en"]) {
                        dispatch(
                            setTranslateDescEro({
                                en: e["en"],
                            })
                        );
                    }
                });

                handleEvent("PreferencesTranslateEvent", (e) => {
                    dispatch(clearDescByName("preferences_translate_to"));

                    if (e["cz"]) {
                        dispatch(
                            setTranslatePrefer({
                                cz: e["cz"],
                            })
                        );
                    }
                    if (e["ru"]) {
                        dispatch(
                            setTranslatePrefer({
                                ru: e["ru"],
                            })
                        );
                    }
                    if (e["en"]) {
                        dispatch(
                            setTranslatePrefer({
                                en: e["en"],
                            })
                        );
                    }
                });

                handleEvent("BookingEvent", (e) => {
                    dispatch(setBookingAmount((prev) => prev + 1));
                    showToast({
                        type: "booking",
                        variant: "booking",
                    });
                    dispatch(setUserBookingBlock(e));
                });


                handleEvent("PreviewEvent", (e) => {
                    dispatch(setPreviewsAmount((prev) => prev + 1));
                    showToast({
                        type: "preview",
                        variant: "preview",
                    });
                    dispatch(setUserPreviewsBlock(e));
                });
            } else {
                handleEvent("VideoCallCanceledEvent", (e) => {
                    dispatch(setVideoCallStatus("canceled"));
                });

            }

            handleEvent("BalanceUpdateEvent", (e) => {
                dispatch(setUserBalance(e["amount"]));
                dispatch(setUserHold(e["holden"]));
            });

            handleEvent("TransactionEvent", (e) => {
                showToast({
                    type:
                        e["data"]["type"] === "credit"
                            ? "transaction_credit"
                            : "transaction_debit",
                    variant: "info",
                });
                dispatch(setIncomingTransaction(e["data"]))
            });
            handleEvent("TransactionErrorEvent", (e) => {
                showToast({
                    message: t("topuperror"),
                    variant: "error",
                });
            });
            handleEvent("TgUserBindEvent", (e) => {
                dispatch(setNotifyTelegramStatus(true))
                showToast({
                    message: t("success"),
                    variant: "success",
                });
            });
            handleEvent("TgUserUnBindEvent", (e) => {
                dispatch(setNotifyTelegramStatus(false))
                showToast({
                    message: t("success"),
                    variant: "success",
                });
            });
        }
    }, [dispatch, token, userId, agencyId, userType]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const route = "user/me";
                const method = "GET";

                const {data = {}} = await makeRequest({route, method});
                const userType = data.user_type;
                const userId = data.id;
                const video = data.accept_video;
                const agency_id = data?.agency_id;
                const order = data.accept_booking;
                const balance = Math.round(data.balance);
                const favorites = data.favorites_count;
                const video_chats = data.video_chats_count;
                const bookings = data.bookings_count;
                const previews = data.previews_count;
                const hold = data.balance_hold;
                const tickets = data.tickets_count;
                const emailVerifyAt = data.email_verified_at;

                dispatch(setUserAgencyId(agency_id));
                dispatch(setVideochatAmount(video_chats));
                dispatch(setBookingAmount(bookings));
                dispatch(setPreviewsAmount(previews));
                dispatch(setUserType(userType));
                dispatch(setUserId(userId));
                dispatch(setUserTariff(data.tariff));
                dispatch(setUserTariffSlug(data.tariff.data.slug));
                dispatch(setApiUserReady(true));
                dispatch(setReadyToOrder(order));
                dispatch(setReadyToVideochat(video));
                dispatch(setUserBalance(balance));
                dispatch(setUserHold(hold));
                dispatch(setFavoriteCounter(favorites));
                dispatch(setUserTickets(tickets));
                dispatch(setEmailVerifyAt(emailVerifyAt));
            } catch (error) {
            }
        };

        userType === "default" ? token && fetchData() : fetchData();
    }, [token, userType]);

    const userAuth = localStorage.getItem("user");
    useEffect(() => {
        if (!userAuth && userType !== "default") {
            dispatch(setUserType("default"));
            dispatch(setUserStatus("silver"));
            dispatch(setUserId(null));
            dispatch(setFavoriteCounter(0));
            navigate(`/`);
        }
    }, [userAuth]);

    // theme switch
    useEffect(()=> {
        switch(theme){
            case 'light': {
                document.body.classList.add('light')
                break;
            }
            case 'dark' : {
                document.body.classList.remove('light')
                break;
            }
            default:{
                break
            }
        }
    }, [theme])

    return (
        <Suspense fallback={<Loader height={100}/>}>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={<MainLayout/>}>
                    <Route path=":lang?/:city?/:params?" element={<MainPage/>}/>

                    <Route path=":lang?/registration" element={<RegistrationPage/>}/>

                    <Route path=":lang?/support" element={<SupportPage/>}/>

                    <Route path=":lang?/guide" element={<Guide/>}/>

                    <Route path=":lang?/gdpr" element={<PrivacyPolicy/>}/>

                    <Route path=":lang?/rules" element={<RulesPage/>}/>

                    <Route path=":lang?/provisions" element={<ProvisionsPage/>}/>

                    <Route path=":lang?/about" element={<About/>}/>

                    <Route path=":lang?/uikit" element={<UIKitPage/>}/>

                    <Route path=":lang?/login/:token" element={<AdminLogin/>}/>

                    <Route path=":lang?/agencies" element={<AgencyList/>}/>

                    <Route path=":lang?/history" element={<History/>}/>

                    <Route path=":lang?/filter/:uuid" element={<MainPage/>}/>

                    <Route
                        path=":lang?/profile/:profileSlug"
                        element={
                            <Suspense fallback={<Loader/>}>
                                <VariantModelPage/>
                            </Suspense>
                        }
                    />

                    <Route
                        path=":lang?/check-:profileSlug"
                        element={<VariantModelPage/>}
                    />

                    <Route
                        path=":lang?/operator-confirm/:key"
                        element={<OperatorConfirm/>}
                    />

                    <Route
                        path=":lang?/operator-confirm/:key"
                        element={<OperatorConfirm/>}
                    />

                    <Route
                        path=":lang/agency/:agencyId/:params?"
                        element={<AgencyPage/>}
                    />

                    <Route path=":lang?/party/:partyId" element={<PartyPage/>}/>

                    <Route path=":lang?/call/:room" element={<MainPage/>}/>

                    <Route path=":lang?/selection/:city" element={<Selection/>}/>

                    {/* lk pages START ---------------------------------------------------  */}
                    <Route path=":lang?/lk" element={<RedirectLk/>}/>

                    <Route path=":lang?/lk/photograph" element={<ComingSoon/>}/>

                    <Route path=":lang?/lk/support" element={<SupportPage/>}/>

                    <Route
                        path=":lang?/lk/balance"
                        element={
                            <PrivateRoute>
                                <BalancePage/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path=":lang?/lk/previews"
                        element={
                            <PrivateRoute>
                                <PreviewsPage/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path=":lang?/lk/withdraw"
                        element={
                            <PrivateRoute>
                                <WitchdrawBalance/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path=":lang?/lk/profiles"
                        element={
                            <PrivateRoute>
                                <ProfilesPage/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path=":lang?/lk/operators"
                        element={
                            <PrivateRoute>
                                <OperatorsPage/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path=":lang?/lk/parties"
                        element={
                            <PrivateRoute>
                                <PartiesPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                      path=":lang?/lk/party/:partyid"
                      element={
                          <PrivateRoute>
                            <ChangePartyPage />
                          </PrivateRoute>
                      }
                    />
                    <Route
                      path=":lang?/lk/parties/admin"
                      element={
                          <PrivateRoute>
                            <PartiesPage />
                          </PrivateRoute>
                      }
                    />

                    <Route
                      path=":lang?/party/:id"
                      element={
                          <PrivateRoute>
                            <PartyPage />
                          </PrivateRoute>
                      }
                    />

                    <Route
                        path=":lang?/lk/videochats"
                        element={
                            <PrivateRoute>
                                <VideochatHistoryPage/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path=":lang?/lk/reviews"
                        element={
                            <PrivateRoute>
                                <ReviewsPage/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path=":lang?/lk/reservations"
                        element={
                            <PrivateRoute>
                                <ReservationPage/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path=":lang?/lk/statistic/:slug/:name"
                        element={
                            <PrivateRoute>
                                <StatisticPage/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path=":lang?/lk/statistic"
                        element={
                            <PrivateRoute>
                                <StatisticPage/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={`:lang?/lk/topup`}
                        element={
                            <PrivateRoute>
                                <Balance/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={`:lang?/lk/${userType}/balance`}
                        element={
                            <PrivateRoute>
                                <Balance/>
                            </PrivateRoute>
                        }
                    />
                    {/* lk pages END ---------------------------------------------------  */}

                    {/* lk agency START ---------------------------------------------------  */}
                    <Route
                        path=":lang?/lk/agency"
                        element={
                            <PrivateRoute>
                                <AccountPage/>
                            </PrivateRoute>
                        }
                    />
                    {/* lk agency END ---------------------------------------------------  */}

                    <Route
                        path=":lang?/lk/photographer"
                        element={
                            <PrivateRoute>
                                <ComingSoon/>
                            </PrivateRoute>
                        }
                    />

                    {/* lk customer START ---------------------------------------------------  */}
                    <Route
                        path=":lang?/lk/customer"
                        element={
                            <PrivateRoute>
                                <AccountPage/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path=":lang?/lk/customer/subscriptions"
                        element={
                            <PrivateRoute>
                                <Subscriptions/>
                            </PrivateRoute>
                        }
                    />
                    {/* lk customer END ---------------------------------------------------  */}

                    {/* lk indi START ---------------------------------------------------  */}
                    <Route
                        path=":lang?/lk/indi"
                        element={
                            <PrivateRoute>
                                <AccountPage/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path=":lang?/lk/profile/:profileSlug/edit"
                        element={
                            <PrivateRoute>
                                <VariantModelPage edit/>
                            </PrivateRoute>
                        }
                    />
                    {/*  lk indi END --------------------------------------------------- */}
                    <Route
                        path=":lang?/lk/create"
                        element={
                            <PrivateRoute>
                                <CreateProfile/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path=":lang?/lk/create/:slug"
                        element={
                            <PrivateRoute>
                                <CreateProfile/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path=":lang?/preview/:profileSlug"
                        element={
                            <PrivateRoute>
                                <VariantModelPage edit/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path={`:lang?/signin/:token`}
                        element={
                            <PrivateRoute>
                                <SignIn/>
                            </PrivateRoute>
                        }
                    />
                </Route>
                <Route path="404" element={<ErrorPage/>}/>
                <Route path="*" element={<ErrorPage/>}/>
            </Routes>
        </Suspense>
    )

}

export default App;
