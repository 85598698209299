import { ButtonLink, Icon, RatingStars } from "../index";
import React from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "@/helper/capitalizeFirstLetter";

import "./banner.scss";

const Banner = ({ props }) => {
  const {
    title,
    img,
    imgMobile,
    rating,
    model_total,
    date,
    country,
    logoImg,
    logoImgMobile,
    partyPage = false
  } = props;

  const { t } = useTranslation("translation");

  return (
    <section className="banner __container">
      <picture className="banner__img">
        {img && <source srcSet={img} media="(min-width: 479.98px)" />}
        {imgMobile && (
          <img
            className="img-cover"
            src={imgMobile}
            alt={`${t("agenci1")} ${title}`}
          />
        )}
      </picture>

      <div className="agency__item">
        {logoImg || logoImgMobile ? (
          <picture>
            {logoImg ? (
              <source srcSet={logoImg} media="(min-width: 599.98px)" />
            ) : null}
            {logoImgMobile ? (
              <img alt={`${t("agenci1")} ${title}`} src={logoImgMobile} />
            ) : null}
          </picture>
        ) : null}

        <div className="agency__item-desc">
          <h1 className="title title_h3 mb-8">{title}</h1>

          {!!rating ? (
            <a
              href={"#reviews"}
              className="model__review"
              title="Посмотреть отзывы"
            >
              <RatingStars color={'gold'} value={rating} readOnly={true} />
              <span>
                {t("reviewses")} - {}
              </span>
            </a>
          ) : date && country ? (
            <span className={"banner__daytime"}>
              <Icon
                type={"flag"}
                size={"m"}
                clazz={"mr-6"}
                spritePath={country}
              />
              {date}
            </span>
          ) : null}
        </div>
        
          <ButtonLink
            anchor={true}
            href="#models"
            size={"m"}
            clazz="button--green td-none"
          >
            {partyPage ? t('participatingmodels') : capitalizeFirstLetter(t('models')) + ' - ' }  {model_total ? model_total : 0}
          </ButtonLink>
     
      </div>
    </section>
  );
};
export default Banner;
