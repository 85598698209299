import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ComingSoon = ({backLink = `/`}) => {
  const { t } = useTranslation("translation");

  return (
    <div className="error-page__container text-center">
      <h1>{t("soon")}.</h1>
      <Link to={backLink}>{t("backtomain")}</Link>
    </div>
  );
};

export default ComingSoon;
