import { DndProvider } from "react-dnd-cjs";
import HTML5Backend from "react-dnd-html5-backend-cjs";
import PhotoUpload from "../UploadZone";
import { getIdsFromUrls } from "../changeOrder/changeOrder";
import WarningOnRemovePhoto from "@/components/popups/warningOnRemovePhoto/WarningOnRemovePhoto";
import { useState } from "react";

export const Photo = ({
  data,
  setData,
  show,
  error,
  setError,
  formData,
  setFormData,
  setAllSteps,
  setAvailableSteps,
  setOpenAddStories
}) => {
  const movePhoto = (dragIndex, hoverIndex) => {
    const draggedPhoto = data.files[dragIndex];
    const newPhotos = [...data.files];
    newPhotos.splice(dragIndex, 1);
    newPhotos.splice(hoverIndex, 0, draggedPhoto);
    setData({ files: newPhotos });
    setFormData(prev => ({
      ...prev,
      media: ({
        ...prev.media,
        photo: newPhotos.map(item => item.preview)
      })
    }))
    getIdsFromUrls(newPhotos, formData.slug);
  };

  const [open, setOpen] = useState(false);
  const [link, setLink] = useState(false);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {show === "photo" && (
          <PhotoUpload
            setError={setError}
            movePhoto={movePhoto}
            data={data}
            setData={setData}
            accept={{
              "image/jpeg": [".jpg", ".jpeg", ".JPG", ".JPEG"],
              "image/png": [".png", ".PNG"],
              "image/webp": [".webp", ".WEBP"],
            }}
            type="photo"
            addText={true}
            mode={"main"}
            formData={formData}
            setFormData={setFormData}
            setAllSteps={setAllSteps}
            setAvailableSteps={setAvailableSteps}
            setOpen={setOpen}
            setLink={setLink}
            setOpenAddStories={setOpenAddStories}
          />
        )}
        {error && show === "photo" && (
          <p className="error text-center">{error}</p>
        )}

      </DndProvider>

      {open &&
        <WarningOnRemovePhoto
          open={open}
          setOpen={setOpen}
          profileSlug={formData.slug}
          formData={formData}
          setData={setData}
          link={link}
          setAllSteps={setAllSteps}
          setAvailableSteps={setAvailableSteps}
        />
      }
    </>
  );
};
