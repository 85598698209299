import {
  setAuthorizationStatus,
  setStoriesMap,
} from "@/stores/slices/popupSlice";
import React from "react";
import { Button, ButtonLink, Icon } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import { useSelector } from "react-redux";
import {
  getFavoriteCounterStatus,
} from "@/stores/slices/userSlice";
import { show, showShort } from "@/stores/slices/showFilterSlice";
import { getProfilesCount, getProfilesFilterCount } from "@/stores/slices/mapModelSlice";
import {
  setBurgerMenu,
} from "@/stores/slices/headerSlice";
import { useLocation } from "react-router-dom";
import { getModelsOnMapCount } from "@/stores/slices/counterFilterSlice";

const MenuNavBar = ({lk}) => {
  const { dispatch, userType, t, navigate } = useLadyService();
  const location = useLocation()
  const modelsOnMapCount = useSelector(getModelsOnMapCount);

  const showFilter = () => {
    dispatch(setBurgerMenu(false));
    dispatch(show());
  };

  const showShortFilter = () => {
    dispatch(setBurgerMenu(false));
    dispatch(showShort());
  };

  const favoriteCount = useSelector(getFavoriteCounterStatus);
  const profilesCountValue = useSelector(getProfilesCount);


  const toLk = (key) => {
    navigate(`/lk/${key}`);
  };


  // profilesCountValue and profilesFilterCountValue did not change

  const mapOpenHandler = () => {
    if(profilesCountValue > 0 || modelsOnMapCount > 0) dispatch(setStoriesMap(true))
  }
  

  const headerIconsMenuBar = {
    // agency_lk: [
    //   {
    //     id: 1,
    //     icon: "user-circle",
    //     name: t("profuser"),
    //     link: "lk/agency",
    //   },
    //   {
    //     id: 2,
    //     icon: "user-id",
    //     name: t("myads"),
    //     link: "#",
    //   },
    //   {
    //     id: 3,
    //     icon: "star",
    //     name: t("reviews"),
    //     link: "#",
    //   },
    //   {
    //     id: 5,
    //     icon: "stat",
    //     name: t("mystat"),
    //     link: "/lk/statistic",
    //   },
    //   {
    //     id: 6,
    //     name: t("balance"),
    //     link: "#",
    //     wallet: userBalance,
    //     icon: "wallet",
    //   },
    //   {
    //     id: 7,
    //     icon: "camera",
    //     name: t("photographer"),
    //     link: "/lk/photographer",
    //   },
    // ],
    // indi_lk: [
    //   {
    //     id: 1,
    //     icon: "user-circle",
    //     name: t("profuser"),
    //     link: "/lk/indi",
    //   },
    //   {
    //     id: 2,
    //     icon: "user-id",
    //     name: t("myads"),
    //     link: "/lk/profiles",
    //   },
    //   {
    //     id: 3,
    //     icon: "star",
    //     name: t("reviews"),
    //     link: "/lk/reviews",
    //   },
    //   {
    //     id: 4,
    //     icon: "stat",
    //     name: t("mystat"),
    //     link: "/lk/statistic",
    //   },
    //   {
    //     id: 5,
    //     name: t("balance"),
    //     link: "/lk/balance",
    //     wallet: userBalance,
    //   },
    //   {
    //     id: 6,
    //     icon: "undo-left",
    //     name: t("backtomain"),
    //     link: `/${city}`,
    //   },
    // ],
    default: [
      {
        id: 1,
        icon: "home-main",
        name: t("mainpage"),
        link: "/",
      },
      {
        id: 2,
        icon: "catalog",
        name: t("catalogue"),
        onClick: () => showShortFilter(),
        disabled: userType === 'agency' && location.pathname.includes('lk'),
      },
      {
        id: 3,
        icon: "filter",
        name: t("filter"),
        onClick: () => showFilter(),
        disabled: userType === 'agency' && location.pathname.includes('lk'),
      },
      {
        id: 4,
        icon: "map",
        name: t("onmap"),
        onClick: () => mapOpenHandler(),
        disabled: (userType === 'agency' && location.pathname.includes('lk')) || modelsOnMapCount === 0 || profilesCountValue === 0,
      },
      {
        id: 5,
        icon: "favorite",
        clazz: `user__favorite${favoriteCount <= 0 ? ' empty' : ''}`,
        name: t("favorites"),
        counter: favoriteCount,
        link: "/history",
      },
      {
        id: 6,
        icon: "person",
        name: t("profuser"),
        onClick:
          userType === "default"
            ? () => dispatch(setAuthorizationStatus(true))
            : () => toLk(userType),
      },
    ],
  };

  

  return (
    <nav className="menu__body">
      {
      headerIconsMenuBar.default.slice(0, 6)
        .map((i) => {
          if (i.link) {
            return (
              <ButtonLink
                href={i.link}
                key={i.id}
                title={i.name}
                size={"l"}
                clazz={`menu__link _no-underline${
                  i.clazz ? ` ${i.clazz}` : ""
                }`}
                counter={i.wallet ? i.counter : ""}
              >
                {i.icon === 'wallet' ? (
                  <span className={"menu__wallet currency--erocoin"}>
                    {i.wallet}
                  </span>
                ) : (
                  <Icon
                    counter={i.counter}
                    size={"l"}
                    clazz={i.clazz}
                    spritePath={i.icon}
                  />
                )}
                <span>{i.name}</span>
              </ButtonLink>
            );
          }

          return (
            <Button
              onClick={i.onClick}
              key={i.id}
              title={i.name}
              size={"l"}
              counter={i.counter}
              clazz={`menu__link${i.clazz ? ` ${i.clazz}` : ""}`}
              disabled={i.disabled}
            >
              <Icon size={"l"} clazz={i.clazz} spritePath={i.icon} />
              <span>{i.name}</span>
            </Button>
          );
        })}
    </nav>
  );
};

export default MenuNavBar;
