import { Icon, Button } from "@/components/ui";

import "./addHeader.scss";

import useLadyService from "@/services/LadyService";
import { setAddOrImportProfile } from "@/stores/slices/popupSlice";
import { useState } from "react";
import ImportAdComponent from "@/helper/ImportAdComponent";

const options = [
  { name: "banging.cz" },
  { name: "secretgirlprague.com" },
  { name: "eurogirlsescort.com" },
  { name: "escorts.cz" },
];

const AddHeader = () => {
  const { t, dispatch } = useLadyService();

  const [importAdValue, setImportAdValue] = useState("");
  const { importAd } = ImportAdComponent();


  const handleImport = async () => {
    await importAd(importAdValue);
  };

  return (
    <div className="add__root">
      <Button
        // href={`/lk/indi/create`}
        clazz={"button--green _no-underline"}
        size="l"
        onClick={() => dispatch(setAddOrImportProfile(true))}
      >
        <Icon spritePath={"plus"} size={"l"} />
        <span className="p1 add__add">{t("add")}</span>
        <span className="p1 add__addad">{t("addad")}</span>
      </Button>
    </div>
  );
};

export default AddHeader;
