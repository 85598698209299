import {
  CountriesFilter,
  CheckPhone,
  Rules,
  InfoForm, AccountActions, InfoBlock, ToggleSalon,
} from "../components";
import useLadyService from "@/services/LadyService";

import NotifyBlock from "../components/notifyBlock/NotifyBlock.js";
import { PersonalData } from "../components/PersonalData.js";
import useUserProfileService from "../../../../services/UserProfileService";

const AccountModel = () => {
  const { windowWidth, t, userBalance } = useLadyService();
  const { profileCountStatus, blockedCountriesList } = useUserProfileService();

  let data = {
    blocked_countries: blockedCountriesList
  }

  const isSmallTablet = windowWidth < 1000.98;

  const goToAncketa = isSmallTablet ? t("toads") : t("browseprofiles");

  const goToBank = isSmallTablet ? t("tobalance") : t("gotobalance");

  return (
        <div className={"account-page__root"}>
            <div className={"account-page__main"}>
              <PersonalData/>

              <hr />

              {isSmallTablet && (
                <>
                  <Rules />
                  <hr />
                </>
              )}

              <NotifyBlock/>

              <InfoForm/>

              <ToggleSalon/>

              <div className={"account-page__info"}>

                <InfoBlock
                  text={!!profileCountStatus ? t("yourads") : t("addads")}
                  count={!!profileCountStatus ? profileCountStatus : ''}
                  link={'/lk/profiles'}
                  linkText={goToAncketa}
                />

                <InfoBlock
                  text={t("yourbalance")}
                  count={userBalance}
                  balance
                  link={'/lk/balance'}
                  linkText={goToBank}
                />
              </div>

              <hr />

              <div className="account-page__info">
                <h2>{t("usefulfeatures")}</h2>

                <CheckPhone />

                <CountriesFilter data={data} />
              </div>

              <AccountActions/>

            </div>

          {!isSmallTablet && <Rules />}
        </div>
  );
};

export default AccountModel;
