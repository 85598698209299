import useLadyService from "@/services/LadyService";
import { useEffect } from "react";
import { Loader } from "@/components/ui";
import { useSelector } from "react-redux";
import { getApiUserReady } from "@/stores/slices/userSlice";

export const RedirectLk = () => {
  const { userType, navigate, token } = useLadyService();
  const apiReady = useSelector(getApiUserReady);

  useEffect(() => {
    if(apiReady) {
      if (userType !== "default" && token) {
        const targetPath = `/lk/${userType}`;
        navigate(targetPath);
      } else {
        navigate("/");
      }
    }
    if (!token && !apiReady) {
      navigate("/");
    }
    
  }, [userType, token, navigate, apiReady]);

  return (
      <Loader height={"100"}></Loader>
  );
};

export default RedirectLk