import React, {useState} from "react";
import {Line} from "react-chartjs-2";
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    Title,
} from "chart.js";

import ChartAnnotation from 'chartjs-plugin-annotation';
import CustomLegend from "./CustomLegend";
import BackgroundLegend from "./BackgroundLegend";
import useLadyService from "../../../../../services/LadyService";
import IncomeExpensesChart from "./IncomeExpensesChart"; // Подключаем плагин

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend, Title, ChartAnnotation);

const StatisticChart = ({profileFilter, labels, transactions, statistic, promoPeriods, elitePeriods, clazz}) => {

    const {t, theme} = useLadyService()
    const toTimestampGMT = (dateString) => {
        const [year, month, day] = dateString.split('.');
        const date = new Date(Date.UTC(year, month - 1, day)); // Используем UTC

        return Math.floor(date.getTime() / 1000);
    };
    const processData = (data) => {
        const datasetsMap = {};
        const allKeys = new Set();

        Object.values(data).forEach((entry) => {
            Object.keys(entry).forEach((key) => {
                if (key !== 'position') {
                    allKeys.add(key);
                }
            });
        });

        // Инициализируем массивы для всех ключей
        allKeys.forEach((key) => {
            datasetsMap[key] = [];
        });

        // Проходим по меткам и добавляем значения или нули
        labels.forEach((date) => {
            const unix = toTimestampGMT(date); // Преобразуем дату в Unix timestamp
            const key = String(unix); // Преобразуем Unix timestamp в строку для соответствия ключам data

            if (data[key]) {
                // Если данные для текущей даты существуют
                allKeys.forEach((dataKey) => {
                    datasetsMap[dataKey].push(Number(data[key][dataKey] || 0));
                });
            } else {
                // Если данных для текущей даты нет, добавляем 0
                allKeys.forEach((dataKey) => {
                    datasetsMap[dataKey].push(0);
                });
            }
        });
        return {datasetsMap};
    };


    const processPeriods = (labels, periods) => {
        const labelDates = labels.map((label) => {
            const [year, month, day] = label.split('.'); // Разделяем день, месяц, год
            return new Date(year, month - 1, day).setHours(0, 0, 0, 0); // Создаем объект Date, начиная с 00:00:00
        });

        return periods.map((period) => {
            let start = new Date(period.start_time);
            let end = new Date(period.end_time);

            // Убедимся, что период охватывает весь день, если start и end одинаковые
            if (start.getTime() === end.getTime()) {
                end.setHours(0, 0, 0, 0);
            } else {
                end.setHours(0, 0, 0, 0);
            }

            // Преобразуем start и end в формат, совместимый с метками
            start = start.setHours(0, 0, 0, 0);
            end = end.setHours(0, 0, 0, 0);

            const startIndex = labelDates.findIndex((date) => date >= start);
            const endIndex = labelDates.findIndex((date) => date >= end);

            let indicesInRange = [];
            if (startIndex !== -1 && endIndex !== -1) {
                // Заполняем индексы, которые попадают в диапазон от startIndex до endIndex
                for (let i = startIndex; i <= endIndex; i++) {
                    indicesInRange.push(i);
                }
            }

            return {
                start: startIndex !== -1 ? startIndex : null,
                end: endIndex !== -1 ? endIndex : null,
                indicesInRange,  // список всех индексов в этом периоде
            };
        });
    };


    const {datasetsMap} = processData(statistic);
    
    const [visibleDatasets, setVisibleDatasets] = useState(
        Object.keys(datasetsMap).reduce((acc, key) => ({...acc, [key]: true}), {})
    );
    const getColor = (key) => {
        const colors = {
            profileshows: theme === 'dark' ? "#fff" : "#000",
            viewprofile: "#DF8343",
            viewcontact: "#489CFF",
            erocontentaccess: "#DC3D50",
            orderonline: "#3ddc40",
            minofvideo: "#a8a514",
            "g-preorders": "#af300a",
        };
        return colors[key];
    };

    const reorder = {profileshows: datasetsMap?.profileshows, viewprofile: datasetsMap?.viewprofile, viewcontact: datasetsMap?.viewcontact,  minofvideo : datasetsMap.minofvideo}
    console.log('dm', reorder)

    // Генерация datasets для графика
    const datasets = Object.keys(reorder).map((key, index) => ({
        label: key,
        data: datasetsMap[key],
        borderColor: getColor(key),
        borderWidth: 2,
        fill: false,
        hidden: !visibleDatasets[key],
    }));


    const createAnnotationsForTimestamps = (timestamps, color) => {
        return timestamps.map((timestamp) => ({
            type: 'box',
            xMin: labels.findIndex((label) => toTimestampGMT(label) === timestamp), // индекс метки
            xMax: labels.findIndex((label) => toTimestampGMT(label) === timestamp) + 1, // следующий индекс
            backgroundColor: color,
            yScaleID: 'y',
            borderWidth: 0,
        })).filter(({ xMin }) => xMin !== -1); // Убираем даты, которые отсутствуют в labels
    };


    // // Создаем аннотации для подсветки активных периодов с добавлением названия
    // const createBackgroundHighlight = (periods, color, label) => {
    //     return periods.map(({start, end}) => {
    //         if (start !== null && end !== null) {
    //             return {
    //                 type: 'box',
    //                 xMin: start,
    //                 xMax: end,
    //                 backgroundColor: color,
    //                 yScaleID: 'y',
    //                 borderWidth: 0,
    //             };
    //         }
    //         return null;
    //     }).filter(Boolean);
    // };

    const createStripedPattern = (color, angle = 45, size = 10) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = size;
        canvas.height = size;

        // Угол наклона полосок
        const radians = (angle * Math.PI) / 180;

        // Настройка цвета полосок
        ctx.strokeStyle = color;
        ctx.lineWidth = 2;

        // Рисуем диагональные линии
        ctx.beginPath();
        ctx.moveTo(0, size);
        ctx.lineTo(size, 0);
        ctx.stroke();

        // Поворачиваем канвас для нужного угла
        canvas.style.transform = `rotate(${radians}rad)`;

        return ctx.createPattern(canvas, 'repeat');
    };
    const backgroundPeriods = [
        { label: t("activepromo"), color: "rgba(28, 161, 130, 0.8)" },
        { label: (t("Status") + ' Elite'), color: "rgba(204, 175, 110, 0.8)" },
    ];
    const incomeExpenesesLegend = [
        { label: t("income"), color: "rgba(40, 182, 114, 1)" },
        { label: t("expences"), color: "rgba(216, 53, 73, 1)" },
        { label: t("position"), color: "#165faf" },
    ];
    const promotionPeriodAnnotations = createAnnotationsForTimestamps(
        promoPeriods,
        'rgba(28, 161, 130, 0.4)',
        t("activepromo")
    );
    const eliteStatusPeriodAnnotations = createAnnotationsForTimestamps(
        elitePeriods,
        'rgba(204, 175, 110, 0.4)',
        t("statusElite")
    );
    // Данные для графика
    const data = {
        labels: labels,
        datasets: datasets,
    };

    // Опции графика с добавлением аннотаций
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,

            },
            tooltip: {
                enabled: true,
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                titleColor: "#ffffff",
                bodyColor: "#ffffff",
                borderColor: "#ffffff",
                borderWidth: 1,
                displayColors: false,
                callbacks: {
                    label: function (context) {
                        const value = context.raw;
                        return `${t(context.dataset.label)}: ${value}`;
                    },
                },
            },
            annotation: {
                annotations: [
                    ...promotionPeriodAnnotations,
                    ...eliteStatusPeriodAnnotations,
                ],
            },
        },
        scales: {
            x: {
            },
            y: {
                beginAtZero: true,
                min: 0,
            },
        },
    };
    const processIncomeExpensesData = (incomeData, expenseData, labels) => {
        const incomeValues = [];
        const expenseValues = [];

        // Преобразуем labels в Unix timestamps для точного сравнения
        const labelTimestamps = labels.map(label => {
            const [year, month, day] = label.split('.');
            return Math.floor(new Date(Date.UTC(year, month - 1, day)).getTime() / 1000);
        });

        // Заполняем доходы и расходы для каждой даты
        labelTimestamps.forEach((timestamp) => {
            // Доходы
            if (incomeData[timestamp]) {
                incomeValues.push(Number(incomeData[timestamp]));
            } else {
                incomeValues.push(0);
            }

            // Расходы (делаем отрицательными)
            if (expenseData[timestamp]) {
                expenseValues.push(-Number(expenseData[timestamp]));
            } else {
                expenseValues.push(0);
            }
        });

        return { incomeValues, expenseValues };
    };


    console.log(profileFilter)
    const { incomeValues, expenseValues } = processIncomeExpensesData(transactions['income'], transactions['expenses'], labels);
    return (<>
            <CustomLegend
            datasets={datasets}
            visibleDatasets={visibleDatasets}
            setVisibleDatasets={setVisibleDatasets}/>
            <Line data={data} options={options}/>
            <BackgroundLegend periods={backgroundPeriods} />
            {profileFilter && <>
                <h3 className="mt-48">{t('income')} / {t('expenses')} / {t('position')}</h3>
                <IncomeExpensesChart profileFilter={profileFilter} labels={labels}  incomeData={incomeValues} statistic={statistic} expenseData={expenseValues}/>
                <BackgroundLegend periods={incomeExpenesesLegend} />
            </>}
    </>);
};

export default StatisticChart;
